import { HttpClient, HttpHeaders } from '@angular/common/http';
import { newArray } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trace } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { cpuUsage } from 'process';
import { stringify } from 'querystring';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-tournoi',
  templateUrl: './tournoi.component.html',
  styleUrls: ['./tournoi.component.scss']
})
export class TournoiComponent implements OnInit {

  public tournoiList;
  public tab_inscrit = new Array();

  public tab_invit = new Array();
  public tab_role = new Array();

  public tab_valid = new Array();
  public tab_team = new Array();

  public textAlert = ""
  public alerteDisplay = false;

  public userList;
  public userConnected;

  public notif = new Array();
  public tab_etat = new Array();

  public isConnected = false;

  private httpOptions: any;


  constructor(private _httpClient: HttpClient, private route: ActivatedRoute, private cookieService: CookieService, private global: AppComponent) {

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
  }

  ngOnInit(): void {
    if (this.cookieService.get("username") != "") {
      this.isConnected = true;
    }
    this.getListUser();
    this.getTournoi();
    const user_part = JSON.parse(this.cookieService.get('username'));
    this.userConnected = user_part.data.username;
  }

  getTournoi() {
    this._httpClient.get("http://127.0.0.1:8000/tournoi/").subscribe(async data => {
      const tournoi_decoded = JSON.stringify(data);
      const tounoi_part = JSON.parse(tournoi_decoded);
      this.tournoiList = tounoi_part.results
      for (var i = 0; i < tounoi_part.results.length; i++) {
        this.tab_inscrit.push(0);
        if (tounoi_part.results[i].num_byteam == 1) {
          this.getSolo(tounoi_part.results[i].id, i)
        } else {
          this.getTeam(tounoi_part.results[i].id, i);
        }

        const data_one = await this._httpClient.get("http://127.0.0.1:8000/subcount/" + tounoi_part.results[i].id).toPromise()
        const count_decoded = JSON.stringify(data_one);
        const count_part = JSON.parse(count_decoded);
        console.log(count_part)
        this.tab_inscrit[i] = count_part.count;

      }

    });
  }

  getSolo(id_tournoi, num) {
    this.tab_etat.push(0);
    const user_part = JSON.parse(this.cookieService.get('username'));
    const id_joueur = user_part.data.id;
    this._httpClient.get("http://127.0.0.1:8000/inscription_solo/" + id_tournoi + "/" + id_joueur).subscribe(data => {
      this.tab_etat[num] = 2;
    });
  }

  async getTeam(id_tournoi, num) {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const id_joueur = user_part.data.id;
    this.notif.push(0);
    this.tab_etat.push(0);
    this.tab_role.push(0);
    var num_notif = 0;

    // var id_team = player_part.results[0].id_team;
    const data = await this._httpClient.get("http://127.0.0.1:8000/getuserteam/" + id_joueur + "/check/" + id_tournoi + "/2").toPromise()
    const player_decoded = JSON.stringify(data);
    const player_part = JSON.parse(player_decoded);

    if (player_part.count == 1) {
      if (player_part.results[0].etat_joueur > 1) {
        console.log("test ");
        this.tab_etat[num] = 1;
        this.tab_role[num] = player_part.results[0].etat_joueur;
        const data_two = await this._httpClient.get("http://127.0.0.1:8000/lien_team/" + player_part.results[0].id_team + "/").toPromise();
        const check_decoded = JSON.stringify(data_two);
        const check_part = JSON.parse(check_decoded);
        console.log(check_part)
        if (check_part.etat_inscription == 1) {
          this.tab_etat[num] = 2;
        }
        else {
          this._httpClient.get("http://127.0.0.1:8000/teams/" + player_part.results[0].id_team).subscribe(data => {
            const equipe_decoded = JSON.stringify(data);
            const equipe_part = JSON.parse(equipe_decoded);
            var name = equipe_part.results[0].nom_team;
            var id_team = equipe_part.results[0].id_team;
            var team = "";
            var tab_ligne = [];
            tab_ligne.push(id_tournoi);
            tab_ligne.push(id_team);
            tab_ligne.push(name);
            for (var j = 0; j < equipe_part.results.length; j++) {
              var tab_colonne = [];
              tab_colonne.push(equipe_part.results[j].nom_joueur)
              tab_colonne.push(equipe_part.results[j].etat_joueur)
              tab_ligne.push(tab_colonne);
            }
            this.tab_invit.push(tab_ligne);
            console.log(tab_ligne)
            console.log(this.tab_invit)
          });
        }
      }

    } else {

      console.log("notif")
      this._httpClient.get("http://127.0.0.1:8000/getuserteam/" + id_joueur + "/check/" + id_tournoi + "/0").subscribe(async data => {
        const data_decoded = JSON.stringify(data);
        const data_part = JSON.parse(data_decoded);
        console.log(data_part)
        for (var i = 0; i < data_part.results.length; i++) {
          const data = await this._httpClient.get("http://127.0.0.1:8000/teams/" + data_part.results[i].id_team).toPromise()
          num_notif++;
          const equipe_decoded = JSON.stringify(data);
          const equipe_part = JSON.parse(equipe_decoded);
          var name = equipe_part.results[0].nom_team;
          var id_team = equipe_part.results[0].id_team;
          var team = "";
          for (var j = 0; j < equipe_part.results.length; j++) {
            if (equipe_part.results.length == j + 1) {
              team = team + equipe_part.results[j].nom_joueur;
            } else {
              team = team + equipe_part.results[j].nom_joueur + " | ";
            }
          }
          var tab_ligne = [];
          tab_ligne.push(id_tournoi);
          tab_ligne.push(id_team);
          tab_ligne.push(name);
          tab_ligne.push(team);
          console.log(tab_ligne)
          this.tab_invit.push(tab_ligne);



        }
        this.notif[num] = num_notif;
        console.log(this.notif)
      })




    }








  }

  valid_invit(num_team, id_tournoi) {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const id_joueur = user_part.data.id;
    const bodyTeam = '{"etat_joueur" : "' + 2 + '" }';
    this._httpClient.put('http://127.0.0.1:8000/teams/' + id_joueur + '/' + num_team, bodyTeam, this.httpOptions).subscribe(data => {

      this._httpClient.get("http://127.0.0.1:8000/teams/" + num_team).subscribe(async data => {
        const team_decoded = JSON.stringify(data);
        const team_part = JSON.parse(team_decoded);
        var team_valid = true;
        for (var i = 0; i < team_part.results.length; i++) {
          if (team_part.results[i].etat_joueur < 2) {
            team_valid = false;
          }
        }


        const check = await this._httpClient.get('http://127.0.0.1:8000/getuserteam/' + id_joueur + '/check/' + id_tournoi + '/' + 2, this.httpOptions).toPromise()
        const check_decoded = JSON.stringify(check);
        const check_part = JSON.parse(check_decoded);
        for (var i = 0; i < check_part.results.length; i++) {
          const bodyTeam = '{"etat_joueur" : "' + 1 + '" }';
          await this._httpClient.put('http://127.0.0.1:8000/teams/' + id_joueur + '/' + check_part.results[i].id_team, bodyTeam, this.httpOptions).toPromise();
        }

        if (team_valid) {
          const bodyLien = '{"etat_inscription" : "' + 1 + '" }';
          this._httpClient.put('http://127.0.0.1:8000/inscription_team/' + id_tournoi + '/' + num_team, bodyLien, this.httpOptions).subscribe(data => {
            window.location.reload();
          });
        }
        else {
          window.location.reload();
        }


      });
    });



  }

  refuser_invit(num_team) {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const id_joueur = user_part.data.id;
    const bodyTeam = '{"etat_joueur" : "' + 1 + '" }';
    this._httpClient.put('http://127.0.0.1:8000/teams/' + id_joueur + '/' + num_team, bodyTeam, this.httpOptions).subscribe(async data => {
      window.location.reload();
    });
  }

  async valid_inscription(id_tournoi, num_tournoi, nbre_joueur) {
    var erreur_doublon = false;
    var erreur_getTeam = false;
    var joueur_doublon = "";
    var tab_id = new Array();
    var tab_username = new Array();
    const user_part = JSON.parse(this.cookieService.get('username'));
    const id_joueur = user_part.data.id;
    const nom_joueur = user_part.data.username;
    if (this.tab_team[num_tournoi] == undefined || this.tab_team[num_tournoi] == "") {
      this.alerteDisplay = true;
      this.textAlert = "Veuillez renseigner le nom de votre équipe."
    } else {



      for (var i = 0; i < this.tab_valid.length; i++) {
        const info_user = this.tab_valid[i].split('/');
        tab_id.push(info_user[0])
        tab_username.push(info_user[1])
      }
      console.log(nbre_joueur)
      if (nbre_joueur > tab_id.length) {
        this.alerteDisplay = true;
        this.textAlert = "Veuillez sélectionner " + nbre_joueur + " joueurs avant de valider votre équipe."
      }
      else {


        console.log(tab_id)
        console.log(tab_username)
        for (var j = 0; j < tab_id.length; j++) {

          var user_verif = tab_id[j]
          if (user_verif == id_joueur) {
            erreur_doublon = true;
            joueur_doublon = this.userConnected;
          }
          for (var i = 0; i < tab_id.length; i++) {
            if (user_verif == tab_id[i] && i != j) {
              erreur_doublon = true;
              joueur_doublon = tab_username[i];
            }
          }
        }

        if (erreur_doublon) {
          this.alerteDisplay = true;
          this.textAlert = "Vous avez sélectionner deux fois '" + joueur_doublon + "'."

        }
        /////////////////// si il y a pas de doublon alors on créer l'équipe /////////////
        else {
          for (var i = 0; i < tab_id.length; i++) {
            const check = await this._httpClient.get('http://127.0.0.1:8000/getuserteam/' + tab_id[i] + '/check/' + id_tournoi + '/' + 2, this.httpOptions).toPromise()
            const count_decoded = JSON.stringify(check);
            const count_part = JSON.parse(count_decoded);
            if (count_part.count > 0) {
              erreur_getTeam = true;
              joueur_doublon = tab_username[i];
            }
          }
          if (erreur_getTeam) {
            this.alerteDisplay = true;
            this.textAlert = "Le joueur '" + joueur_doublon + "' est déjà dans une équipe."
          }////si aucun joueur n'est dans une team ou a créer une team alors on créer l'équipe /////
          else {
            var numTeam = 0
            const bodyTeam = '{"etat_inscription" : "' + 0 + '", "id_tournoi":"' + id_tournoi + '", "id_player":"' + 0 + '", "name_team":"' + this.tab_team[num_tournoi] + '" }';
            this._httpClient.post('http://127.0.0.1:8000/lien_team/', bodyTeam, this.httpOptions).subscribe(data => {
              const data_decoded = JSON.stringify(data)
              const data_part = JSON.parse(data_decoded)
              console.log(data_part)
              numTeam = data_part.id;

              var bodyUser = '{"etat_joueur" : "' + 3 + '", "id_tournoi":"' + id_tournoi + '", "id_joueur":"' + id_joueur + '", "nom_team":"' + this.tab_team[num_tournoi] + '", "id_team":"' + numTeam + '", "nom_joueur":"' + nom_joueur + '" }';
              this._httpClient.post('http://127.0.0.1:8000/addTeam/', bodyUser, this.httpOptions).toPromise();
              for (var i = 0; i < tab_id.length; i++) {
                bodyUser = '{"etat_joueur" : "' + 0 + '", "id_tournoi":"' + id_tournoi + '", "id_joueur":"' + tab_id[i] + '", "nom_team":"' + this.tab_team[num_tournoi] + '", "id_team":"' + numTeam + '", "nom_joueur":"' + tab_username[i] + '" }';
                this._httpClient.post('http://127.0.0.1:8000/addTeam/', bodyUser, this.httpOptions).toPromise();
              }
            });
            this._httpClient.get('http://127.0.0.1:8000/getuserteam/' + id_joueur + '/check/' + id_tournoi + '/' + 0, this.httpOptions).subscribe(data => {
              const data_decoded = JSON.stringify(data)
              const data_part = JSON.parse(data_decoded)
              const bodyTeam = '{"etat_joueur" : "' + 1 + '" }';
              for (var i = 0; i < data_part.results.length; i++) {
                if (numTeam != data_part.results[i].id_team) {
                  this._httpClient.put('http://127.0.0.1:8000/teams/' + id_joueur + '/' + data_part.results[i].id_team, bodyTeam, this.httpOptions).subscribe(async data => {
                  });
                }

              }
            });
            window.location.reload();
          }

        }
      }

    }

  }

  valid_inscription_solo(id_tournoi) {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const id_joueur = user_part.data.id;
    const name_joueur = user_part.data.username;
    const bodySolo = '{"etat_inscription" : "' + 1 + '", "id_tournoi":"' + id_tournoi + '", "id_player":"' + id_joueur + '", "name_player":"' + name_joueur + '" }';
    this._httpClient.post('http://127.0.0.1:8000/lien_player/', bodySolo, this.httpOptions).subscribe(data => {
    });
  }

  getListUser() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._httpClient.get('https://expedigame.com/api/userTwitch', this.httpOptions).subscribe(async data => {
      const user_decoded = JSON.stringify(data);
      const user_part = JSON.parse(user_decoded);

      this.userList = user_part.results;
      console.log(this.userList)

      var next = user_part.next
      while (next != null) {
        var reponse = await this._httpClient.get(next, this.httpOptions).toPromise();
        const rep_decoded = JSON.stringify(reponse);
        const rep_part = JSON.parse(rep_decoded);
        for (var i = 0; i < rep_part.results.length; i++) {
          this.userList.push(rep_part.results[i]);
        }
        next = rep_part.next
      }
    });
  }

  updateTeam(id_tournoi, id_team) {

  }


  deleteAlert() {
    this.alerteDisplay = false;
  }
}



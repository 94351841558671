<!-- Start your project here-->

<app-nav *ngIf="nav"></app-nav>
<div class="full-bg">

    <button id="openSucces" [hidden]="true" data-toggle="modal" data-target="#chestModal" data-backdrop="static"
        data-keyboard="false" (click)="succes.show()"></button>



    <div mdbModal #succes="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-notify modal-danger modal-side modal-bot-right" role="document">
            <!--Content-->
            <div class="modal-content">
                <!--Header-->
                <div class="modal-header">
                    <p class="heading">Succès</p>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="succes.hide()">
                        <span aria-hidden="true" class="white-text">&times;</span>
                    </button>
                </div>

                <!--Body-->
                <div class="modal-body">
                    <div class="text-center">
                        <div class="overflow-hidden">
                            <div class="row py-1 justify-content-center">
                                <div class="col-4">
                                    <img src="{{img}}" class="img-fluid" alt="Responsive image">
                                </div>
                                <div class="col-8 text-left">

                                    <h4>{{titre}}</h4>

                                    {{description}}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!--/.Content-->
        </div>
    </div>
    <!-- /Start your project here-->
    <router-outlet></router-outlet>
    <!--   <app-separateur></app-separateur> -->


</div>
<app-footer *ngIf="footer"></app-footer>
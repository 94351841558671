<div class="flex flex-col bg-cover bg-center relative bg-black">



    <div class="bg-image-cover" [style.background-image]="banniere"></div>
    <img *ngIf="image_profil != null" src="{{image_profil}}" [style.background-color]="colorProfil"
        class="rounded-circle profil_img border border-info img-fluid" alt="Responsive image">
    <img *ngIf="cadre != null" src="{{cadre}}" class="rounded-circle cadre_img  img-fluid" alt="Responsive image">
    <div class="rounded-circle level border border-info">{{level}}</div>
    <div class="progress unique-color-dark ">
        <div class="progress-bar rounded purple lighten-2 test px-1" role="progressbar" aria-valuenow="40"
            aria-valuemin="0" aria-valuemax="100" style="width: {{pourcentage}}%">

        </div>
    </div>

</div>

<ul class="nav special-color-dark py-4 white-text">

    <li class="nav-item ">
        <a id="succes" (click)="navChange(1)" class="nav-link px-3 active">Succès</a>
    </li>

    <li class="nav-item ">
        <a id="clip" (click)="navChange(2)" class="nav-link px-3">Clip</a>
    </li>

</ul>


<app-separateur></app-separateur>







<div class="d-flex justify-content-center" *ngIf="!navProfil">




    <div class="container">
        <div *ngFor="let data_success of succesList; let index_Succes = index">
            <div *ngFor="let user_data_success of userSuccesList">
                <div
                    *ngIf="infoSucces( data_success.id , user_data_success.succes, index_Succes,user_data_success.currently_Value,user_data_success.Rang )">
                    <div class="row py-2 bg-success rounded">
                        <div class="col-2">
                            <img src="{{url_Succes}}" class="img-fluid" alt="Responsive image">
                        </div>
                        <div class="col-10 white-text">
                            <h2>{{data_success.titre}}</h2>
                            <p>{{data_success.description_deb}} {{goal_actual}} {{data_success.description_fin}}</p>


                            <div class="progress">

                                <div class="progress-bar" role="progressbar" style="width: {{pourcentage_succes}}%"
                                    aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>


</div>



<div class="d-flex justify-content-center" *ngIf="navProfil">
    <div class="justify-content-center w-75">
        <div class="row py-2  white-text ">
            <h2>Clips</h2>
        </div>
        <div class="row py-2  bg-success rounded">


            <div class="col-md-3 py-2" *ngFor="let data_clip of clipList">


                <a href="{{data_clip.url_video}}" target="_blank">
                    <div class="view overlay zoom">
                        <img src="{{data_clip.url_img}}" class="img-fluid " alt="smaple image">
                        <div class="mask flex-center">
                            <p class="white-text">Vue : {{data_clip.count_view}}</p>
                        </div>
                    </div>
                </a>

            </div>

        </div>
    </div>



</div>








<app-separateur></app-separateur>-->
<!--<mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
  <mdb-carousel-item>
    <img class="d-block w-100" src="http://expedigame.com/theme/Darker/img/carousel/affiche_expedicraft.jpg"
      alt="First slide">
  </mdb-carousel-item>
  <mdb-carousel-item>
    <img class="d-block w-100" src="http://expedigame.com/theme/Darker/img/carousel/live.jpg" alt="Second slide">
  </mdb-carousel-item>
  <mdb-carousel-item>
    <img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg" alt="Third slide">
  </mdb-carousel-item>
</mdb-carousel>-->
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
  <div class=" w-75 ">
    <div class="row">
      <div class="col-md-6">
        <h2 class="white-text text-left">Clip du moment </h2>
        <div class="rounded py-2 bg-success text-center">

          <iframe src="https://player.twitch.tv/?video=1426359500&parent=expedigame.com" frameborder="0"
            allowfullscreen="true" scrolling="no" height="350px" width="90%"></iframe>
        </div>
      </div>
      <div class="col-md-6">
        <h2 class="white-text text-left">Qui suis-je ? <img
            src="https://cdn.discordapp.com/attachments/327226689899986946/728746099451494400/ZERLYS_logo_chauve.png"
            class="img-fluid" alt="Responsive image"></h2>
        <div class="rounded py-2 bg-success text-center white-text">

          <p>
            Bonjour et bienvenue sur le site expedigame.com
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
  <div class=" w-75 ">
    <h2 class="white-text text-left">Planning</h2>
    <div class="rounded bg-success text-center white-text">
      <div class="row py-2" *ngFor="let data_planning of planningList">
        <div class="col-md-2 align-title">
          {{data_planning[0]}}
        </div>
        <div class="col-md-10 rounded bg-planning">
          <div class="row">
            <div class="col-lg-4 align-middle">
              {{data_planning[1]}}
            </div>
            <div class="col-lg-4 align-middle">
              {{data_planning[2]}}
            </div>
            <div class="col-lg-4">
              <img src="assets/img/{{data_planning[3]}}" class="img-fluid center-lg rounded img-planning"
                alt="Responsive image">
            </div>
          </div>
        </div>
      </div>







    </div>
  </div>
</div>

<app-separateur></app-separateur>
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public planningList = [];

  constructor(private _httpClient: HttpClient) { }

  ngOnInit(): void {
    this.getPlanning();
  }

  getPlanning() {
    var ladate = new Date();
    console.log(ladate)
    var debSemaine = 0
    var finSemaine = 0

    switch (ladate.getDay()) {
      case 0:
        debSemaine = -6
        finSemaine = 0
        break;
      case 1:
        debSemaine = 0
        finSemaine = 6
        break;
      case 2:
        debSemaine = -1
        finSemaine = 5
        break;
      case 3:
        debSemaine = -2
        finSemaine = 4
        break;
      case 4:
        debSemaine = -3
        finSemaine = 3
        break;
      case 5:
        debSemaine = -4
        finSemaine = 2
        break;
      case 6:
        debSemaine = -5
        finSemaine = 1
        break;
    }
    var lundi = formatDate(new Date(Date.now() + debSemaine * 24 * 3600 * 1000), 'yyyy-MM-dd', 'en_US');
    var dimanche = formatDate(new Date(Date.now() + finSemaine * 24 * 3600 * 1000), 'yyyy-MM-dd', 'en_US');


    this._httpClient.get("https://expedigame.com/api/planning/deb_sem/" + lundi + "/fin_sem/" + dimanche).subscribe(data => {
      console.log(data);
      const date_decoded = JSON.stringify(data);
      const date_part = JSON.parse(date_decoded);
      for (var i = 0; i < 7; i++) {
        var dateString = formatDate(new Date(Date.now() + (debSemaine + i) * 24 * 3600 * 1000), 'yyyy-MM-dd', 'en_US');
        var newDate = new Date(Date.now() + (debSemaine + i) * 24 * 3600 * 1000);
        var check = false;
        for (var j = 0; j < date_part.results.length; j++) {
          if (date_part.results[j].date_live == dateString) {
            this.planningList.push(this.getJour(newDate.getDay(), date_part.results[j]));
            check = true;
          }
        }
        if (check == false) {
          this.planningList.push(this.getJour(newDate.getDay(), null));
        }


      }
      console.log(this.planningList)
    })

  }

  getJour(num_jour, tabDonnee) {
    var tabAddPlanning = []
    switch (num_jour) {
      case 0:
        tabAddPlanning.push("Dimanche");
        break;
      case 1:
        tabAddPlanning.push("Lundi");
        break;
      case 2:
        tabAddPlanning.push("Mardi");
        break;
      case 3:
        tabAddPlanning.push("Mercredi");
        break;
      case 4:
        tabAddPlanning.push("Jeudi");
        break;
      case 5:
        tabAddPlanning.push("Vendredi");
        break;
      case 6:
        tabAddPlanning.push("Samedi");
        break;
    }
    if (tabDonnee == null) {
      tabAddPlanning.push("Je ne suis pas là");
      tabAddPlanning.push("PAS DE LIVE");
      tabAddPlanning.push("NoLive-planning.png")
    } else {
      tabAddPlanning.push(tabDonnee.titre);
      tabAddPlanning.push(tabDonnee.jeu.toUpperCase());
      tabAddPlanning.push(tabDonnee.img)
    }
    return tabAddPlanning
  }

}

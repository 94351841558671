import { HttpClient, HttpHeaders } from '@angular/common/http';
import { newArray } from '@angular/compiler/src/util';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { checkServerIdentity } from 'tls';
import { AppComponent } from '../app.component';
import { TVComponent } from '../tv/tv.component';

@Component({
  selector: 'app-boutique',
  templateUrl: './boutique.component.html',
  styleUrls: ['./boutique.component.scss']
})
export class BoutiqueComponent implements OnInit {

  onOpen(event: any) {
    console.log(event);
  }

  private numRandom: number;
  private item: number;
  public CardAppear = false;
  public url = 'assets/img/chest-idle.gif';
  public needMoney = false;

  public typeMoney = false;

  public error_boutique = "";

  public banniereList;
  public userBanniereList: boolean[] = new Array;
  public cadreList;
  public userCadreList: boolean[] = new Array;

  constructor(private cookieService: CookieService, private _http: HttpClient, private global: AppComponent, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.addBanniere()
    this.addCadre()

  }


  buyBanniere(type, prix, id, index) {
    const user_part = JSON.parse(this.cookieService.get("username"));
    var diff;
    var diamant;
    var expedipoint;
    if (type == 1) {
      diff = user_part.data.expedipoint;
      diamant = user_part.data.diamant;
      expedipoint = user_part.data.expedipoint - prix;
      this.error_boutique = "Il vous manque des expedipoints";
      this.typeMoney = true;
    }
    if (type == 0) {
      diff = user_part.data.diamant;
      diamant = user_part.data.diamant - prix;
      expedipoint = user_part.data.expedipoint;
      this.error_boutique = "Il vous manque des diamants";
      this.typeMoney = false;
    }


    if (prix <= diff) {
      this.cookieService.set('diamant', diamant);
      this.cookieService.set('expedipoint', expedipoint);
      var httpHeader = {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
      };
      const body = "banniere=" + id;
      this._http.post("https://expedigame.com/api/userBanniere/", body, httpHeader).subscribe();


      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
      };
      const bodyAdd = '{"diamant" : "' + diamant + '", "expedipoint" :"' + expedipoint + '" }';
      this._http.put('https://expedigame.com/api/users', bodyAdd, httpOptions).subscribe(
        data => {
          const user_decoded = JSON.stringify(data);
          this.cookieService.set('username', user_decoded);
          console.log(this.userBanniereList)
          this.userBanniereList[index] = true;
          this.changeDetector.detectChanges();
        });

    } else {
      // this.needMoney = true;
      document.getElementById("openError").click();
    }
  }

  buyCadre(type, prix, id) {
    const user_part = JSON.parse(this.cookieService.get("username"));
    var diff;
    var diamant;
    var expedipoint;
    if (type == 1) {
      diff = user_part.data.expedipoint;
      diamant = user_part.data.diamant;
      expedipoint = user_part.data.expedipoint - prix;
      this.error_boutique = "Il vous manque des expedipoints";
      this.typeMoney = true;
    }
    if (type == 0) {
      diff = user_part.data.diamant;
      diamant = user_part.data.diamant - prix;
      expedipoint = user_part.data.expedipoint;
      this.error_boutique = "Il vous manque des diamants";
      this.typeMoney = false;
    }


    if (prix <= diff) {
      this.cookieService.set('diamant', diamant);
      this.cookieService.set('expedipoint', expedipoint);
      var httpHeader = {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
      };

      const body = "cadre=" + id;
      this._http.post("https://expedigame.com/api/userCadre/", body, httpHeader).subscribe();


      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
      };
      const bodyAdd = '{"diamant" : "' + diamant + '", "expedipoint" :"' + expedipoint + '" }';
      this._http.put('https://expedigame.com/api/users', bodyAdd, httpOptions).subscribe(
        data => {
          const user_decoded = JSON.stringify(data);
          this.cookieService.set('username', user_decoded);
          this.userCadreList[id - 1] = true;
        });

    } else {
      document.getElementById("openError").click();
    }
  }


  addCadre() {
    this._http.get("https://expedigame.com/api/cadre/boutique").subscribe(async data => {
      const cadre_decoded = JSON.stringify(data);

      const cadre_part = JSON.parse(cadre_decoded);
      this.cadreList = cadre_part.results
      if (cadre_part.next != null) {
        this._http.get(cadre_part.next).subscribe(data => {
          const cadre_decoded = JSON.stringify(data);

          const cadre_part = JSON.parse(cadre_decoded);
          for (var i = 0; i < cadre_part.results.length; i++) {
            this.cadreList.push(cadre_part.results[i]);
            // this.succesArray.push(banniere_part.results[i])
          }



        })
      }

      for (var i = 0; i < this.cadreList.length; i++) {
        var httpHeader = {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
        };
        const reponse = await this._http.get("https://expedigame.com/api/userCadre/" + this.cadreList[i].id + "/", httpHeader).toPromise().catch(err => this.userCadreList.push(false));
        if (reponse != this.cadreList[i].id) {
          this.userCadreList.push(true);
        }



      }
    });



  }

  addBanniere() {
    this._http.get("https://expedigame.com/api/banniere/boutique").subscribe(async data => {
      const banniere_decoded = JSON.stringify(data);

      const banniere_part = JSON.parse(banniere_decoded);
      this.banniereList = banniere_part.results
      if (banniere_part.next != null) {
        this._http.get(banniere_part.next).subscribe(data => {
          const banniere_decoded = JSON.stringify(data);

          const banniere_part = JSON.parse(banniere_decoded);
          for (var i = 0; i < banniere_part.results.length; i++) {
            this.banniereList.push(banniere_part.results[i]);
            // this.succesArray.push(banniere_part.results[i])

          }



        })
      }


      for (var i = 0; i < this.banniereList.length; i++) {

        var httpHeader = {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
        };
        const reponse = await this._http.get("https://expedigame.com/api/userBanniere/" + this.banniereList[i].id + "/", httpHeader).toPromise().catch(err => "");
        if (reponse != "") {
          this.userBanniereList.push(true);
          this.changeDetector.detectChanges();
        } else {
          this.userBanniereList.push(false);
          this.changeDetector.detectChanges();
        }






      }
    });


  }

  buyChest() {
    const user_part = JSON.parse(this.cookieService.get("username"));
    if (user_part.data.expedipoint >= 10000) {
      var money_fin = Number(user_part.data.expedipoint) - 10000;
      this.cookieService.set('expedipoint', money_fin.toString());
      var bodyAdd = '{"expedipoint" : "' + money_fin + '" }';
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
      };
      this._http.put('https://expedigame.com/api/users', bodyAdd, httpOptions).subscribe(
        data => {
          const user_decoded = JSON.stringify(data);
          this.cookieService.set('username', user_decoded);
          this.cookieService.set('chest', "1");
          this.numRandom = Math.floor(Math.random() * 1000);
          this.giftChest();
          document.getElementById("openModalButton").click();
          this.global.updateUserSucces(12);
          this.global.updateUserSucces(15, 10000);
          for (var i = 1; i <= 3; i++) {
            if (this.cookieService.get('quest_' + i) == "2") {
              this.updateQuestValue(i);
            }
          }
          // window.location.href = "http://localhost:4200/scratch";
        },
        err => {
          console.log(err['error']);
        }
      );

    }
    else {
      this.error_boutique = "Il vous manque des expedipoints";
      this.typeMoney = true;
      document.getElementById("openError").click();
    }
  }


  updateQuestValue(num_quest) {
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    var new_value = Number(this.cookieService.get("value_quest_" + num_quest)) + 1;
    console.log(new_value);
    const body = "currently_Value=" + new_value;
    this._http.put("https://expedigame.com/api/quest/" + this.cookieService.get('id_quest_' + num_quest) + "/", body, httpHeader).subscribe(data => {
      this.cookieService.set("value_quest_" + num_quest, new_value.toString());
    });
  }

  closeAlert() {
    this.needMoney = false;
  }

  validAchat(item) {
    switch (item) {
      case 1:
        //achat coffre
        break;
      case 2:
        //achat ticket a gratter
        break;
    }
  }

  chestOpen() {
    this.url = 'assets/img/chest-open.gif';

    setTimeout(() => {                           // <<<---using ()=> syntax
      this.url = 'assets/img/chest-opened.png';
      this.CardAppear = true;
    }, 200);
  }

  chestClose() {
    setTimeout(() => {                           // <<<---using ()=> syntax
      this.url = 'assets/img/chest-idle.gif';
      this.CardAppear = false;
    }, 200);


  }



  giftChest() {
    var bodyAdd;
    const user_part = JSON.parse(this.cookieService.get("username"));
    if (this.numRandom <= 659) {
      this.item = 1;
      bodyAdd = '{"expedipoint" : "' + (Number(user_part.data.expedipoint) + 100) + '" }';
      this.cookieService.set('expedipoint', (Number(user_part.data.expedipoint) + 100).toString())
    }
    else if (this.numRandom <= 799) {
      this.item = 2;
      bodyAdd = '{"expedipoint" : "' + (Number(user_part.data.expedipoint) + 20000) + '" }';
      this.cookieService.set('expedipoint', (Number(user_part.data.expedipoint) + 20000).toString())
    }
    else if (this.numRandom <= 993) {
      this.item = 3;
      bodyAdd = '{"diamant" : "' + (Number(user_part.data.diamant) + 5) + '" }';
      this.cookieService.set('diamant', (Number(user_part.data.diamant) + 5).toString())
    }
    else if (this.numRandom <= 998) {
      this.item = 4;
      bodyAdd = '{"ticket_sub" : "' + (Number(user_part.data.ticket_sub) + 1) + '" }';
    }
    else {
      this.item = 5;
      bodyAdd = '{"diamant" : "' + (Number(user_part.data.diamant) + 50) + '" }';
      this.cookieService.set('diamant', (Number(user_part.data.diamant) + 50).toString())
    }
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._http.put('https://expedigame.com/api/users', bodyAdd, httpOptions).subscribe(
      data => {
        this.cookieService.set('chest', "0");
        const user_decoded = JSON.stringify(data);
        this.cookieService.set('username', user_decoded);
      },
      err => {
        console.log(err['error']);
      }
    );
    console.log(this.item);
  }

  showItem(value) {
    if (value == this.item) {
      return true;
    }
    return false;
  }


}

import { Component, OnInit } from '@angular/core';
import { buildDriverProvider } from 'protractor/built/driverProviders';
import { logging } from 'protractor';
import { UserService } from '../user.service';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { countReset } from 'console';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { AppComponent } from '../app.component';


@Component({
  selector: 'app-nav',

  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public _item: string = "";
  private _hide: boolean = false;
  public test_value_update = "1";

  public user_part;


  constructor(private router: Router, public _userService: UserService, public cookieService: CookieService, private _http: HttpClient, private global: AppComponent) { }


  get hide() {
    if (this.cookieService.get('username') == '' || this.cookieService.get('username') == null) {
      this._hide = false;
    }
    else {
      this._hide = true;
      const user_part = JSON.parse(this.cookieService.get('username'));
      this.cookieService.set('expedipoint', user_part.data.expedipoint);
      this.cookieService.set('diamant', user_part.data.diamant);
      this._item = user_part.data.username;
    }

    return this._hide;

  }


  logout() {
    this.cookieService.deleteAll();
    this._userService.logout();
  }



  ngOnInit(): void {
    this.user_part = JSON.parse(this.cookieService.get('username'));
    if (this._hide == true) {

      this.updateDailyQuest();
    }
  }

  questUpdate(variant, numero_quest) {
    var getResult = false;
    switch (variant) {
      case 1:
        if (Number(this.cookieService.get("value_quest_" + numero_quest)) < Number(this.cookieService.get("goal_quest_" + numero_quest))) {
          getResult = true;
        }
        break;
      case 2:
        if ((Number(this.cookieService.get("value_quest_" + numero_quest)) >= Number(this.cookieService.get("goal_quest_" + numero_quest))) && (this.cookieService.get("quest_finish_" + numero_quest) == "false")) {
          getResult = true;
        }
        break;
      case 3:
        if (this.cookieService.get("quest_finish_" + numero_quest) == "true") {
          getResult = true;
        }

        break;
    }
    return getResult;
  }

  finishQuest(num_quest) {
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    const urlParameters = "quest_finish=true";
    this.global.updateUserSucces(10);
    this._http.put("https://expedigame.com/api/quest/" + this.cookieService.get("id_quest_" + num_quest) + "/", urlParameters, httpHeader).subscribe(data => {
      this.cookieService.set("quest_finish_" + num_quest, this.cookieService.get("quest_finish_" + num_quest))
      const user_part = JSON.parse(this.cookieService.get("username"));
      var xp_fin = Number(user_part.data.experience) + Number(this.cookieService.get("xp_quest_" + num_quest));
      const money_fin = Number(user_part.data.expedipoint) + Number(this.cookieService.get("point_quest_" + num_quest));
      this.cookieService.set('expedipoint', money_fin.toString());
      var level = user_part.data.level;
      if (xp_fin >= 1000) {
        this.global.updateUserSucces(13);
        level = Number(level + 1);
        this.global.updateClassement(50);
        this.levelUp(level, money_fin, user_part.data.expedipoint, this.cookieService.get("point_quest_" + num_quest));
        xp_fin = xp_fin - 1000;
      }
      else {
        Swal.fire({
          title: 'Quest',
          html: '<div class="overflow-hidden"><div class="row justify-content-center" style="overflow-x: hidden;"><b>Level ' + user_part.data.level + '</b> : &nbsp;<div class="counter font-weight-bold" data-target="' + xp_fin + '">' + user_part.data.experience + '</div> &nbsp;xp +&nbsp; <div class="counterReverse" data-target="0">' + this.cookieService.get("xp_quest_" + num_quest) + '</div>&nbsp; xp </div>  <div class="row">&nbsp;</div> <div class="row justify-content-center"><div class="counter font-weight-bold" data-target="' + money_fin + '">' + Number(user_part.data.expedipoint) + '</div> &nbsp;<i class="fas fa-coins"></i>&nbsp;&nbsp; + &nbsp;&nbsp;<div class="counterReverse" data-target="0">' + Number(this.cookieService.get("point_quest_" + num_quest)) + '</div>&nbsp;<i class="fas fa-coins"></i></div></div> ',

        })

        this.updateCount();
        this.reverseCount();
      }
      this.updateEXP_Point(xp_fin, money_fin, level, num_quest);

    });
  }

  levelUp(new_level, money_fin, money, money_quest) {
    Swal.fire({
      title: 'Level up : ' + new_level,
      html: '<div class="overflow-hidden"><div class="row">&nbsp;</div> <div class="row justify-content-center"><div class="counter font-weight-bold" data-target="' + money_fin + '">' + money + '</div> &nbsp;<i class="fas fa-coins"></i>&nbsp;&nbsp; + &nbsp;&nbsp;<div class="counterReverse" data-target="0">' + money_quest + '</div>&nbsp;<i class="fas fa-coins"></i></div></div> ',
      width: 400,
      padding: '3em',
      color: '#716add',
      background: '#fff url(https://sweetalert2.github.io/images/trees.png)',
      backdrop: `
        rgba(0,0,123,0.4)
        url("https://sweetalert2.github.io/images/nyan-cat.gif")
        left top
        no-repeat
      `
    });
    this.updateCount();
    this.reverseCount();
  }

  updateEXP_Point(xp, money, level, num_Quest) {
    const bodyTwitch = '{"experience" : "' + xp + '", "expedipoint" : "' + money + '","level" :"' + level + '" }';

    //voir creation body !
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._http.put('https://expedigame.com/api/users', bodyTwitch, httpHeader).subscribe(
      data => {
        const user_decoded = JSON.stringify(data);
        this.cookieService.set('username', user_decoded);
        this.cookieService.set('quest_finish_' + num_Quest, "true");
        if (this.cookieService.get('quest_finish_1') == "true" && this.cookieService.get('quest_finish_2') == "true" && this.cookieService.get('quest_finish_3') == "true") {
          this.getPrime();
          this.global.updateClassement(20);
          this.global.updateUserSucces(14);
        }
        console.log(data);
      },
      err => {
        console.log(err['error']);
      }
    );
  }

  getPrime() {
    const user_part = JSON.parse(this.cookieService.get('username'));
    var xp_fin = user_part.data.experience + 500;
    var level = user_part.data.level;
    var diamant = user_part.data.diamant + 5;
    this.cookieService.set('diamant', diamant);
    if (xp_fin >= 1000) {
      this.global.updateUserSucces(13);
      level = Number(level + 1);
      xp_fin = xp_fin - 1000;
    }
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    const bodyTwitch = '{"experience" : "' + xp_fin + '", "diamant" : "' + diamant + '","level" :"' + level + '" }';
    this._http.put('https://expedigame.com/api/users', bodyTwitch, httpHeader).subscribe(data => {
      const user_decoded = JSON.stringify(data);
      this.cookieService.set('username', user_decoded);
    });
  }


  updateDailyQuest() {
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };

    this._http.get("https://expedigame.com/api/quest/", httpHeader).subscribe(
      data => {

        const data_decoded = JSON.stringify(data);
        const data_part = JSON.parse(data_decoded);

        if (this.cookieService.get("id_quest_1") == "" || this.cookieService.get("date_quest") != data_part.results[0].date_quest) {

          console.log("on est la")
          console.log(data_part.results[0].id);
          this.cookieService.set("date_quest", data_part.results[0].date_quest);
          this.cookieService.set("id_quest_1", data_part.results[0].id);
          this.cookieService.set("quest_1", data_part.results[0].quest);
          this.cookieService.set("value_quest_1", data_part.results[0].currently_Value);
          this.cookieService.set("id_quest_2", data_part.results[1].id);
          this.cookieService.set("quest_2", data_part.results[1].quest);
          this.cookieService.set("value_quest_2", data_part.results[1].currently_Value);
          this.cookieService.set("id_quest_3", data_part.results[2].id);
          this.cookieService.set("quest_3", data_part.results[2].quest);
          this.cookieService.set("value_quest_3", data_part.results[2].currently_Value);
          this.cookieService.set("quest_finish_1", data_part.results[0].quest_finish);
          this.cookieService.set("quest_finish_2", data_part.results[1].quest_finish);
          this.cookieService.set("quest_finish_3", data_part.results[2].quest_finish);

          this._http.get("https://expedigame.com/api/objectif/" + data_part.results[0].quest + "/", httpHeader).subscribe(data => {
            const objectif_decoded = JSON.stringify(data);
            const objectif_part = JSON.parse(objectif_decoded);
            this.cookieService.set("goal_quest_1", objectif_part.goal_value);
            this.cookieService.set("title_quest_1", objectif_part.titre);
            this.cookieService.set("desc_quest_1", objectif_part.description);
            this.cookieService.set("xp_quest_1", objectif_part.gain_exp);
            this.cookieService.set("point_quest_1", objectif_part.gain_point);

          });

          this._http.get("https://expedigame.com/api/objectif/" + data_part.results[1].quest + "/", httpHeader).subscribe(data => {
            const objectif_decoded = JSON.stringify(data);
            const objectif_part = JSON.parse(objectif_decoded);
            this.cookieService.set("goal_quest_2", objectif_part.goal_value);
            this.cookieService.set("title_quest_2", objectif_part.titre);
            this.cookieService.set("desc_quest_2", objectif_part.description);
            this.cookieService.set("xp_quest_2", objectif_part.gain_exp);
            this.cookieService.set("point_quest_2", objectif_part.gain_point);

          });

          this._http.get("https://expedigame.com/api/objectif/" + data_part.results[2].quest + "/", httpHeader).subscribe(data => {
            const objectif_decoded = JSON.stringify(data);
            const objectif_part = JSON.parse(objectif_decoded);
            this.cookieService.set("goal_quest_3", objectif_part.goal_value);
            this.cookieService.set("title_quest_3", objectif_part.titre);
            this.cookieService.set("desc_quest_3", objectif_part.description);
            this.cookieService.set("xp_quest_3", objectif_part.gain_exp);
            this.cookieService.set("point_quest_3", objectif_part.gain_point);

          });
        }
      });

  }

  updateCount() {
    const counters = document.querySelectorAll('.counter');
    const speed = 200;

    counters.forEach(counter => {
      const updateValue = () => {
        const target = +counter.getAttribute('data-target');
        var count = +counter.innerHTML;

        const inc = 1000 / speed;

        if (count < target) {
          counter.innerHTML = Math.ceil(count + inc).toString();
          setTimeout(updateValue, 1);


        } else {
          count = target;
        }

      }
      updateValue();
    });
  }

  reverseCount() {
    const counters = document.querySelectorAll('.counterReverse');
    const speed = 200;

    counters.forEach(counter => {
      const updateValue = () => {
        const target = +counter.getAttribute('data-target');
        var count = +counter.innerHTML;

        const inc = 1000 / speed;
        if (count > target) {
          counter.innerHTML = Math.ceil(count - inc).toString();
          setTimeout(updateValue, 1);


        } else {
          count = target;
        }

      }
      updateValue();
    });
  }


}

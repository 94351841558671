import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../user.service';
import { AppComponent } from '../app.component';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  public user: any;


  constructor(public _userService: UserService) {
  }



  ngOnInit() {
    this._userService.errors = "";
    this.user = {
      username: '',
      password: ''
    };
  }

  login() {

    this._userService.login({ 'username': this.user.username, 'password': this.user.password }, "");


  }



  refreshToken() {
    this._userService.refreshToken();
  }

  logout() {
    this._userService.logout();
  }



}

import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { cpuUsage } from 'process';
import { TwitchEmbed, TwitchEmbedLayout, TwitchPlayerEvent } from 'twitch-player';
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.scss']
})
export class TVComponent implements OnInit {


  public LegoPari;
  public viewerImageLego = "https://static-cdn.jtvnw.net/user-default-pictures-uv/ebb84563-db81-4b9c-8940-64ed33ccfc7b-profile_image-300x300.png"
  public viewerLego = "viewer"

  public HorreurOne;
  public viewerImage_HorreurOne = "https://static-cdn.jtvnw.net/user-default-pictures-uv/ebb84563-db81-4b9c-8940-64ed33ccfc7b-profile_image-300x300.png"
  public viewer_HorreurOne = "viewer"
  public HorreurTwo;
  public viewerImage_HorreurTwo = "https://static-cdn.jtvnw.net/user-default-pictures-uv/ebb84563-db81-4b9c-8940-64ed33ccfc7b-profile_image-300x300.png"
  public viewer_HorreurTwo = "viewer"
  public HorreurThree;
  public viewerImage_HorreurThree = "https://static-cdn.jtvnw.net/user-default-pictures-uv/ebb84563-db81-4b9c-8940-64ed33ccfc7b-profile_image-300x300.png"
  public viewer_HorreurThree = "viewer"

  public tableauAgrou = new Array(9);


  public liveLancer = false;

  public textAlert: String;
  public infoAlert: number = 0;

  public nbreJoueur = 9

  private dataExchange: boolean = false;
  private pari: boolean = false;

  public timerInterval: any;
  public listener: any;
  public commandeGiveAway: boolean = false;
  public validGiveAway: boolean = false;
  public CommandeMarioKart: boolean = false;
  public CommandeLEGO: boolean = false;
  public CommandeHorreur: boolean = false;
  public commandeAmongUs: boolean = false;
  public commandeAgrou: boolean = false;

  public CommandeGlobal: boolean = false;

  public userList;

  private httpOptions: any;
  public groupAuthorization: boolean = false;
  public navProfil: boolean = false;
  public test: any;

  constructor(private _httpClient: HttpClient, private route: ActivatedRoute, private cookieService: CookieService, private service: AppServiceService, private global: AppComponent) { }
  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
    clearInterval(this.listener);
    this.liveLancer = false;
    if (this.dataExchange) { this.service.stopExchangeUpdates(); }

  }

  ngOnInit(): void {
    if (this.cookieService.get("countInterval") != null) {
      for (var i = 0; i < Number(this.cookieService.get("countInterval")); i++) {
        window.clearInterval(i);
      }
      this.cookieService.set("countInterval", "0");
    }
    this.getListUser();
    for (var i = 0; i < this.tableauAgrou.length; i++) {
      this.tableauAgrou[i] = true;
    }
    var date_cookie = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en_US');
    this.cookieService.set("time_cookie", date_cookie);

    //////////// Afficher la chaine twitch ///////////////////////
    const embed = new TwitchEmbed('twitch-embed', {
      width: 1280,
      height: 720,
      channel: 'expedigame',
      parent: ["localhost", "expedigame.com"],
      layout: TwitchEmbedLayout.VIDEO_WITH_CHAT
    });

    ///////Alerte = l'utilisateur n'est pas connecté //////////////////////
    if (this.cookieService.get('username') == "") {
      this.infoAlert = 1;
      this.textAlert = "Veuillez vous connecter pour accéder aux fonctionnalités du site."
    }
    ///////Alerte = l'utilisateur n'a pas de compte TWITCH//////////////////////
    else if (JSON.parse(this.cookieService.get('username')).data.twitch_name == "" || JSON.parse(this.cookieService.get('username')).data.twitch_name == null) {
      this.infoAlert = 1;
      this.textAlert = "Veuillez relier votre compte twitch via votre <a href='https://expedigame.com/profil' >Profil</a> pour accéder aux fonctionnalités du site.";
    }
    ///////utilisateur connecté avec TWITCH//////////////////////
    else {
      this.giveAwayDispo();
      this.numberOfInterval();
      this.listener = setInterval(() => {
        this.getPari();
      }, 5000);
      this.CommandeGlobal = true;
      // this.service.returnAsObservable().subscribe(data => {
      /*      const data_decoded = JSON.stringify(data);
            const data_part = JSON.parse(data_decoded);
            console.log(data);
            if (data_part.PariEnCours && this.pari == false) {
              this.pari = true;
              switch (data_part.type_pari) {
                case 1:
                  this.CommandeMarioKart = data_part.PariEnCours;
                  this.infoAlert = 2;
                  this.textAlert = "Un pari a été lancé, vous pouvez parier via les boutons sous l'onglet Mario Kart"
                  break;
                case 2:
                  this.CommandeLEGO = data_part.PariEnCours;
                  this.getPariLego(data_part.viewer_1);
                  break;
                case 3:
                  this.CommandeHorreur = data_part.PariEnCours;
                  this.getPariHorreur(data_part.viewer_1, data_part.viewer_2, data_part.viewer_3);
                  break;
              }
            }
    
            if (data_part.PariEnCours == false) {
              this.pari = false;
              this.CommandeMarioKart = false;
              this.CommandeHorreur = false;
              this.CommandeLEGO = false;
            }
    
    
    
            if (JSON.parse(data_decoded).resultat != 0 && JSON.parse(data_decoded).resultat != undefined) { this.getResultat(JSON.parse(data_decoded).resultat, data_part.type_pari); }
            if (JSON.parse(data_decoded).luma == true) { this.getLuma(); }
          });*/

      // this.service.GetExchangeData();
      //  this.dataExchange = true;
      ///// a SUPPRIMER / DELETE
      if (this.cookieService.get('position') == null) {
        this.cookieService.set('position', "0");
      }


      const user_part = JSON.parse(this.cookieService.get('username'));
      if (user_part.data.groups[0] == 'https://expedigame.com/api/groups/2/') {
        this.groupAuthorization = true;
      }

    }
    ///////////////////////////////////////////////////////////////////////
  }



  numberOfInterval() {
    if (this.cookieService.get("countInterval") == null) {
      this.cookieService.set("countInterval", "1");
    } else {
      let interval = Number(this.cookieService.get("countInterval")) + 1;
      this.cookieService.set("countInterval", interval.toString());
    }

  }

  beginLive() {
    this.timerInterval = setInterval(() => {
      this.moreMinute();
    }, 60000);
  }

  launchLive(value) {
    const data = { liveBegin: value };
    this.service.postLive(data).subscribe((response) => {
      console.log('Response from API is ', response);
    }, (error) => {
      console.log('Error is ', error);
    });

  }

  endedLive() {
    clearInterval(this.timerInterval);
  }

  giveAwayDispo() {
    var dateToday = new Date();
    var dateGet = "";

    if (dateToday.getHours() < 12) {
      dateGet = formatDate(new Date(Date.now() - 1), 'yyyy-MM-dd', 'en_US');
    } else {
      dateGet = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    }

    this._httpClient.get('https://expedigame.com/api/giveaway/' + dateGet + '/').subscribe(data => {
      this.validGiveAway = true;
    });
  }

  giveAway(number) {
    if (Number(this.cookieService.get('expedipoint')) > Number(number)) {
      const user_part = JSON.parse(this.cookieService.get("username"));
      var money_fin = Number(this.cookieService.get('expedipoint')) - number;
      this.cookieService.set('expedipoint', money_fin.toString());
      console.log(this.cookieService.get('expedipoint'))
      var bodyAdd = '{"expedipoint" : "' + money_fin + '" }';
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
      };
      this._httpClient.put('https://expedigame.com/api/users', bodyAdd, httpOptions).subscribe(data_user => {
        const user_decoded = JSON.stringify(data_user);
        this.cookieService.set('username', user_decoded);
        var dateToday = new Date();
        var dateGet = "";

        if (dateToday.getHours() < 12) {
          dateGet = formatDate(new Date().getDate() - 1, 'yyyy-MM-dd', 'en_US');
        } else {
          dateGet = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
        }

        this._httpClient.get('https://expedigame.com/api/giveaway/' + dateGet + '/').subscribe(data => {
          const data_decoded = JSON.stringify(data);
          const data_part = JSON.parse(data_decoded);
          var value = data_part.valeur_actuelle;
          var palier = 0;
          var rang = data_part.rang;
          switch (rang) {
            case 0:
              palier = data_part.palier_1;
              break;
            case 1:
              palier = data_part.palier_2;
              break;
            case 2:
              palier = data_part.palier_3;
              break;
            default:
              palier = data_part.palier_3;
              break;
          }
          value = value + number
          if (value >= palier && rang < 3) {
            rang = rang + 1;
          }
          for (var i = 1; i <= 3; i++) {
            if (this.cookieService.get('quest_' + i) == "9") {
              this.updateQuestValue(i, number);
            }
          }

          const body = "valeur_actuelle=" + value + "&rang=" + rang;
          this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
          };
          this._httpClient.put('https://expedigame.com/api/giveaway/' + dateGet + '/', body, this.httpOptions).subscribe(data => { });
        })
      });
    }

  }

  /////////////Permet de changer d'onglet entre commande et Modération (utile que pour les MODO et ADMIN)//////////////////
  navChange(numNav) {
    if ((numNav == 2 && this.navProfil == false) || (numNav == 1 && this.navProfil == true)) {
      this.navProfil = !this.navProfil;
    }
  }
  ///////////////////////////////////////////////////////////////////////

  getPari() {
    this.service.getPari().subscribe((response) => {
      console.log('Response from API is ', response);
      const user_part = JSON.parse(this.cookieService.get('username'));
      const data_decoded = JSON.stringify(response);
      const data_part = JSON.parse(data_decoded);
      if (data_part.test == user_part.data.twitch_name && data_part.liveBegin == true) {
        for (var i = 1; i <= 3; i++) {
          if (this.cookieService.get('quest_' + i) == "10") {
            this.updateQuestValue(i);
          }
        }
      }
      if (data_part.liveBegin == true && this.liveLancer == false) {
        this.liveLancer = true;
        this.beginLive();
        if (this.validGiveAway == true) {
          this.commandeGiveAway = true;
        }
      }

      if (data_part.liveBegin == false) {
        this.liveLancer = false;
        this.commandeGiveAway = false;
        this.endedLive();
      }

      if (data_part.PariEnCours && this.pari == false) {
        this.pari = true;
        switch (data_part.type_pari) {
          case 1:
            this.CommandeMarioKart = data_part.PariEnCours;
            this.infoAlert = 2;
            this.textAlert = "Un pari a été lancé, vous pouvez parier via les boutons sous l'onglet Mario Kart"
            break;
          case 2:
            this.CommandeLEGO = data_part.PariEnCours;
            this.getPariLego(data_part.viewer_1);
            break;
          case 3:
            this.CommandeHorreur = data_part.PariEnCours;
            this.getPariHorreur(data_part.viewer_1, data_part.viewer_2, data_part.viewer_3);
            break;
          case 4:
            this.commandeAmongUs = data_part.PariEnCours;
            this.infoAlert = 2;
            this.nbreJoueur = data_part.nbrePlayer;
            this.textAlert = "Un pari a été lancé, vous pouvez parier via les boutons sous l'onglet Among us"
            break;
          case 5:
            for (var i = 0; i < data_part.tableauAgrou.length; i++) {
              this.tableauAgrou[i] = data_part.tableauAgrou[i];
            }
            this.commandeAgrou = data_part.PariEnCours;
            this.infoAlert = 2;
            this.textAlert = "Un pari a été lancé, vous pouvez parier via les boutons sous l'onglet Agrou"
            break;
        }
      }

      if (data_part.PariEnCours == false) {
        this.pari = false;
        this.CommandeMarioKart = false;
        this.CommandeHorreur = false;
        this.CommandeLEGO = false;
        this.commandeAgrou = false;
        this.commandeAmongUs = false;
      }



      if (JSON.parse(data_decoded).resultat != 0 && JSON.parse(data_decoded).resultat != undefined) { this.getResultat(JSON.parse(data_decoded).resultat, data_part.type_pari); }
      if (JSON.parse(data_decoded).luma == true) { this.getLuma(); }
    });

  }

  getListUser() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._httpClient.get('https://expedigame.com/api/userTwitch', this.httpOptions).subscribe(async data => {
      const user_decoded = JSON.stringify(data);
      const user_part = JSON.parse(user_decoded);

      this.userList = user_part.results;
      console.log(this.userList)

      var next = user_part.next
      while (next != null) {
        var reponse = await this._httpClient.get(next, this.httpOptions).toPromise();
        const rep_decoded = JSON.stringify(reponse);
        const rep_part = JSON.parse(rep_decoded);
        for (var i = 0; i < rep_part.results.length; i++) {
          this.userList.push(rep_part.results[i]);
        }
        next = rep_part.next
      }
    });
  }

  addPariLego() {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const data = { twitch_name: user_part.data.twitch_name, lego_Viewer: this.LegoPari, type_pari: 2 };
    this.service.postLanceur(data).subscribe((response) => {
      console.log('Response from API is ', response);
    }, (error) => {
      console.log('Error is ', error);
    });


  }

  addPariAgrou() {
    const user_part = JSON.parse(this.cookieService.get('username'));
    var tab = new Array();
    tab[0] = (<HTMLInputElement>document.getElementById("sorciere")).checked;
    tab[1] = (<HTMLInputElement>document.getElementById("amour")).checked;
    tab[2] = (<HTMLInputElement>document.getElementById("chasseur")).checked;
    tab[3] = (<HTMLInputElement>document.getElementById("dictateur")).checked;
    tab[4] = (<HTMLInputElement>document.getElementById("assassin")).checked;
    tab[5] = (<HTMLInputElement>document.getElementById("pyroman")).checked;
    tab[6] = (<HTMLInputElement>document.getElementById("blaster")).checked;
    tab[7] = (<HTMLInputElement>document.getElementById("ninja")).checked;
    tab[8] = (<HTMLInputElement>document.getElementById("loup")).checked;
    const data = { twitch_name: user_part.data.twitch_name, tabAgrou: tab, type_pari: 5 };
    this.service.postLanceur(data).subscribe((response) => {
      console.log('Response from API is ', response);
    }, (error) => {
      console.log('Error is ', error);
    });


  }

  getPariLego(name_viewer) {
    this.viewerLego = name_viewer
    this.infoAlert = 2;
    this.textAlert = "Un pari a été lancé, vous pouvez parier via les boutons sous l'onglet LEGO"
    this._httpClient.get("https://expedigame.com/api/userView/" + name_viewer).subscribe(data => {
      const user_decoded = JSON.stringify(data);
      const user_part = JSON.parse(user_decoded);
      this.viewerImageLego = user_part.img_profil;

    });
  }

  addPariAmongUs() {
    var nJoueur = (<HTMLInputElement>document.getElementById("nbrePlayer")).value;
    if (Number(nJoueur) > 1) {
      const user_part = JSON.parse(this.cookieService.get('username'));
      const data = { twitch_name: user_part.data.twitch_name, nbrePlayer: nJoueur, type_pari: 4 };
      this.service.postLanceur(data).subscribe((response) => {
        console.log('Response from API is ', response);
      }, (error) => {
        console.log('Error is ', error);
      });
    }



  }



  addPariHorreur() {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const data = { twitch_name: user_part.data.twitch_name, Horreur_1: this.HorreurOne, Horreur_2: this.HorreurTwo, Horreur_3: this.HorreurThree, type_pari: 3 };
    this.service.postLanceur(data).subscribe((response) => {
      console.log('Response from API is ', response);
    }, (error) => {
      console.log('Error is ', error);
    });

  }


  getPariHorreur(name_viewer_1, name_viewer_2, name_viewer_3) {

    this.infoAlert = 2;
    this.textAlert = "Un pari a été lancé, vous pouvez parier via les boutons sous l'onglet Horreur"
    this.viewer_HorreurOne = name_viewer_1;
    this.viewer_HorreurTwo = name_viewer_2;
    this.viewer_HorreurThree = name_viewer_3;

    this._httpClient.get("https://expedigame.com/api/userView/" + name_viewer_1).subscribe(data => {
      const user_decoded = JSON.stringify(data);
      const user_part = JSON.parse(user_decoded);
      this.viewerImage_HorreurOne = user_part.img_profil;
    });

    this._httpClient.get("https://expedigame.com/api/userView/" + name_viewer_2).subscribe(data => {
      const user_decoded = JSON.stringify(data);
      const user_part = JSON.parse(user_decoded);
      this.viewerImage_HorreurTwo = user_part.img_profil;
    });

    this._httpClient.get("https://expedigame.com/api/userView/" + name_viewer_3).subscribe(data => {
      const user_decoded = JSON.stringify(data);
      const user_part = JSON.parse(user_decoded);
      this.viewerImage_HorreurThree = user_part.img_profil;
    });
  }

  moreMinute() {
    var newDate = Date.parse(this.cookieService.get("time_cookie")) + 60000;
    var dateNow = Date.parse(formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en_US'));
    if (dateNow >= newDate) {
      var newDateString = formatDate(newDate, 'yyyy-MM-dd HH:mm:ss', 'en_US')
      this.cookieService.set("time_cookie", newDateString);
      const user_part = JSON.parse(this.cookieService.get('username'));
      user_part.data.minute_watched += 1;
      user_part.data.expedipoint += 50
      for (var i = 1; i <= 3; i++) {
        if (this.cookieService.get('quest_' + i) == "1") {
          this.updateQuestValue(i);
        }
      }
      this.global.updateClassement(1);
      const bodyTwitch = '{"minute_watched" : "' + user_part.data.minute_watched + '", "expedipoint":"' + user_part.data.expedipoint + '" }'; //voir creation body !
      this.httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
      };
      this.global.updateUserSucces(4);
      this._httpClient.put('https://expedigame.com/api/users', bodyTwitch, this.httpOptions).subscribe(
        data => {
          const user_decoded = JSON.stringify(data);
          this.cookieService.set('username', user_decoded);
          console.log(data);
        },
        err => {
          console.log(err['error']);
        }
      );
    }
  }


  postLego_horreur(name, pos, img) {
    if (this.CommandeHorreur || this.CommandeLEGO) {
      this.cookieService.set('position', pos);
      this.infoAlert = 2;
      this.textAlert = "Vous avez voté pour " + name;

      const data_image = { url: img };
      this.service.postImage(data_image).subscribe((response) => {
        console.log('Response from API is ', response);
      }, (error) => {
        console.log('Error is ', error);
      });
    }
  }


  postAgrou(pos, img, text) {
    if (this.commandeAgrou) {
      this.cookieService.set('position', pos);
      this.infoAlert = 2;
      if (pos == 11) {
        this.textAlert = "Les loups vont remporter la partie"
      }
      else {
        this.textAlert = "Le loup se fera tuer par " + text;
      }


      const data_image = { url: img };
      this.service.postImage(data_image).subscribe((response) => {
        console.log('Response from API is ', response);
      }, (error) => {
        console.log('Error is ', error);
      });

    }
  }

  postAmongus(pos, img) {
    if (this.commandeAmongUs) {
      this.cookieService.set('position', pos);
      this.infoAlert = 2;
      if (pos == 14) {
        this.textAlert = "Vous avez voté pour la victoire des imposteurs";
      } else {
        this.textAlert = "Vous avez voté pour " + pos + " morts durant la partie";
      }
      const data_image = { url: img };
      this.service.postImage(data_image).subscribe((response) => {
        console.log('Response from API is ', response);
      }, (error) => {
        console.log('Error is ', error);
      });

    }
  }

  postPari(pos, img) {
    if (this.CommandeMarioKart) {
      this.cookieService.set('position', pos);
      this.infoAlert = 2;
      if (pos == 1) {
        this.textAlert = "Vous avez voté pour la 1ère place";
      } else {
        this.textAlert = "Vous avez voté pour la " + pos + "ème place";
      }
      const data_image = { url: img };
      this.service.postImage(data_image).subscribe((response) => {
        console.log('Response from API is ', response);
      }, (error) => {
        console.log('Error is ', error);
      });

    }
  }

  postGagnant(pos) {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const data = { position: pos };
    if (user_part.data.twitch_name != null) {
      this.service.postGagnant(data).subscribe((response) => {
        console.log('Response from API is ', response);
      }, (error) => {
        console.log('Error is ', error);
      });

    }
    else {
      alert('Veuillez relier votre compte twitch : http://localhost:4200/profil');
    }

  }

  lancerParis() {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const data = { twitch_name: user_part.data.twitch_name, type_pari: 1 };
    this.service.postLanceur(data).subscribe((response) => {
      console.log('Response from API is ', response);
    }, (error) => {
      console.log('Error is ', error);
    });
  }

  getResultat(victoire, type_pari) {

    if (Number(this.cookieService.get('position')) != 0) {
      if (victoire == this.cookieService.get('position')) {
        const user_part = JSON.parse(this.cookieService.get('username'));
        var data = { id_player: user_part.data.username }
        this.service.postWinner(data).subscribe();
        this.cookieService.set('position', "0");
        switch (type_pari) {
          case 1: // mario kart
            this.global.updateUserSucces(1);
            for (var i = 1; i <= 3; i++) {
              if (this.cookieService.get('quest_' + i) == "3") {
                this.updateQuestValue(i);
              }
            }
            break;
          case 2: //lego
            this.global.updateUserSucces(8);
            for (var i = 1; i <= 3; i++) {
              if (this.cookieService.get('quest_' + i) == "5") {
                this.updateQuestValue(i);
              }
            }
            break;
          case 3: //horreur
            this.global.updateUserSucces(5);
            for (var i = 1; i <= 3; i++) {
              if (this.cookieService.get('quest_' + i) == "6") {
                this.updateQuestValue(i);
              }
            }
            break;

          case 4: //among us
            //  this.global.updateUserSucces(id_among_us);
            for (var i = 1; i <= 3; i++) {
              if (this.cookieService.get('quest_' + i) == "7") {
                this.updateQuestValue(i);
              }
            }
            break;
          case 5: //among us
            //  this.global.updateUserSucces(id_among_us);
            for (var i = 1; i <= 3; i++) {
              if (this.cookieService.get('quest_' + i) == "8") {
                this.updateQuestValue(i);
              }
            }
            break;


        }
        this.textAlert = "Félicitations vous avez gagné";
        this.global.updateUserSucces(2);
        this.infoAlert = 3;
      }
      else {
        this.cookieService.set('position', "0");
        this.global.updateUserSucces(2);
        this.textAlert = "Malheureusement vous avez fait le mauvais choix";
        this.infoAlert = 1;
      }
    }
  }



  updateQuestValue(num_quest, number = 1) {
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    var new_value = Number(this.cookieService.get("value_quest_" + num_quest)) + number;
    console.log(new_value);
    const body = "currently_Value=" + new_value;
    this._httpClient.put("https://expedigame.com/api/quest/" + this.cookieService.get('id_quest_' + num_quest) + "/", body, httpHeader).subscribe(data => {
      this.cookieService.set("value_quest_" + num_quest, new_value.toString());
    });
  }


  deleteAlert() {
    this.infoAlert = 0;
  }

  afficherAlert(number) {
    var valeurRetour = false;
    switch (number) {
      case 1: {
        if (this.infoAlert == 1) {
          valeurRetour = true;
        }
        break;
      }
      case 2: {
        if (this.infoAlert == 2) {
          valeurRetour = true;
        }
        break;
      }
      case 3: {
        if (this.infoAlert == 3) {
          valeurRetour = true;
        }
        break;
      }
      default: {
        valeurRetour = false;
        break;
      }
    }
    return valeurRetour;
  }


  getLuma() {

    if (this.cookieService.get('luma') == "false" || this.cookieService.get('luma') == "") {
      this.textAlert = "Incroyable ça brille en plein live !";
      this.global.updateUserSucces(11);
      this.infoAlert = 3;
      this.cookieService.set('luma', "true");
      setTimeout(() => {
        this.cookieService.set('luma', "false");
      }, 65000);
    }
  }




  postLuma() {
    const user_part = JSON.parse(this.cookieService.get('username'));
    const data = { twitch_name: user_part.data.twitch_name };
    this.service.postLuma(data).subscribe((response) => {
      console.log('Response from API is ', response);
    }, (error) => {
      console.log('Error is ', error);
    });
  }





}

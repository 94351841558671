<!-- Material form login -->
<app-separateur></app-separateur>
<app-separateur></app-separateur>

<app-separateur></app-separateur>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
  <mdb-card class="w-50">

    <mdb-card-header class="info-color white-text text-center py-4">
      <h5>
        <strong>Se connecter</strong>
      </h5>
    </mdb-card-header>


    <!--Card content-->
    <mdb-card-body class="px-lg-5 pt-0">

      <!-- Form -->
      <form class="text-center" *ngIf="!_userService.token" style="color: #757575;">

        <!-- Email -->
        <div class="md-form">
          <input type="text" id="materialLoginFormEmail" name="login-username" [(ngModel)]="user.username"
            class="form-control" mdbInput>
          <label for="materialLoginFormEmail">Pseudo</label>
        </div>

        <!-- Password -->
        <div class="md-form">
          <input type="password" id="materialLoginFormPassword" class="form-control" name="login-password"
            [(ngModel)]="user.password" mdbInput>
          <label for="materialLoginFormPassword">Mot de passe</label>
        </div>

        <div class="d-flex justify-content-around">

          <div>
            <!-- Forgot password -->
            <a href="">Mot de passe oublié ?</a>
          </div>
        </div>

        <!-- Sign in button -->
        <button mdbBtn color="info" outline="true" rounded="true" block="true" class="my-4 waves-effect z-depth-0"
          mdbWavesEffect (click)="login()" type="submit">Se connecter</button>

        <!-- Register -->
        <p>Pas encore membre ?
          <a routerLink="/inscription" routerLinkActive="active">Inscris-toi</a>
        </p>


      </form>


      <!--   <div class="row" *ngIf="_userService.token">
        <div class="col-sm-12">Vous êtes maintenant connecté en tant{{ _userService.username }}.<br />
          Token Expires: {{ _userService.token_expires }}<br />
          <button (click)="refreshToken()" class="btn btn-primary">Refresh Token</button>
          <button (click)="logout()" class="btn btn-primary">Log Out</button>
        </div>
      </div>-->
      <!-- Form -->

    </mdb-card-body>

  </mdb-card>

</div>
<div class="d-flex justify-content-center align-items-center" *ngIf="_userService.errors != ''" style="height: 100px;">
  <div class="d-flex justify-content-center w-50  text-white rounded-pill h-50 border border-danger align-items-center"
    style="background-color:#7f1d1d;">

    <div [innerHTML]="_userService.errors"></div>
    <!--<a class="text-white-50" (click)="deleteAlert()">
          <mdb-icon fas icon="times"></mdb-icon>
      </a>    -->

  </div>
</div>
<app-separateur></app-separateur>


<!-- Material form login -->
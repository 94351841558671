import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  private httpOptions;
  constructor(private _httpClient: HttpClient, private route: ActivatedRoute, private cookieService: CookieService) { }


  client_id = 'xn3g12yzv87p0ohpnfsi6o1rh638dm';
  code = this.route.snapshot.queryParamMap.get('code');
  body = new HttpParams()
    .set('client_id', this.client_id)
    .set('client_secret', 'm3mj8i4vchbik7gc037c53iv8acrgp')
    .set('code', this.code)
    .set('grant_type', 'authorization_code')
    .set('redirect_uri', 'http://localhost:4200/404/');
  ngOnInit(): void {
    this.showTwitch()

  }

  showTwitch() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    this._httpClient.post('https://id.twitch.tv/oauth2/token?' + this.body.toString(), this.body.toString(), this.httpOptions).subscribe(
      data => {
        const token_decoded = JSON.stringify(data);
        const token_part = JSON.parse(token_decoded);
        this.cookieService.set('token_twitch', token_part.access_token);
        console.log(data);
        this._httpClient.get('https://api.twitch.tv/helix/users',
          {
            headers: new HttpHeaders()
              .set('Accept', 'application/vnd.twitchtv.v5+json')
              .set('Client-ID', this.client_id)
              .set('Authorization', 'Bearer ' + this.cookieService.get('token_twitch'))
          }).subscribe(
            data_2 => {
              console.log(data_2);
              const twitch_decoded = JSON.stringify(data_2);
              const twitch_part = JSON.parse(twitch_decoded);
              const httpOptionsss = {
                headers: new HttpHeaders({ 'Content-Type': 'application/vnd.twitchtv.v5+json', 'Client-ID': this.client_id, Authorization: 'Bearer ' + token_part.access_token })
              };
              this._httpClient.get('https://api.twitch.tv/helix/subscriptions/user?broadcaster_id=247653042&user_id=' + twitch_part.data[0].id, httpOptionsss).subscribe(data_3 => {

              });

              this._httpClient.get('https://api.twitch.tv/helix/clips?broadcaster_id=247653042', httpOptionsss).subscribe(async data_3 => {
                var httpHeader = {
                  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
                };
                const clip_decoded = JSON.stringify(data_3);
                const clip_part = JSON.parse(clip_decoded);
                for (var i = 0; i < clip_part.data.length; i++) {


                  const urlParameters = "id_clip=" + clip_part.data[i].id + "&user_id_twitch=" + clip_part.data[i].creator_id + "&url_video=" + clip_part.data[i].url + "&url_img=" + clip_part.data[i].thumbnail_url + "&count_view=" + clip_part.data[i].view_count;
                  this._httpClient.post('https://expedigame.com/api/clip/', urlParameters, httpHeader).subscribe(data_post => {

                  });
                }

                var cursor = clip_part.pagination.cursor
                console.log(cursor)
                for (var i = 0; i < 200; i++) {// (cursor != null && cursor != "")


                  const httpOptions = {
                    headers: new HttpHeaders({ 'Content-Type': 'application/vnd.twitchtv.v5+json', 'Client-ID': this.client_id, Authorization: 'Bearer ' + token_part.access_token })
                  };
                  const reponse = await this._httpClient.get('https://api.twitch.tv/helix/clips?broadcaster_id=247653042&after=' + cursor, httpOptions).toPromise();
                  const clip_decoded = JSON.stringify(reponse);
                  const clip_part = JSON.parse(clip_decoded);

                  for (var i = 0; i < clip_part.data.length; i++) {

                    var httpHeader = {
                      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
                    };
                    const urlParameters = "id_clip=" + clip_part.data[i].id + "&user_id_twitch=" + clip_part.data[i].creator_id + "&url_video=" + clip_part.data[i].url + "&url_img=" + clip_part.data[i].thumbnail_url + "&count_view=" + clip_part.data[i].view_count;
                    this._httpClient.post('https://expedigame.com/api/clip/', urlParameters, httpHeader).subscribe(data_post => {

                    });
                  }


                  cursor = clip_part.pagination.cursor;




                }

              });
              this._httpClient.get('https://api.twitch.tv/helix/clips?id=HorribleBlightedWerewolfCmonBruh', httpOptionsss).subscribe(data_3 => {

              });
            },
            err_2 => {
              //        console.log(err_2['error']);
            }
          );


        ;
      },
      err => {
        //    console.log(err['error']);
      }
    );
  }


}

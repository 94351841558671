<!-- Footer -->
<footer class="page-footer font-small danger-color-dark">

  <!-- Footer Elements -->
  <div class="container">

    <!-- Grid row-->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 py-1">
        <div class="mb-5 flex-center">


          <!-- Twitter -->
          <a class="tw-ic" href="https://twitter.com/expedigame1" target="_blank">
            <mdb-icon fab icon="twitter" size="2x" class="white-text mr-md-5 mr-3"></mdb-icon>
          </a>
          <!-- Google +-->
          <a class="gplus-ic" href="https://www.youtube.com/channel/UCaDf8zcAFTU1uGVZmpMB__A" target="_blank">
            <mdb-icon fab fab icon="youtube" size="2x" class="white-text mr-md-5 mr-3"></mdb-icon>
          </a>
          <!--Linkedin -->
          <a class="li-ic" href="https://www.twitch.tv/expedigame" target="_blank">
            <mdb-icon fab icon="twitch" size="2x" class="white-text mr-md-5 mr-3"></mdb-icon>
          </a>
          <a class="li-ic" href="https://discord.gg/tB888jfCvh" target="_blank">
            <mdb-icon fab icon="discord" size="2x" class="white-text mr-md-5 mr-3"></mdb-icon>
          </a>
          <!--Instagram-->

        </div>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row-->

  </div>
  <!-- Footer Elements -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
    <a href="https://mdbootstrap.com/"> Expedigame.com</a>
  </div>
  <!-- Copyright -->

</footer>
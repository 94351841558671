<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>

<div class="d-flex justify-content-center">
    <div class=" w-75 ">
        <h2 class="white-text text-left">Podium {{mois_podiumHTML}} {{annee_podiumHTML}}</h2>
        <app-separateur></app-separateur>
        <div class="first white-text text-center">
            <p> <a routerLink="../profil/{{firstName}}">{{firstName}}</a></p>
            <p> {{firstPoint}} points</p>
        </div>
        <div class="second white-text text-center">
            <p> <a routerLink="../profil/{{secondName}}">{{secondName}}</a></p>
            <p> {{secondPoint}} points</p>
        </div>
        <div class="third white-text text-center">
            <p> <a routerLink="../profil/{{thirdName}}">{{thirdName}}</a></p>
            <p> {{thirdPoint}} points</p>
        </div>
        <div class="text-center test"> <img src="assets/img/podium.png" class="img-fluid" alt="Responsive image"></div>

    </div>
</div>
<app-separateur></app-separateur>

<app-separateur></app-separateur>
<app-separateur></app-separateur>

<div class="d-flex justify-content-center ">
    <div class=" w-75 ">
        <h2 class="white-text text-left">Classement {{moisHTML}} {{anneeHTML}}</h2>

        <div class="rounded bg-success text-center py-2">
            <div class="row py-2 text-center white-text bold">
                <div class="col-4 place">
                    Position
                </div>
                <div class="col-4 pseudo">
                    Pseudo
                </div>
                <div class="col-4 point">
                    Points
                </div>

            </div>

            <div class=" row py-2 rounded bg-danger text-center"
                *ngFor="let data_classement of classementList; let index_classement = index">
                <div class="col-4 " *ngIf="index_classement+1==1">
                    {{index_classement + 1}}er
                </div>
                <div class="col-4 " *ngIf="index_classement+1!=1">
                    {{index_classement + 1}}ème
                </div>
                <div class="col-4 text-center">
                    <a routerLink="../profil/{{data_classement.username}}"> {{data_classement.username}}</a>
                </div>
                <div class="col-4 ">
                    {{data_classement.point}}
                </div>

            </div>

        </div>
    </div>
</div>
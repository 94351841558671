import { animate } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { deflateRaw } from 'zlib';



@Component({
  selector: 'app-scratch-card',
  templateUrl: './scratch-card.component.html',
  styleUrls: ['./scratch-card.component.scss']
})
export class ScratchCardComponent implements OnInit {


  private numRandom: number;
  private item: number;


  public url = 'assets/img/chest-idle.gif';
  public CardAppear = false;





  constructor(private cookieService: CookieService, private _http: HttpClient) { }


  ngOnInit(): void {
    console.log(this.cookieService.get('chest'))
    if (this.cookieService.get('chest') == "0" || this.cookieService.get('chest') == "") {
      window.location.href = "http://localhost:4200/404";
    }
    else {



      this.numRandom = Math.floor(Math.random() * 1000);
      this.giftChest();



    }

  }

  giftChest() {
    var bodyAdd;
    const user_part = JSON.parse(this.cookieService.get("username"));
    if (this.numRandom <= 659) {
      this.item = 1;
      bodyAdd = '{"expedipoint" : "' + (Number(user_part.data.expedipoint) + 100) + '" }';
    }
    else if (this.numRandom <= 799) {
      this.item = 2;
      bodyAdd = '{"expedipoint" : "' + (Number(user_part.data.expedipoint) + 20000) + '" }';
    }
    else if (this.numRandom <= 993) {
      this.item = 3;
      bodyAdd = '{"diamant" : "' + (Number(user_part.data.diamant) + 5) + '" }';
    }
    else if (this.numRandom <= 998) {
      this.item = 4;
      bodyAdd = '{"ticket_sub" : "' + (Number(user_part.data.ticket_sub) + 1) + '" }';
    }
    else {
      this.item = 5;
      bodyAdd = '{"diamant" : "' + (Number(user_part.data.diamant) + 50) + '" }';
    }
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._http.put('http://127.0.0.1:8000/users', bodyAdd, httpOptions).subscribe(
      data => {
        this.cookieService.set('chest', "0");
        const user_decoded = JSON.stringify(data);
        this.cookieService.set('username', user_decoded);
      },
      err => {
        console.log(err['error']);
      }
    );
    console.log(this.item);
  }

  showItem(value) {
    if (value == this.item) {
      return true;
    }
    return false;
  }

  chestOpen() {
    this.url = 'assets/img/chest-open.gif';

    setTimeout(() => {                           // <<<---using ()=> syntax
      this.url = 'assets/img/chest-opened.png';
      this.CardAppear = true;
    }, 200);
  }








}

<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark danger-color-dark fixed-top" [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand><a class="navbar-brand" href="#"><img src="assets/img/ZERLYS.png" class="img-fluid"
        alt="Responsive image"></a></mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <ul class="navbar-nav mr-auto">
      <li class="nav-item ">
        <a class="nav-link waves-light" routerLink="" (click)="updateDailyQuest()" mdbWavesEffect>Accueil</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" routerLink="TV" (click)="updateDailyQuest()" routerLinkActive="active"
          mdbWavesEffect>TV</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" routerLink="classement" (click)="updateDailyQuest()" routerLinkActive="active"
          mdbWavesEffect>Classement</a>
      </li>

      <li class="nav-item">
        <a class="nav-link waves-light" routerLink="tournoi" (click)="updateDailyQuest()" routerLinkActive="active"
          mdbWavesEffect>Tournoi</a>
      </li>

      <li class="nav-item">
        <a class="nav-link waves-light" routerLink="boutique" (click)="updateDailyQuest()" routerLinkActive="active"
          mdbWavesEffect>Boutique</a>
      </li>


      <!-- Dropdown -->
      <!--   <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            Basic dropdown<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
          </div>
        </li> -->

    </ul>
    <!-- Links -->



    <div *ngIf="!hide">
      <ul class="navbar-nav ml-auto nav-flex-icons">



        <li class="nav-item">
          <!-- <a class="nav-link" href="https://api.twitch.tv/kraken/oauth2/authorize?response_type=code&client_id=xn3g12yzv87p0ohpnfsi6o1rh638dm&redirect_uri=http://localhost:4200/&scope=user_read"> -->
          <!--<a class="nav-link waves-light" href="https://id.twitch.tv/oauth2/authorize?client_id=xn3g12yzv87p0ohpnfsi6o1rh638dm&redirect_uri=http://localhost:4200/profil/&response_type=code&scope=user%3Aread%3Aemail&state=85e19155860186db1bca9f1b9232ee9f">-->
          <a class="nav-link waves-light" routerLink="login">
            <mdb-icon fab icon="twitch"></mdb-icon> Se connecter
          </a>
        </li>
        <!--   <li class="nav-item">
              <a class="nav-link">
                <mdb-icon fab icon="google-plus"></mdb-icon>
              </a>
            </li> -->

      </ul>

    </div>


    <div *ngIf="hide">


      <ul class="navbar-nav ml-auto nav-flex-icons">

        <li class="nav-item">

          <a class="nav-link waves-light">
            <mdb-icon fas icon="gem"></mdb-icon>
            {{this.cookieService.get("diamant")}}
          </a>
        </li>
        <li class="nav-item">

          <a class="nav-link waves-light">
            <mdb-icon fas icon="coins"></mdb-icon>
            {{this.cookieService.get("expedipoint")}}
          </a>
        </li>

        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" (click)="updateDailyQuest()" class="nav-link waves-light"
            mdbWavesEffect>
            <mdb-icon fas icon="book"></mdb-icon>
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right bg-danger white-text"
            style="width: 480px; margin-top: 20px;" role="menu">
            <div class="row align-items-center" style="width: 470px; margin-left: 20px">
              <div class="col-9">
                <div class="row">
                  <b> {{this.cookieService.get("title_quest_1")}} </b>
                </div>
                <div class="row">
                  <span class="text-m">{{this.cookieService.get("desc_quest_1")}}</span>
                </div>
              </div>
              <div class="col-3 align-items-center">
                <div *ngIf="questUpdate(1, 1)">
                  {{this.cookieService.get("value_quest_1")}} / {{this.cookieService.get("goal_quest_1")}}
                </div>
                <div *ngIf="questUpdate(2, 1)" style="margin-left: -9px;">

                  <a (click)="finishQuest(1)">
                    <mdb-icon class="yellow-text" fas icon="question-circle" size="2x"></mdb-icon>
                  </a>

                </div>
                <div *ngIf="questUpdate(3, 1)">
                  <mdb-icon class="green-text" fas icon="check-circle" size="2x"></mdb-icon>
                </div>

                <!-- <mdb-icon fas icon="exclamation-circle"></mdb-icon>-->
              </div>
            </div>
            <div class="row align-items-center" style="width: 470px; margin-left: 20px">
              <span class="text-xs">Prime : &nbsp;</span>
              <b class="font-weight-bold"> {{this.cookieService.get("xp_quest_1")}}</b> <span class="text-xs"> &nbsp;xp
                &nbsp;</span>
              &nbsp;
              <b class="font-weight-bold">{{this.cookieService.get("point_quest_1")}}</b>
              &nbsp;<mdb-icon fas icon="coins"></mdb-icon>
            </div>
            <app-separateur></app-separateur>
            <div class="row align-items-center" style="width: 470px; margin-left: 20px">
              <div class="col-9">
                <div class="row">
                  <b> {{this.cookieService.get("title_quest_2")}} </b>
                </div>
                <div class="row">
                  <span class="text-m">{{this.cookieService.get("desc_quest_2")}}</span>
                </div>
              </div>
              <div class="col-3 align-items-center">
                <div *ngIf="questUpdate(1, 2)">
                  {{this.cookieService.get("value_quest_2")}} / {{this.cookieService.get("goal_quest_2")}}
                </div>
                <div *ngIf="questUpdate(2, 2)" style="margin-left: -9px;">

                  <a (click)="finishQuest(2)">
                    <mdb-icon class="yellow-text" fas icon="question-circle" size="2x"></mdb-icon>
                  </a>

                </div>
                <div *ngIf="questUpdate(3, 2)">
                  <mdb-icon class="green-text" fas icon="check-circle" size="2x"></mdb-icon>
                </div>

                <!-- <mdb-icon fas icon="exclamation-circle"></mdb-icon>-->
              </div>
            </div>
            <div class="row align-items-center" style="width: 470px; margin-left: 20px">
              <span class="text-xs">Prime : &nbsp;</span>
              <b class="font-weight-bold"> {{this.cookieService.get("xp_quest_2")}}</b> <span class="text-xs"> &nbsp;xp
                &nbsp;</span>
              &nbsp;
              <b class="font-weight-bold">{{this.cookieService.get("point_quest_2")}}</b>
              &nbsp;<mdb-icon fas icon="coins"></mdb-icon>
            </div>
            <app-separateur></app-separateur>

            <div class="row align-items-center" style="width: 470px; margin-left: 20px">
              <div class="col-9">
                <div class="row">
                  <b> {{this.cookieService.get("title_quest_3")}} </b>
                </div>
                <div class="row">
                  <span class="text-m">{{this.cookieService.get("desc_quest_3")}}</span>
                </div>
              </div>
              <div class="col-3 align-items-center">
                <div *ngIf="questUpdate(1, 3)">
                  {{this.cookieService.get("value_quest_3")}} / {{this.cookieService.get("goal_quest_3")}}
                </div>
                <div *ngIf="questUpdate(2, 3)">

                  <a (click)="finishQuest(3)" style="margin-left: -9px;">
                    <mdb-icon class="yellow-text" fas icon="question-circle" size="2x"></mdb-icon>
                  </a>

                </div>
                <div *ngIf="questUpdate(3, 3)">
                  <mdb-icon class="green-text" fas icon="check-circle" size="2x"></mdb-icon>
                </div>

                <!-- <mdb-icon fas icon="exclamation-circle"></mdb-icon>-->
              </div>
            </div>
            <div class="row align-items-center" style="width: 470px; margin-left: 20px">
              <span class="text-xs">Prime : &nbsp;</span>
              <b class="font-weight-bold"> {{this.cookieService.get("xp_quest_3")}}</b> <span class="text-xs"> &nbsp;xp
                &nbsp;</span>
              &nbsp;
              <b class="font-weight-bold">{{this.cookieService.get("point_quest_3")}}</b>
              &nbsp;<mdb-icon fas icon="coins"></mdb-icon>
            </div>
            <app-separateur></app-separateur>
            <div class="divider dropdown-divider"></div>
            <div class="row " style="width: 470px; margin-left: 20px">
              <div class="col-6">
                <div class="row"> Prime quotidienne bonus</div>

              </div>
              <div class="col-6">
                <i *ngIf="questUpdate(3, 1);else quest_prime_1" class="green-text fas fa-check-circle"></i>
                <ng-template #quest_prime_1>
                  <mdb-icon fas icon="exclamation-circle"></mdb-icon>
                </ng-template>&nbsp;
                <i *ngIf="questUpdate(3, 2);else quest_prime_2" class="green-text fas fa-check-circle"></i>
                <ng-template #quest_prime_2>
                  <mdb-icon fas icon="exclamation-circle"></mdb-icon>
                </ng-template>&nbsp;
                <i *ngIf="questUpdate(3, 3);else quest_prime_3" class="green-text fas fa-check-circle"></i>
                <ng-template #quest_prime_3>
                  <mdb-icon fas icon="exclamation-circle"></mdb-icon>
                </ng-template>&nbsp;


                500 xp &nbsp; 5 &nbsp;<i class="far fa-gem"></i>
                <!-- <mdb-icon fas icon="exclamation-circle"></mdb-icon>-->
              </div>
            </div>

          </div>
        </li>



        <li class="nav-item">
          <!-- <a class="nav-link" href="https://api.twitch.tv/kraken/oauth2/authorize?response_type=code&client_id=xn3g12yzv87p0ohpnfsi6o1rh638dm&redirect_uri=http://localhost:4200/&scope=user_read"> -->
          <a class="nav-link waves-light" (click)="updateDailyQuest()" routerLink="profil">
            <mdb-icon fas icon="user"></mdb-icon>
            {{_item}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" (click)="logout()">
            <mdb-icon fas icon="sign-out-alt"></mdb-icon>

          </a>
        </li>
        <!--   <li class="nav-item">
                <a class="nav-link">
                  <mdb-icon fab icon="google-plus"></mdb-icon>
                </a>
              </li> -->

      </ul>
    </div>


  </links>
  <!-- Collapsible content -->
</mdb-navbar>




<!--/.Navbar-->
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { InscriptionComponent } from './inscription/inscription.component';
import { ProfilComponent } from './profil/profil.component';
import { TVComponent } from './tv/tv.component';
import { ScratchCardComponent } from './scratch-card/scratch-card.component';
import { BoutiqueComponent } from './boutique/boutique.component';
import { ErrorComponent } from './error/error.component';
import { ViewProfilComponent } from './view-profil/view-profil.component';
import { ClassementComponent } from './classement/classement.component';
import { OverlayComponent } from './overlay/overlay.component';
import { OverlayPariComponent } from './overlay-pari/overlay-pari.component';
import { TournoiComponent } from './tournoi/tournoi.component';
import { TournoiMatchComponent } from './tournoi-match/tournoi-match.component';
import { ZoeMerciComponent } from './zoe-merci/zoe-merci.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'profil', component: ProfilComponent },
  { path: 'inscription', component: InscriptionComponent },
  { path: 'TV', component: TVComponent },
  { path: 'scratch', component: ScratchCardComponent },
  { path: 'boutique', component: BoutiqueComponent },
  { path: '404', component: ErrorComponent },
  { path: 'profil/:id', component: ViewProfilComponent },
  { path: 'tournoi/:id', component: TournoiMatchComponent },
  { path: 'classement', component: ClassementComponent },
  { path: 'overlay', component: OverlayComponent },
  { path: 'overlay-pari', component: OverlayPariComponent },
  { path: 'tournoi', component: TournoiComponent },
  { path: 'zoe_merci', component: ZoeMerciComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zoe-merci',
  templateUrl: './zoe-merci.component.html',
  styleUrls: ['./zoe-merci.component.scss']
})
export class ZoeMerciComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

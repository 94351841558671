import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {

  
    public user: any;
 
    public isActive:boolean = true;
    
    constructor(public _userService: UserService) {
    }
   
  
   
    ngOnInit() {
      this.user = {
        username: '',
        password: '',
        email:'',
        dateAnniv:'',
      };
    }

    newUser() {
      this._userService.newUser({'username': this.user.username, 'email': this.user.email, 'password': this.user.password, 'dateAnniv': this.user.dateAnniv});
    }



}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _router: Router, private cookieService: CookieService, private http: HttpClient) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.substring(0, 28) == 'https://api.twitch.tv/helix/') {
      req = req.clone({
        setHeaders: {
          'Accept': 'application/vnd.twitchtv.v5+json',
          'Client-ID': 'xn3g12yzv87p0ohpnfsi6o1rh638dm',
          'Authorization': 'Bearer ' + this.cookieService.get('token_twitch')
        }
      });
    } else {
      let token = this.cookieService.get('token');

      if (token) {
        req = req.clone({
          setHeaders: {
            'Authorization': "Bearer " + token
          }
        });
      }

      if (req.method == "GET") {

        next.handle(req).subscribe(data => {




        }, async err => {

          if (err.status === 401) {
            if (err.error.code == "token_not_valid") {
              //Genrate params for token refreshing
              let params = {
                refresh: this.cookieService.get("tokenRefresh"),
              };
              await this.http.post('https://expedigame.com/api/auth/refresh', params).subscribe(
                (data: any) => {
                  //If reload successful update tokens


                  this.cookieService.set("token", data.access);

                  window.location.reload();
                  //Clone our fieled request ant try to resend it
                  req = req.clone({
                    setHeaders: {
                      'Authorization': "Bearer " + this.cookieService.get("token")
                    }
                  });
                  return next.handle(req);


                }
              );
            } else {
              //Logout from account or do some other stuff
            }
          }
        });
      }
    }
    return next.handle(req);

  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ConnexionComponent } from './connexion/connexion.component';
import { InscriptionComponent } from './inscription/inscription.component';
import { LoginComponent } from './login/login.component';
import { UserService } from './user.service';
import { SeparateurComponent } from './separateur/separateur.component';
import { ProfilComponent } from './profil/profil.component';
import { CookieService } from 'ngx-cookie-service';
import { TVComponent } from './tv/tv.component';
import { ScratchCardComponent } from './scratch-card/scratch-card.component';
import { BoutiqueComponent } from './boutique/boutique.component';
import { ErrorComponent } from './error/error.component';
import { ViewProfilComponent } from './view-profil/view-profil.component';
import { ClassementComponent } from './classement/classement.component';
import { OverlayComponent } from './overlay/overlay.component';
import { AuthInterceptor } from './auth-interceptor.interceptor';
import { OverlayPariComponent } from './overlay-pari/overlay-pari.component';
import { TournoiComponent } from './tournoi/tournoi.component';
import { TournoiMatchComponent } from './tournoi-match/tournoi-match.component';
import { ZoeMerciComponent } from './zoe-merci/zoe-merci.component';






@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    ConnexionComponent,
    InscriptionComponent,
    LoginComponent,
    SeparateurComponent,
    ProfilComponent,
    TVComponent,
    ScratchCardComponent,
    BoutiqueComponent,
    ErrorComponent,
    ViewProfilComponent,
    ClassementComponent,
    OverlayComponent,
    OverlayPariComponent,
    TournoiComponent,
    TournoiMatchComponent,
    ZoeMerciComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [UserService, CookieService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }


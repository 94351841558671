import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CheckboxRequiredValidator } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Console } from 'console';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-tournoi-match',
  templateUrl: './tournoi-match.component.html',
  styleUrls: ['./tournoi-match.component.scss']
})
export class TournoiMatchComponent implements OnInit {

  private id_tournoi

  public numMatch = 0;

  private httpOptions: any;

  public teamList = new Array();
  public matchList = new Array();

  public tab_name = new Array();

  public user = "";

  public roundMax = 0;

  public img_tournoi;
  public nom_tournoi;
  public date_tournoi;
  public num_inscrit;
  public team_valider = 0;
  public num_byteam;
  public nom_jeu;
  public etat_tournoi; //0 = afficher team  1 = afficher bracket   2 = terminé

  constructor(private route: ActivatedRoute, private _httpClient: HttpClient, private cookieService: CookieService) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
  }

  async ngOnInit(): Promise<void> {
    if (this.cookieService.get('username') != "") {
      const user_part = JSON.parse(this.cookieService.get('username'));
      this.user = user_part.data.username;
    }
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id_tournoi = params.get('id');
    });
    const data = await this._httpClient.get('http://127.0.0.1:8000/tournoi/' + this.id_tournoi + '/').toPromise();
    const tournoi_decoded = JSON.stringify(data);
    const tournoi_part = JSON.parse(tournoi_decoded);
    console.log(data)
    this.img_tournoi = tournoi_part.img_tournoi;
    this.nom_tournoi = tournoi_part.nom_tournoi;
    this.date_tournoi = tournoi_part.date_tournoi;
    this.num_inscrit = tournoi_part.num_inscrit;
    this.nom_jeu = tournoi_part.nom_jeu;
    this.etat_tournoi = tournoi_part.etat_tournoi;
    this.num_byteam = tournoi_part.num_byteam;


    switch (this.etat_tournoi) {
      case 0:
        this.avantTournoi();
        break;
      case 1:
        this.pendantTournoi();
        break;

      case 2:
        this.pendantTournoi();
        break;
    }


  }

  avantTournoi() {
    this._httpClient.get('http://127.0.0.1:8000/lienteam/' + this.id_tournoi).subscribe(data => {
      const data_decoded = JSON.stringify(data);
      const data_part = JSON.parse(data_decoded);
      console.log(data_part)
      this.team_valider = data_part.count;
      for (var i = 0; i < data_part.results.length; i++) {
        this._httpClient.get("http://127.0.0.1:8000/teams/" + data_part.results[i].id).subscribe(data => {
          const team_decoded = JSON.stringify(data);
          const team_part = JSON.parse(team_decoded);
          var tab_equipe = new Array();
          console.log(team_part)
          tab_equipe.push(team_part.results[0].nom_team)
          for (var j = 0; j < team_part.results.length; j++) {
            tab_equipe.push(team_part.results[j].nom_joueur)
            console.log(tab_equipe);
          }
          this.teamList.push(tab_equipe)
          console.log(this.teamList);
        })
      }
    });
  }

  pendantTournoi() {
    this._httpClient.get('http://127.0.0.1:8000/lienteam/' + this.id_tournoi).subscribe(data => {
      const data_decoded = JSON.stringify(data);
      const data_part = JSON.parse(data_decoded);
      this.tab_name = data_part.results
      console.log(this.tab_name)
    })
    this._httpClient.get('http://127.0.0.1:8000/matchmax/' + this.id_tournoi).subscribe(async data => {
      const data_decoded = JSON.stringify(data)
      const data_part = JSON.parse(data_decoded)
      this.roundMax = data_part.round
      for (var i = this.roundMax; i > 0; i--) {
        const data = await this._httpClient.get('http://127.0.0.1:8000/getmatch/' + this.id_tournoi + "/round/" + i).toPromise()
        const match_decoded = JSON.stringify(data);
        const match_part = JSON.parse(match_decoded);
        var tabMatch = new Array();
        tabMatch.push(match_part.results);
        this.matchList[i] = tabMatch
        console.log(this.matchList)


      }
    })

  }

  apresTournoi() {

  }


  async beginTournoi() {
    var checkRound = 0
    var round = 0;
    const bodyTournoi = '{"etat_tournoi" : "' + 1 + '", "date_tournoi":"' + this.date_tournoi + '"}';
    this._httpClient.put("http://127.0.0.1:8000/tournoi/" + this.id_tournoi + "/", bodyTournoi, this.httpOptions).toPromise();
    while (checkRound < this.team_valider) {
      round++;
      checkRound = Math.pow(2, round);
    }
    round = round - 1;
    var tab_team = new Array();
    const data = await this._httpClient.get('http://127.0.0.1:8000/lienteam/' + this.id_tournoi).toPromise();
    const data_decoded = JSON.stringify(data);
    const data_part = JSON.parse(data_decoded);

    for (var i = 0; i < data_part.results.length; i++) {
      tab_team.push(data_part.results[i].id);
    }

    tab_team = this.randomize(tab_team);
    console.log(tab_team)


    //////////remplir tous les matchs de chaque round
    if (checkRound == this.team_valider) {
      console.log("test erreur")
      var nume_team = 1;
      for (var i = round; i >= 0; i--) {
        for (var j = 1; j <= Math.pow(2, i); j++) {
          if (i == round) {
            const team1 = Number((nume_team * 2) - 2);
            const team2 = Number((nume_team * 2) - 1);
            bodyMatch = '{"id_team1" : "' + tab_team[team1] + '", "id_team2":"' + tab_team[team2] + '", "round":"' + Number(i + 1) + '", "pos":"' + j + '", "id_tournoi":"' + this.id_tournoi + '" }';
          }
          else {
            bodyMatch = '{"id_team1" : "' + 0 + '", "id_team2":"' + 0 + '", "round":"' + Number(i + 1) + '", "pos":"' + j + '", "id_tournoi":"' + this.id_tournoi + '" }';
          }
          this._httpClient.post('http://127.0.0.1:8000/creatematch/', bodyMatch, this.httpOptions).toPromise();
          nume_team++
        }
      }
    }
    ////////////// nbre de match dans le round max ()
    else {
      console.log("test bon")
      var matchMore = this.team_valider - Math.pow(2, round);
      var nume_team = 1;
      for (var i = 1; i <= matchMore; i++) {
        const team1 = Number((nume_team * 2) - 2);
        const team2 = Number((nume_team * 2) - 1);
        const bodyMatch = '{"id_team1" : "' + tab_team[team1] + '", "id_team2":"' + tab_team[team2] + '", "round":"' + Number(round + 1) + '", "pos":"' + i + '", "id_tournoi":"' + this.id_tournoi + '" }';
        this._httpClient.post('http://127.0.0.1:8000/creatematch/', bodyMatch, this.httpOptions).toPromise();
        nume_team++;
      }
      round = round - 1;

      var semiMatch = false;
      var nbSecondRound = Math.ceil(matchMore / 2)
      if (Number.isInteger(Number(matchMore / 2)) == false) {
        semiMatch = true;
        console.log("not integer")
      }
      //// créer les match du round en dessous qui sont déjà rempli ////
      for (var j = 1; j <= Math.pow(2, round); j++) {
        console.log("test match en plus round - 1")
        var bodyMatch;
        if (j == nbSecondRound && semiMatch) {
          const team2 = Number((nume_team * 2) - 1);
          bodyMatch = '{"id_team1" : "' + 0 + '", "id_team2":"' + tab_team[team2] + '", "round":"' + Number(round + 1) + '", "pos":"' + j + '", "id_tournoi":"' + this.id_tournoi + '" }';
          nume_team++
        }
        else if (j > nbSecondRound) {
          var team1;
          var team2;
          if (semiMatch) {
            team1 = Number((nume_team * 2) - 3);
            team2 = Number((nume_team * 2) - 2);
          }
          else {
            team1 = Number((nume_team * 2) - 2);
            team2 = Number((nume_team * 2) - 1);
          }

          bodyMatch = '{"id_team1" : "' + tab_team[team1] + '", "id_team2":"' + tab_team[team2] + '", "round":"' + Number(round + 1) + '", "pos":"' + j + '", "id_tournoi":"' + this.id_tournoi + '" }';
          nume_team++
        }
        else {
          bodyMatch = '{"id_team1" : "' + 0 + '", "id_team2":"' + 0 + '", "round":"' + Number(round + 1) + '", "pos":"' + j + '", "id_tournoi":"' + this.id_tournoi + '" }';
        }
        this._httpClient.post('http://127.0.0.1:8000/creatematch/', bodyMatch, this.httpOptions).toPromise();
      }
      round = round - 1;
      //// créer tous les match !!! ///
      for (var i = round; i >= 0; i--) {

        for (var j = 1; j <= Math.pow(2, i); j++) {
          const bodyMatch = '{"id_team1" : "' + 0 + '", "id_team2":"' + 0 + '", "round":"' + Number(i + 1) + '", "pos":"' + j + '", "id_tournoi":"' + this.id_tournoi + '" }';
          this._httpClient.post('http://127.0.0.1:8000/creatematch/', bodyMatch, this.httpOptions).toPromise();

        }
      }
    }



    window.location.reload();

  }

  randomize(tab) {
    var i, j, tmp;
    for (i = tab.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      tmp = tab[i];
      tab[i] = tab[j];
      tab[j] = tmp;
    }
    return tab;
  }

  getNameTeam(id_team) {
    var name_team = ""
    for (var i = 0; i < this.tab_name.length; i++) {
      if (this.tab_name[i].id == id_team) {
        name_team = this.tab_name[i].name_team
        return name_team
      }
    }
    return name_team
  }


  winner(id_winner, pos, round) {


    if (round == 1) {
      const bodyTournoi = '{"etat_tournoi" : "' + 2 + '", "date_tournoi":"' + this.date_tournoi + '"}';
      this._httpClient.put("http://127.0.0.1:8000/tournoi/" + this.id_tournoi + "/", bodyTournoi, this.httpOptions).toPromise();
    }


    //put winner
    const bodyWinner = '{"winner" : "' + id_winner + '"}';
    this._httpClient.put("http://127.0.0.1:8000/match/" + this.id_tournoi + "/round/" + round + "/pos/" + pos, bodyWinner, this.httpOptions).toPromise();
    //put joueur match en dessous
    var pos_match = Math.ceil(Number(pos / 2));
    var round_match = round - 1;
    var bodyTeam = ""
    if (Number.isInteger(Number(pos / 2)) == true) {
      bodyTeam = '{"id_team2" : "' + id_winner + '"}';
    }
    else {
      bodyTeam = '{"id_team1" : "' + id_winner + '"}';
    }

    this._httpClient.put("http://127.0.0.1:8000/match/" + this.id_tournoi + "/round/" + round_match + "/pos/" + pos_match, bodyTeam, this.httpOptions).toPromise();
    window.location.reload();
  }




}

<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>


<div class="container white-text">
    <div class="row justify-content-center">
        <img src="assets/img/{{img_tournoi}}" class="img-fluid rounded img-game" alt="Responsive image">
    </div>
    <div class="row justify-content-center">
        {{nom_tournoi}} | {{date_tournoi}}
    </div>
    <div class="row justify-content-center">
        {{nom_jeu}}<span *ngIf="etat_tournoi == 0"> &nbsp;| {{team_valider}}/{{num_inscrit}} </span>
    </div>
</div>

<div *ngIf="etat_tournoi == 0">
    <div *ngFor="let data_team of teamList;let i=index">
        <div class="d-flex justify-content-center">
            <div class="justify-content-center w-75">
                <div class="row py-2  white-text ">
                    <h2>
                        <mdb-icon fas icon="users"></mdb-icon> &nbsp;{{data_team[0]}}
                    </h2>
                </div>
                <div class="row   bg-success rounded white-text">

                    <div *ngFor="let f of [].constructor(num_byteam); let r = index" class="col-md-3 text-center py-3">
                        <mdb-icon fas icon="user-alt"></mdb-icon>
                        &nbsp; {{data_team[r+1]}}
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="user == 'admin'">
        <div class="d-flex justify-content-center">

            <button mdbBtn (click)="beginTournoi()" color="secondary" rounded="true" class="my-4 waves-effect w-25 "
                mdbWavesEffect type="submit">Générer tournoi</button>
        </div>
    </div>
</div>

<div *ngIf="etat_tournoi >= 1">
    <div class="d-flex justify-content-center">
        <div class=" w-75 ">
            <div *ngIf="user=='admin'">
                <div *ngIf="roundMax >2">
                    <h2 class="white-text text-left">Quart de final</h2>
                    <div *ngFor="let data_team of matchList[3][0];let i=index">



                        <div class="row rounded bg-success text-center white-text">
                            <div class="col-md-2 text-match">
                                Match {{i+1}}
                            </div>
                            <div class="col-md-10 rounded  bg-match">
                                <div class="row">
                                    <div class="col-lg-5 text-center ">
                                        <h3 *ngIf="data_team.id_team1 == 0" class=" text-match">En attente des
                                            résultats...
                                        </h3>
                                        <h3 *ngIf="data_team.id_team1 > 0" class=" text-match">
                                            <span *ngIf="data_team.winner == 0"> <a
                                                    (click)="winner(data_team.id_team1, data_team.pos, data_team.round)">{{getNameTeam(data_team.id_team1)}}</a></span>
                                            <span class="bg-winner py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team1">{{getNameTeam(data_team.id_team1)}}</span>
                                            <span class="bg-loser py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team2 && data_team.id_team2 != 0">{{getNameTeam(data_team.id_team1)}}</span>
                                        </h3>
                                    </div>
                                    <div class="col-lg-2 text-center">
                                        <img src="assets/img/vs.png" class="img-fluid" alt="Responsive image">
                                    </div>
                                    <div class="col-lg-5 text-match text-center">
                                        <h3 *ngIf="data_team.id_team2 == 0" class=" text-match">En attente des
                                            résultats...
                                        </h3>
                                        <h3 *ngIf="data_team.id_team2 > 0" class=" text-match">
                                            <span *ngIf="data_team.winner == 0"> <a
                                                    (click)="winner(data_team.id_team2, data_team.pos, data_team.round)">{{getNameTeam(data_team.id_team2)}}</a></span>
                                            <span class="bg-winner py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team2">{{getNameTeam(data_team.id_team2)}}</span>
                                            <span class="bg-loser py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team1 && data_team.id_team1 != 0">{{getNameTeam(data_team.id_team2)}}</span>
                                        </h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="roundMax > 1">
                    <h2 class="white-text text-left">Demi final</h2>
                    <div *ngFor="let data_team of matchList[2][0];let i=index">



                        <div class="row rounded bg-success text-center white-text">
                            <div class="col-md-2 text-match">
                                Match {{i+1}}
                            </div>
                            <div class="col-md-10 rounded  bg-match">
                                <div class="row">
                                    <div class="col-lg-5 text-center ">
                                        <h3 *ngIf="data_team.id_team1 == 0" class=" text-match">En attente des
                                            résultats...
                                        </h3>
                                        <h3 *ngIf="data_team.id_team1 > 0" class=" text-match">
                                            <span *ngIf="data_team.winner == 0"> <a
                                                    (click)="winner(data_team.id_team1, data_team.pos, data_team.round)">{{getNameTeam(data_team.id_team1)}}</a></span>
                                            <span class="bg-winner py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team1">{{getNameTeam(data_team.id_team1)}}</span>
                                            <span class="bg-loser py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team2 && data_team.id_team2 != 0">{{getNameTeam(data_team.id_team1)}}</span>
                                        </h3>
                                    </div>
                                    <div class="col-lg-2 text-center">
                                        <img src="assets/img/vs.png" class="img-fluid" alt="Responsive image">
                                    </div>
                                    <div class="col-lg-5 text-match text-center">
                                        <h3 *ngIf="data_team.id_team2 == 0" class=" text-match">En attente des
                                            résultats...
                                        </h3>
                                        <h3 *ngIf="data_team.id_team2 > 0" class=" text-match">
                                            <span *ngIf="data_team.winner == 0"> <a
                                                    (click)="winner(data_team.id_team2, data_team.pos, data_team.round)">{{getNameTeam(data_team.id_team2)}}</a></span>
                                            <span class="bg-winner py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team2">{{getNameTeam(data_team.id_team2)}}</span>
                                            <span class="bg-loser py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team1 && data_team.id_team1 != 0">{{getNameTeam(data_team.id_team2)}}</span>
                                        </h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="roundMax > 0">
                    <h2 class="white-text text-left">Final</h2>
                    <div *ngFor="let data_team of matchList[1][0];let i=index">



                        <div class="row rounded bg-success text-center white-text">
                            <div class="col-md-2 text-match">
                                FINALE
                            </div>
                            <div class="col-md-10 rounded  bg-match">
                                <div class="row">
                                    <div class="col-lg-5 text-center ">
                                        <h3 *ngIf="data_team.id_team1 == 0" class=" text-match">En attente des
                                            résultats...
                                        </h3>
                                        <h3 *ngIf="data_team.id_team1 > 0" class=" text-match">
                                            <span *ngIf="data_team.winner == 0"> <a
                                                    (click)="winner(data_team.id_team1, data_team.pos, data_team.round)">{{getNameTeam(data_team.id_team1)}}</a></span>
                                            <span class="bg-winner py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team1">{{getNameTeam(data_team.id_team1)}}</span>
                                            <span class="bg-loser py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team2 && data_team.id_team2 != 0">{{getNameTeam(data_team.id_team1)}}</span>
                                        </h3>
                                    </div>
                                    <div class="col-lg-2 text-center">
                                        <img src="assets/img/vs.png" class="img-fluid" alt="Responsive image">
                                    </div>
                                    <div class="col-lg-5 text-match text-center">
                                        <h3 *ngIf="data_team.id_team2 == 0" class=" text-match">En attente des
                                            résultats...
                                        </h3>
                                        <h3 *ngIf="data_team.id_team2 > 0" class=" text-match">
                                            <span *ngIf="data_team.winner == 0"> <a
                                                    (click)="winner(data_team.id_team2, data_team.pos, data_team.round)">{{getNameTeam(data_team.id_team2)}}</a></span>
                                            <span class="bg-winner py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team2">{{getNameTeam(data_team.id_team2)}}</span>
                                            <span class="bg-loser py-2 px-2 rounded"
                                                *ngIf="data_team.winner == data_team.id_team1 && data_team.id_team1 != 0">{{getNameTeam(data_team.id_team2)}}</span>
                                        </h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div *ngIf="user!='admin'">
                <h2 class="white-text text-left">Quart de final</h2>
                <div *ngFor="let data_team of matchList[3][0];let i=index">



                    <div class="row rounded bg-success text-center white-text">
                        <div class="col-md-2 text-match">
                            Match {{i+1}}
                        </div>
                        <div class="col-md-10 rounded  bg-match">
                            <div class="row">
                                <div class="col-lg-5 text-center ">
                                    <h3 *ngIf="data_team.id_team1 == 0" class=" text-match">En attente des résultats...
                                    </h3>
                                    <h3 *ngIf="data_team.id_team1 > 0" class=" text-match">
                                        <span *ngIf="data_team.winner == 0"> {{getNameTeam(data_team.id_team1)}}</span>
                                        <span class="bg-winner py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team1">{{getNameTeam(data_team.id_team1)}}</span>
                                        <span class="bg-loser py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team2 && data_team.id_team2 != 0">{{getNameTeam(data_team.id_team1)}}</span>
                                    </h3>
                                </div>
                                <div class="col-lg-2 text-center">
                                    <img src="assets/img/vs.png" class="img-fluid" alt="Responsive image">
                                </div>
                                <div class="col-lg-5 text-match text-center">
                                    <h3 *ngIf="data_team.id_team2 == 0" class=" text-match">En attente des résultats...
                                    </h3>
                                    <h3 *ngIf="data_team.id_team2 > 0" class=" text-match">
                                        <span *ngIf="data_team.winner == 0"> {{getNameTeam(data_team.id_team2)}}</span>
                                        <span class="bg-winner py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team2">{{getNameTeam(data_team.id_team2)}}</span>
                                        <span class="bg-loser py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team1 && data_team.id_team1 != 0">{{getNameTeam(data_team.id_team2)}}</span>
                                    </h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <h2 class="white-text text-left">Demi final</h2>
                <div *ngFor="let data_team of matchList[2][0];let i=index">



                    <div class="row rounded bg-success text-center white-text">
                        <div class="col-md-2 text-match">
                            Match {{i+1}}
                        </div>
                        <div class="col-md-10 rounded  bg-match">
                            <div class="row">
                                <div class="col-lg-5 text-center ">
                                    <h3 *ngIf="data_team.id_team1 == 0" class=" text-match">En attente des résultats...
                                    </h3>
                                    <h3 *ngIf="data_team.id_team1 > 0" class=" text-match">
                                        <span *ngIf="data_team.winner == 0"> {{getNameTeam(data_team.id_team1)}}</span>
                                        <span class="bg-winner py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team1">{{getNameTeam(data_team.id_team1)}}</span>
                                        <span class="bg-loser py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team2 && data_team.id_team2 != 0">{{getNameTeam(data_team.id_team1)}}</span>
                                    </h3>
                                </div>
                                <div class="col-lg-2 text-center">
                                    <img src="assets/img/vs.png" class="img-fluid" alt="Responsive image">
                                </div>
                                <div class="col-lg-5 text-match text-center">
                                    <h3 *ngIf="data_team.id_team2 == 0" class=" text-match">En attente des résultats...
                                    </h3>
                                    <h3 *ngIf="data_team.id_team2 > 0" class=" text-match">
                                        <span *ngIf="data_team.winner == 0"> {{getNameTeam(data_team.id_team2)}}</span>
                                        <span class="bg-winner py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team2">{{getNameTeam(data_team.id_team2)}}</span>
                                        <span class="bg-loser py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team1 && data_team.id_team1 != 0">{{getNameTeam(data_team.id_team2)}}</span>
                                    </h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <h2 class="white-text text-left">Final</h2>
                <div *ngFor="let data_team of matchList[1][0];let i=index">



                    <div class="row rounded bg-success text-center white-text">
                        <div class="col-md-2 text-match">
                            FINALE
                        </div>
                        <div class="col-md-10 rounded  bg-match">
                            <div class="row">
                                <div class="col-lg-5 text-center ">
                                    <h3 *ngIf="data_team.id_team1 == 0" class=" text-match">En attente des résultats...
                                    </h3>
                                    <h3 *ngIf="data_team.id_team1 > 0" class=" text-match">
                                        <span *ngIf="data_team.winner == 0"> {{getNameTeam(data_team.id_team1)}}</span>
                                        <span class="bg-winner py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team1">{{getNameTeam(data_team.id_team1)}}</span>
                                        <span class="bg-loser py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team2 && data_team.id_team2 != 0">{{getNameTeam(data_team.id_team1)}}</span>
                                    </h3>
                                </div>
                                <div class="col-lg-2 text-center">
                                    <img src="assets/img/vs.png" class="img-fluid" alt="Responsive image">
                                </div>
                                <div class="col-lg-5 text-match text-center">
                                    <h3 *ngIf="data_team.id_team2 == 0" class=" text-match">En attente des résultats...
                                    </h3>
                                    <h3 *ngIf="data_team.id_team2 > 0" class=" text-match">
                                        <span *ngIf="data_team.winner == 0"> {{getNameTeam(data_team.id_team2)}}</span>
                                        <span class="bg-winner py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team2">{{getNameTeam(data_team.id_team2)}}</span>
                                        <span class="bg-loser py-2 px-2 rounded"
                                            *ngIf="data_team.winner == data_team.id_team1 && data_team.id_team1 != 0">{{getNameTeam(data_team.id_team2)}}</span>
                                    </h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
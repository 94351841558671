<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>

<div class="d-flex justify-content-center">
    <div class=" w-75 ">
        <div class="row">
            <div class="col-md-12">
                <h2 class="white-text text-center">Félicitation pour avoir résolu les énigmes !!! </h2>

            </div>

        </div>
    </div>
</div>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
    <div class=" w-75 ">
        <div class="row">
            <div class="col-md-12">
                <h2 class="white-text text-center">Pourquoi tout ça ? </h2>
                <div class="rounded py-2 bg-success text-center white-text">


                    <p> Ces dernières semaines, je me sentais coupable d'avoir gaché quelque chose entre nous et ce
                        projet m'a permis de penser à toi positivement au lieu de me morfondre sur ma culpabilité.
                    </p>
                    <p>Je me rend compte maintenant que je suis important pour toi car avec tout le mal que j'ai
                        pu te
                        faire, tu es encore là, à me répondre,
                        à accepter de me voir, alors que tu as plusieurs raison pour me ghoster... </p>
                    <p> Ces cadeaux ne sont pas là pour effacer mes erreurs du passé mais de faire un pas
                        vers l'avant pour qu'on puisse créer
                        autre chose.</p>

                    <p>La vidéo qui suit est un message sincère de ce que je pense de toi et de la fille extraordinaire
                        que tu es... J'espère que tu pourras la regarder ou la réécouter lorsque tu en auras le plus
                        besoin.

                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
    <div class=" w-75 ">
        <div class="row">
            <div class="col-md-12">
                <h2 class="white-text text-center">Merci Zoé </h2>
                <div class="rounded py-2 bg-success text-center">
                    <div class="video-responsive">
                        <iframe width="1280" height="720"
                            src="https://www.youtube.com/embed/hyYfuFhLU-s?si=NGoMZC1jlFZT6t6K"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
    <div class=" w-75 ">
        <div class="row">
            <div class="col-md-12">
                <h2 class="white-text text-center">Est-ce que ça te dis ?! </h2>
                <div class="rounded py-2 bg-success text-center white-text">
                    <p>J'ai récupéré dans ma galerie, quelques photos de nous deux qui me donne le smile.</p>
                    <p>Qu'on se perde de vue ou qu'on devienne les meilleur(e)s ami(e)s du monde, ces photos seront
                        toujours rattaché à un moment de bonheur et j'en suis très reconnaissant d'avoir pu le vivre.
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>


<div class="d-flex justify-content-center">
    <div class=" w-75 ">
        <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/Toutacommencer.jpg" alt="First slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/Sebalade.jpg" alt="Second slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/Calin.jpg" alt="Third slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/Latronchedutype.jpg" alt="Third slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/VicieuxLeRegard.jpg" alt="Third slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/Lol.jpg" alt="Third slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/On est heureux.jpg" alt="Third slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/ToiEtMoi2.jpg" alt="Third slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/ToiEtMoi.jpg" alt="Third slide">
            </mdb-carousel-item>
            <mdb-carousel-item>
                <img class="d-block w-100" src="assets/img/stampida.jpg" alt="Third slide">
            </mdb-carousel-item>
        </mdb-carousel>
    </div>
</div>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-overlay-pari',
  templateUrl: './overlay-pari.component.html',
  styleUrls: ['./overlay-pari.component.scss']
})
export class OverlayPariComponent implements OnInit {

  private largeur;
  private hauteur;

  public posx;
  public posy;

  public progress;
  public palier;

  public data_list = [];

  private tableauUrl = [];
  private tableauID = [];
  private incremente = 0;
  private longTab = 0;

  public list_winner = [];

  public result_pari = 0;

  private listener;

  private pari = false;
  public resultat = false;


  private elementDivImage;


  constructor(private global: AppComponent, private service: AppServiceService, private changeDetector: ChangeDetectorRef, private httpclient: HttpClient) { }

  ngOnDestroy(): void {
    clearInterval(this.listener);
  }

  ngOnInit(): void {

    for (var i = 0; i < 100; i++) {
      window.clearInterval(i);
    }
    this.lancementParis();
    this.listener = setInterval(() => {
      this.lancementParis();
    }, 10000);
    this.global.nav = false;
    this.global.footer = false;

    this.service.returnAsObservable().subscribe(async data => {
      const data_decoded = JSON.stringify(data);
      const data_part = JSON.parse(data_decoded);
      console.log(data_part)
      if (data_part.url != undefined) {
        for (var i = 0; i < data_part.url.length; i++) {
          this.tableauUrl.push(data_part.url[i])
          this.tableauID.push(this.longTab);
          this.longTab++;

          setTimeout(() => {
            this.affiche(this.tableauUrl[this.tableauID[this.incremente]]);
            this.incremente++;

            this.changeDetector.detectChanges();
          }, data_part.time[i]);


        }
        if (data_part.url.length == 0) {
          if (this.tableauUrl.length <= this.incremente) {
            this.incremente = 0;
            this.longTab = 0;
            this.tableauID = [];
            this.tableauUrl = [];
          }

        }
      }



    });

    this.service.GetExchangeImage();
    // this.remove();
  }

  remove() {
    this.data_list.shift();
    this.changeDetector.detectChanges();
  }
  public affiche(url) {
    console.log(url)
    // recuperation de la resolution de l'ecran chez l'utilisateur
    this.largeur = screen.width - 150;
    this.hauteur = screen.height - 150;
    // positionnement aleatoire

    this.posx = Math.round(Math.random() * this.largeur)

    this.posy = Math.round(Math.random() * this.hauteur)
    var body = { 'x': this.posx, 'y': this.posy, 'img': url }

    this.data_list.push(body)
    // console.log(this.data_list)

    setTimeout(() => {
      this.remove();
    }, 3000);

  }

  lancementParis() {
    this.service.getPari().subscribe(async (response) => {
      console.log('Response from API is ', response);
      const data_decoded = JSON.stringify(response);
      const data_part = JSON.parse(data_decoded);


      if (data_part.PariEnCours && this.pari == false) {
        this.pari = true;
        switch (data_part.type_pari) {
          case 1:
            this.result_pari = 0;
            this.list_winner = [];
            this.resultat = false;
            break;
        }

      }

      if (data_part.resultat > 0 && this.resultat == false) {

        this.result_pari = data_part.resultat;
        for (var i = 0; i < data_part.tableau_winner.length; i++) {
          this.resultat = true;
          var tab = []
          var reponse = await this.httpclient.get("https://expedigame.com/api/userView/" + data_part.tableau_winner[i]).toPromise();
          const winner_decoded = JSON.stringify(reponse);
          const winner_part = JSON.parse(winner_decoded);
          tab.push(winner_part.username);
          tab.push(winner_part.img_profil);
          this.list_winner.push(tab);
        }
        console.log(this.list_winner)
      }

      if (data_part.PariEnCours == false) {
        this.pari = false;
      }

    });
  }





}

<div id="elemImage" *ngFor="let data of data_list"
    style="position:absolute; left:{{data.x}}px; top:{{data.y}}px; width:150px; height:150px; z-index:1">
    <img src="{{data.img}}" name="image" width="160" height="120" id="image">
</div>
<div id="giveAway" class="w-25">
    <div class="row">
        <div class="progress  ">
            <div class="progress-bar rounded purple lighten-2 px-1" role="progressbar" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100" style="width: {{progress}}%">

            </div>
        </div>
        <mdb-icon *ngIf="palier_level < 1" class="text-white iconProgress" fas icon="gift" size="2x"></mdb-icon>
        <mdb-icon *ngIf="palier_level >= 1" class="text-white iconValider" fas icon="check" size="2x"></mdb-icon>
        <div class="progress  ">
            <div class="progress-bar rounded purple lighten-2 px-1" role="progressbar" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100" style="width: {{progress_2}}%">

            </div>
        </div>
        <mdb-icon *ngIf="palier_level < 2" class="text-white iconProgress" fas icon="gift" size="2x"></mdb-icon>
        <mdb-icon *ngIf="palier_level >= 2 " class="text-white iconValider" fas icon="check" size="2x"></mdb-icon>
        <div class="progress  ">
            <div class="progress-bar rounded purple lighten-2 px-1" role="progressbar" aria-valuenow="40"
                aria-valuemin="0" aria-valuemax="100" style="width: {{progress_3}}%">

            </div>
        </div>
        <mdb-icon *ngIf="palier_level < 3" class="text-white iconProgress" fas icon="gift" size="2x"></mdb-icon>
        <mdb-icon *ngIf="palier_level>=3" class="text-white iconValider" fas icon="check" size="2x"></mdb-icon>
    </div>
</div>

<div id="lancerPari" class="marquee-rtl">
    <!-- le contenu défilant -->
    <div>Pari en cours</div>
</div>

<!--<div class="marquee-rtl-left">
     le contenu défilant 
    <div>
        Zerlys <br><img src="assets/img/amongus-walk.gif" name="image" width="160" height="120" id="image">
    </div>

</div>-->
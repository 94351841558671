import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { data } from 'jquery';
import { AppServiceService } from '../app-service.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {
  private largeur;
  private hauteur;

  public posx;
  public posy;

  public progress;
  public progress_2;
  public progress_3;

  public palier_level = 0;


  public data_list = [];

  private tableauUrl = [];
  private tableauID = [];
  private incremente = 0;
  private longTab = 0;

  public list_winner = [];

  public result_pari = 0;

  private d1;

  private d2;

  private listener;

  private pari = false;
  public resultat = false;


  private elementDivImage;


  constructor(private global: AppComponent, private service: AppServiceService, private changeDetector: ChangeDetectorRef, private httpclient: HttpClient) { }

  ngOnDestroy(): void {
    clearInterval(this.listener);
  }

  ngOnInit(): void {
    this.d1 = document.getElementById("lancerPari");
    this.d1.style.display = "none";

    this.d2 = document.getElementById("giveAway");
    this.d2.style.display = "none";
    for (var i = 0; i < 100; i++) {
      window.clearInterval(i);
    }
    this.giveAway();
    this.listener = setInterval(() => {
      this.giveAway();
      this.lancementParis();
    }, 10000);
    this.global.nav = false;
    this.global.footer = false;

    this.service.returnAsObservable().subscribe(async data => {
      const data_decoded = JSON.stringify(data);
      const data_part = JSON.parse(data_decoded);
      console.log(data_part)
      if (data_part.url != undefined) {
        for (var i = 0; i < data_part.url.length; i++) {
          this.tableauUrl.push(data_part.url[i])
          this.tableauID.push(this.longTab);
          this.longTab++;

          setTimeout(() => {
            this.affiche(this.tableauUrl[this.tableauID[this.incremente]]);
            this.incremente++;

            this.changeDetector.detectChanges();
          }, data_part.time[i]);


        }
        if (data_part.url.length == 0) {
          if (this.tableauUrl.length <= this.incremente) {
            this.incremente = 0;
            this.longTab = 0;
            this.tableauID = [];
            this.tableauUrl = [];
          }

        }
      }



    });

    this.service.GetExchangeImage();
    // this.remove();
  }

  remove() {
    this.data_list.shift();
    this.changeDetector.detectChanges();
  }
  public affiche(url) {
    console.log(url)
    // recuperation de la resolution de l'ecran chez l'utilisateur
    this.largeur = screen.width - 150;
    this.hauteur = screen.height - 150;
    // positionnement aleatoire

    this.posx = Math.round(Math.random() * this.largeur)

    this.posy = Math.round(Math.random() * this.hauteur)
    var body = { 'x': this.posx, 'y': this.posy, 'img': url }

    this.data_list.push(body)
    // console.log(this.data_list)

    setTimeout(() => {
      this.remove();
    }, 3000);

  }

  lancementParis() {
    this.service.getPari().subscribe((response) => {
      console.log('Response from API is ', response);
      const data_decoded = JSON.stringify(response);
      const data_part = JSON.parse(data_decoded);


      if (data_part.PariEnCours && this.pari == false) {
        this.pari = true;
        switch (data_part.type_pari) {
          case 1:
            this.result_pari = 0;
            this.d1.style.display = "block";
            this.list_winner = [];
            this.resultat = false;
            setTimeout(() => {
              this.d1.style.display = "none";
            }, 10000);


            break;
        }

      }

      if (data_part.resultat > 0 && this.resultat == false) {

        this.result_pari = data_part.resultat;
        for (var i = 0; i < data_part.tableau_winner.length; i++) {
          this.resultat = true;
          var tab = []
          this.httpclient.get("https://expedigame.com/api/userView/" + data_part.tableau_winner[i]).subscribe(data => {
            const winner_decoded = JSON.stringify(data);
            const winner_part = JSON.parse(winner_decoded);
            tab.push(winner_part.username);
            tab.push(winner_part.img_profil);
          });
          this.list_winner.push(tab);
        }
        console.log(this.list_winner)
      }

      if (data_part.PariEnCours == false) {
        this.pari = false;
      }

    });
  }

  giveAway() {
    var dateToday = new Date();
    var dateGet = "";

    if (dateToday.getHours() < 12) {
      dateGet = formatDate(new Date(Date.now() - 1), 'yyyy-MM-dd', 'en_US');
    } else {
      dateGet = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    }
    this.httpclient.get('https://expedigame.com/api/giveaway/' + dateGet + '/').subscribe(data => {
      console.log(data)
      this.d2.style.display = "block";
      const data_decoded = JSON.stringify(data);
      const data_part = JSON.parse(data_decoded);
      var value = data_part.valeur_actuelle;
      var palier = 0;
      var palier_2 = 0;
      var palier_3 = 0;
      switch (data_part.rang) {
        case 0:
          this.palier_level = 0;
          palier = data_part.palier_1;
          this.progress_2 = 0;
          this.progress_3 = 0;
          break;
        case 1:
          this.palier_level = 1;
          palier = data_part.palier_1;
          palier_2 = data_part.palier_2;
          this.progress_3 = 0;
          this.progress_2 = ((value - palier) / (palier_2 - palier)) * 100;
          break;
        case 2:
          this.palier_level = 2;
          palier = data_part.palier_1;
          palier_2 = data_part.palier_2;
          palier_3 = data_part.palier_3;
          this.progress_2 = ((value - palier) / (palier_2 - palier)) * 100;
          this.progress_3 = ((value - palier_2) / (palier_3 - palier_2)) * 100;
          break;
        case 3:
          this.palier_level = 3;
          palier = data_part.palier_3;
          palier_2 = data_part.palier_2;
          palier_3 = data_part.palier_3;
          this.progress_2 = ((value - palier) / (palier_2 - palier)) * 100;
          this.progress_3 = ((value - palier_2) / (palier_3 - palier_2)) * 100;
          break;
      }
      console.log(palier)
      this.progress = (value / palier) * 100;


      console.log(value)


    });
  }



}




function sleep(arg0: number) {
  throw new Error('Function not implemented.');
}


<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>





<div *ngIf="isConnected">

    <div *ngFor="let data_tournoi of tournoiList;let i=index">
        <div class="d-flex justify-content-center">
            <div class="justify-content-center w-75">
                <div class="row py-2  white-text ">
                    <h2>{{data_tournoi.nom_tournoi}} | {{data_tournoi.date_tournoi}}</h2>
                </div>
                <div class="row   bg-success rounded white-text">
                    <div class="col-md-4">
                        <img src="assets/img/{{data_tournoi.img_tournoi}}" class="img-fluid rounded img-game"
                            alt="Responsive image">
                    </div>
                    <div class="col-md-8  text-center">
                        <p class="py-2 game">
                            {{data_tournoi.nom_jeu}} </p>
                        <p *ngIf="data_tournoi.num_byteam == 1"> Solo </p>
                        <p *ngIf="data_tournoi.num_byteam > 1"> Equipe de {{data_tournoi.num_byteam}} </p>
                        <p> {{tab_inscrit[i]}} / {{data_tournoi.num_inscrit}} </p>
                        <p>
                            <button *ngIf="data_tournoi.etat_tournoi == 1" routerLink="../tournoi/{{data_tournoi.id}}"
                                mdbBtn color="orange" rounded="true" class="waves-effect  " mdbWavesEffect
                                type="submit">Tournoi en cours</button>
                            <button *ngIf="data_tournoi.etat_tournoi == 2" routerLink="../tournoi/{{data_tournoi.id}}"
                                mdbBtn color="success" rounded="true" class="waves-effect  " mdbWavesEffect
                                type="submit">Tournoi terminé</button>
                            <span *ngIf="data_tournoi.etat_tournoi == 0">
                                <button *ngIf="tab_etat[i]==1" mdbBtn color="orange" rounded="true"
                                    class="waves-effect  " mdbWavesEffect type="submit" data-toggle="modal"
                                    data-target="#basicExample" (click)="frame.show()">En cours... &nbsp;
                                    <span *ngFor="let data_team of tab_invit">
                                        <span *ngIf="data_team[0] == data_tournoi.id">
                                            <span
                                                *ngFor="let f of [].constructor(data_tournoi.num_byteam); let r = index">
                                                <span *ngIf="data_team[r+3][1] == 0">
                                                    <mdb-icon class="yellow-text" fas icon="user-alt"></mdb-icon>&nbsp;
                                                </span>
                                                <span *ngIf="data_team[r+3][1] == 1">
                                                    <mdb-icon class="dark-red" fas icon="user-alt"></mdb-icon>&nbsp;
                                                </span>
                                                <span *ngIf="data_team[r+3][1] >= 2">
                                                    <mdb-icon class="dark-green" fas icon="user-alt"></mdb-icon>&nbsp;
                                                </span>
                                            </span>
                                        </span>
                                    </span>

                                </button>


                                <button *ngIf="tab_etat[i]==2" mdbBtn color="success" rounded="true"
                                    class="waves-effect  " mdbWavesEffect type="submit">Inscrit</button>
                                <button *ngIf="tab_etat[i]==0" mdbBtn color="info" rounded="true" class="waves-effect  "
                                    mdbWavesEffect (click)="frame.show()">
                                    <mdb-badge *ngIf="notif[i]>0" pill="false" danger="true">{{notif[i]}}</mdb-badge>
                                    &nbsp; S'inscrire
                                </button>
                                <button mdbBtn color="grey" routerLink="../tournoi/{{data_tournoi.id}}" rounded="true"
                                    class="waves-effect " mdbWavesEffect type="submit">

                                    <mdb-icon fas icon="eye"></mdb-icon>

                                </button>
                            </span>
                        </p>



                    </div>
                </div>
            </div>


        </div>

        <div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-notify modal-danger" role="document">
                <!--Content-->
                <div class="modal-content">
                    <!--Header-->
                    <div class="modal-header">
                        <p class="heading lead">{{data_tournoi.nom_tournoi}}</p>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="frame.hide()">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                    <!--Body-->
                    <div *ngIf="data_tournoi.num_byteam > 1">
                        <div *ngIf="tab_etat[i]==1">
                            <div *ngIf="tab_role[i] == 2">
                                <!--JOUEUR-->
                                <div class="modal-body">
                                    <span *ngFor="let data_team of tab_invit">
                                        <span *ngIf="data_team[0] == data_tournoi.id">
                                            <div
                                                *ngFor="let f of [].constructor(data_tournoi.num_byteam); let r = index">
                                                <div class="text-center">
                                                    <label>Joueur {{r + 1}}</label>
                                                    <p>

                                                        <span class="team"> {{data_team[r+3][0]}}</span> &nbsp;
                                                        <span *ngIf="data_team[r+3][1]==0">
                                                            <mdb-icon class="yellow-text" fas icon="user-alt">
                                                            </mdb-icon>&nbsp;
                                                        </span>
                                                        <span *ngIf="data_team[r+3][1]==1">
                                                            <mdb-icon class="dark-red" fas icon="user-alt"></mdb-icon>
                                                            &nbsp;
                                                        </span>
                                                        <span *ngIf="data_team[r+3][1]==2">
                                                            <mdb-icon class="green-text" fas icon="user-alt"></mdb-icon>
                                                            &nbsp;
                                                        </span>
                                                        <span *ngIf="data_team[r+3][1]==3">
                                                            <mdb-icon class="green-text" fas icon="crown"></mdb-icon>
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="tab_role[i] == 3">
                                <!--CAPITAINE-->
                                <div class="modal-body">
                                    <div class="text-center">
                                        <label>Joueur 1</label>
                                        <p class="team">{{userConnected}}</p>
                                    </div>
                                    <span *ngFor="let data_team of tab_invit">
                                        <span *ngIf="data_team[0] == data_tournoi.id">
                                            <div
                                                *ngFor="let f of [].constructor(data_tournoi.num_byteam); let r = index">
                                                <span *ngIf="data_team[r+3][0] != userConnected">
                                                    <div class="text-center">
                                                        <label>Joueur {{r + 2}} :

                                                            <span *ngIf="data_team[r+3][1]==1"
                                                                class="red-text">{{data_team[r+3][0]}}</span>
                                                            <span *ngIf="data_team[r+3][1]==0"
                                                                class="yellow-text">{{data_team[r+3][0]}}</span>
                                                            <span *ngIf="data_team[r+3][1]==2"
                                                                class="green-text">{{data_team[r+3][0]}}</span>

                                                        </label>

                                                        <select class="browser-default custom-select mb-4">
                                                            <option value="" disabled>Choisis un joueur </option>
                                                            <option *ngFor="let data_user of userList"
                                                                [value]="data_user.username">
                                                                {{data_user.username}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </span>
                                            </div>
                                        </span>
                                    </span>

                                </div>
                                <div class="modal-footer justify-content-center">
                                    <a type="button" mdbBtn color="success" class="waves-effect" mdbWavesEffect>
                                        <mdb-icon fas icon="check"></mdb-icon>
                                    </a>
                                    <a type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect
                                        (click)="frame.hide()">
                                        <mdb-icon fas icon="times"></mdb-icon>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="tab_etat[i]==0">
                            <div class="modal-body">
                                <div *ngIf="notif[i]>0">
                                    Invitation
                                    <div class="modal-footer">

                                    </div>
                                    <div *ngFor="let data_team of tab_invit">
                                        <div *ngIf="data_team[0] == data_tournoi.id">
                                            <b class="team">{{data_team[2]}}</b>
                                            <div class="row">
                                                <div class="col-md-10">{{data_team[3]}}</div>
                                                <div class="col-md-1"> <a color="green" class="green-text"
                                                        (click)="valid_invit(data_team[1], data_tournoi.id)">
                                                        <mdb-icon class="green-text" fas icon="check"></mdb-icon>
                                                    </a></div>
                                                <div class="col-md-1"> <a class=""
                                                        (click)="refuser_invit(data_team[1])">
                                                        <mdb-icon class="dark-red" fas icon="times"></mdb-icon>
                                                    </a> </div>


                                            </div>
                                            <p></p>
                                        </div>
                                    </div>

                                </div>

                                Inscription
                                <div class="modal-footer justify-content-center">

                                </div>
                                <div class="text-center">
                                    <label>Joueur 1</label>
                                    <p class="team">{{userConnected}}</p>
                                </div>
                                <div *ngFor="let f of [].constructor(data_tournoi.num_byteam - 1); let r = index">
                                    <div class="text-center">
                                        <label>Joueur {{r + 2}}</label>

                                        <select [(ngModel)]="tab_valid[r]" class="browser-default custom-select mb-4">
                                            <option value="" disabled>Choisis un joueur </option>
                                            <option *ngFor="let data_user of userList"
                                                [value]="data_user.id + '/' + data_user.username">
                                                {{data_user.username}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <label>Nom de l'équipe</label>
                                    <input mdbInput type="text" [(ngModel)]="tab_team[i]" class="form-control"
                                        placeholder="">
                                </div>

                            </div>
                            <!--Footer-->
                            <div class="modal-footer justify-content-center">

                                <div *ngIf="alerteDisplay" (click)="deleteAlert()"
                                    class="  w-100 text-white rounded-pill  border border-danger "
                                    style="background-color:#7f1d1d;">

                                    <div class="text-center" [innerHTML]="textAlert"></div>


                                </div>

                                <a (click)="valid_inscription(data_tournoi.id,i,data_tournoi.num_byteam - 1)"
                                    type="button" mdbBtn color="success" class="waves-effect" mdbWavesEffect>
                                    <mdb-icon fas icon="check"></mdb-icon>
                                </a>
                                <a type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect
                                    (click)="frame.hide()">
                                    <mdb-icon fas icon="times"></mdb-icon>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="data_tournoi.num_byteam == 1">





                        <div class="modal-footer justify-content-center">
                            <div class="row team">Voulez-vous vous inscrire pour le tournoi de
                                {{data_tournoi.nom_jeu}}
                                ?</div>
                            <div class="row">
                                <a (click)="valid_inscription_solo(data_tournoi.id)" type="button" mdbBtn
                                    color="success" class="waves-effect" mdbWavesEffect>
                                    <mdb-icon fas icon="check"></mdb-icon>
                                </a>
                                <a type="button" mdbBtn color="danger" class="waves-effect" mdbWavesEffect
                                    (click)="frame.hide()">
                                    <mdb-icon fas icon="times"></mdb-icon>
                                </a>
                            </div>
                        </div>
                    </div>



                </div>
                <!--/.Content-->
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isConnected">
    <div class="d-flex justify-content-center" *ngFor="let data_tournoi of tournoiList;let i=index">
        <div class="justify-content-center w-75">
            <div class="row py-2  white-text ">
                <h2>{{data_tournoi.nom_tournoi}} | {{data_tournoi.date_tournoi}}</h2>
            </div>
            <div class="row   bg-success rounded white-text">
                <div class="col-md-4">
                    <img src="assets/img/{{data_tournoi.img_tournoi}}" class="img-fluid rounded img-game"
                        alt="Responsive image">
                </div>
                <div class="col-md-8  text-center">
                    <p class="py-2 game">
                        {{data_tournoi.nom_jeu}} </p>
                    <p *ngIf="data_tournoi.num_byteam == 1"> Solo </p>
                    <p *ngIf="data_tournoi.num_byteam > 1"> Equipe de {{data_tournoi.num_byteam}} </p>
                    <p> {{tab_inscrit[i]}} / {{data_tournoi.num_inscrit}} </p>
                    <p> <button mdbBtn color="info" routerLink="../tournoi/{{data_tournoi.id}}" rounded="true"
                            class="waves-effect  " mdbWavesEffect type="submit">
                            Visualiser
                        </button>
                    </p>



                </div>
            </div>
        </div>


    </div>
</div>
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  constructor(private http: HttpClient) { }

  evs: EventSource;
  evImage: EventSource;

  private subj = new BehaviorSubject([]);
  returnAsObservable() {
    return this.subj.asObservable();
  }


  GetExchangeData() {
    let subject = this.subj;
    let test;
    if (typeof (EventSource) !== 'undefined') {
      this.evs = new EventSource('//expedigame.com/express/getStockUpdate');
      this.evs.onopen = function (e) {
      }
      this.evs.onmessage = function (e) {

        subject.next(JSON.parse(e.data));
        console.log(e.data)
      }
      this.evs.addEventListener("timestamp", function (e) {
        subject.next(e["data"]);
      })
      this.evs.onerror = function (e) {
        console.log(e);
        if (this.readyState == 0) {
          console.log("Reconnecting…");
        }
      }
    }
  }

  GetExchangeImage() {
    let subject = this.subj;
    let test;
    if (typeof (EventSource) !== 'undefined') {
      this.evs = new EventSource('//expedigame.com/express/getStockImage');
      this.evs.onopen = function (e) {
      }
      this.evs.onmessage = function (e) {

        subject.next(JSON.parse(e.data));

      }
      this.evs.addEventListener("timestamp", function (e) {
        subject.next(e["data"]);
      })
      this.evs.onerror = function (e) {
        console.log(e);
        if (this.readyState == 0) {
          console.log("Reconnecting…");
        }
      }
    }
  }

  stopExchangeUpdates() {
    this.evs.close();
  }



  getPari() {
    return this.http.get('https://expedigame.com/express/getPari');
  }

  postImage(body) {

    return this.http.post('https://expedigame.com/express/postImage', body);
  }



  postLanceur(body) {
    console.log(body);
    return this.http.post('https://expedigame.com/express/postLanceur', body);
  }

  postWinner(body) {
    console.log(body);
    return this.http.post('https://expedigame.com/express/postWinner', body);
  }

  postLive(body) {
    console.log(body);
    return this.http.post('https://expedigame.com/express/postLive', body);
  }

  postLuma(body) {
    return this.http.post('https://expedigame.com/express/postLuma', body);
  }

  postGagnant(body) {
    return this.http.post('https://expedigame.com/express/postGagnant', body);

  }





}
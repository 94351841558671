<!-- Material form register -->
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
  <mdb-card class="w-50">

    <mdb-card-header class="info-color white-text text-center py-4">
      <h5>
        <strong>Inscription</strong>
      </h5>
    </mdb-card-header>

    <!--Card content-->
    <mdb-card-body class="px-lg-5 pt-0">

      <!-- Form -->
      <form *ngIf="!_userService.subSuccess" class="text-center" style="color: #757575;">

        <div class="md-form">
          <!-- First name -->
          <div class="md-form">
            <input type="text" name="pseudo" id="materialRegisterFormFirstName" class="form-control"
              [(ngModel)]="user.username" mdbInput>
            <label for="materialRegisterFormFirstName">Pseudo</label>
            <div *ngIf="_userService.error_pseudo"> <small class="text-danger">{{_userService.serror_pseudo}}</small>
            </div>
          </div>
        </div>

        <!-- E-mail -->
        <div class="md-form mt-0">
          <input type="email" name="email" id="materialRegisterFormEmail" class="form-control" [(ngModel)]="user.email"
            mdbInput>
          <label for="materialRegisterFormEmail">E-mail</label>
          <div *ngIf="_userService.error_email"> <small class="text-danger">{{_userService.serror_email}}</small> </div>
        </div>

        <!-- Password -->
        <div class="md-form">
          <input type="password" name="mdp" [(ngModel)]="user.password" id="materialRegisterFormPassword"
            class="form-control" aria-describedby="materialRegisterFormPasswordHelpBlock" mdbInput>
          <label for="materialRegisterFormPassword">Mot de passe</label>
        </div>
        <div class="row">
          <p></p>
        </div>
        <div class="row">
          <p></p>
        </div>

        <!-- Birthday -->

        <div class="md-form">
          <input type="date" name="dateAnnif" [(ngModel)]="user.dateAnniv" id="materialRegisterFormDate"
            class="form-control" aria-describedby="materialRegisterFormDateHelpBlock">
          <label class="active" for="birthday">Ton anniversaire !</label>
          <div *ngIf="_userService.error_date"> <small class="text-danger">{{_userService.serror_date}}</small> </div>
        </div>



        <!-- Sign up button -->
        <button mdbBtn color="info" outline="true" rounded="true" block="true" class="my-4 waves-effect z-depth-0"
          mdbWavesEffect (click)="newUser()" type="submit">S'inscrire</button>




      </form>
      <!-- Form -->
      <div *ngIf="_userService.subSuccess" class="text-center">
        Votre inscription est terminé ! <br> Lier votre compte twitch dans votre <a routerLink="/profil"
          routerLinkActive="active">profil</a> pour accéder au fonctionnalité du site.
      </div>
    </mdb-card-body>

  </mdb-card>
</div>
<app-separateur></app-separateur>


<!-- Material form register -->
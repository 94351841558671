<div class="flex flex-col bg-cover bg-center relative bg-black">
  <div class="bg-image-cover" [style.background-image]="banniere"></div>
  <img *ngIf="user_part.data.img_profil != null" [style.background-color]="colorProfil"
    src="{{user_part.data.img_profil}}" class="rounded-circle profil_img border border-info img-fluid"
    alt="Responsive image">
  <img *ngIf="cadre != ''" src="{{cadre}}" class="rounded-circle cadre_img  img-fluid" alt="Responsive image">-->
  <div class="rounded-circle level border border-info">{{user_part.data.level}}</div>
  <div class="progress unique-color-dark ">
    <div class="progress-bar rounded purple lighten-2 test px-1" role="progressbar" aria-valuenow="40" aria-valuemin="0"
      aria-valuemax="100" style="width: {{pourcentage}}%">

    </div>
  </div>

</div>

<ul class="nav special-color-dark py-4 white-text">
  <li class="nav-item ">
    <a id="profil" class="nav-link px-3 active" (click)="navChange(1)">Profil</a>
  </li>
  <li class="nav-item ">
    <a id="succes" class="nav-link px-3" (click)="navChange(2)">Succès</a>
  </li>

  <!-- <li class="nav-item ml-auto">
    <a id="parametre" class="nav-link px-3" (click)="navChange(3)">
      <mdb-icon class="iconNav" fas icon="cog" size="2x"></mdb-icon>
    </a>
  </li>-->

</ul>


<app-separateur></app-separateur>


<div class="" *ngIf="navProfil==0">
  <div class="justify-content-center text-center" *ngIf="!_twitch_name">


    <a class=" justify-content-center"
      href="https://id.twitch.tv/oauth2/authorize?client_id=xn3g12yzv87p0ohpnfsi6o1rh638dm&redirect_uri=https://expedigame.com/profil/&response_type=code&scope=user%3Aread%3Asubscriptions&state=85e19155860186db1bca9f1b9232ee9f">
      <button mdbBtn color="secondary" rounded="true" class="my-4 waves-effect w-25 " mdbWavesEffect type="submit">Lier
        compte twitch</button>
    </a>
  </div>
  <!-- <div class="row" *ngIf="_twitch_name">
    <div class="col text-center">
      <p class="h5 my-4  "> {{userTwitch}} </p><a mdbBtn (click)="updateTwitchName('')" class="btn text-right" size="lg"
        color="red">
        <mdb-icon fas class="white-text text-left pr-2" icon="trash-alt"></mdb-icon> Supprimer
      </a>
    </div>
  </div>-->
  <div *ngIf="clipExist" class="d-flex justify-content-center">
    <div class="justify-content-center w-75" *ngIf="_twitch_name">
      <div class="row py-2  white-text ">
        <h2>Clips</h2>
      </div>
      <div class="row py-2  bg-success rounded">


        <div class="col-md-3 py-2" *ngFor="let data_clip of clipList">


          <a href="{{data_clip.url_video}}" target="_blank">
            <div class="view overlay zoom">
              <img src="{{data_clip.url_img}}" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Vue : {{data_clip.count_view}}</p>
              </div>
            </div>
          </a>

        </div>

        <div class="col-md-12 ">


          <a class=" justify-content-center"
            href="https://id.twitch.tv/oauth2/authorize?client_id=xn3g12yzv87p0ohpnfsi6o1rh638dm&redirect_uri=https://expedigame.com/profil/&response_type=code&scope=user%3Aread%3Asubscriptions&state=85e19155860186db1bca9f1b9232ee9f">
            <button mdbBtn color="secondary" rounded="true" class="my-4 waves-effect " mdbWavesEffect
              type="submit">Mettre à jour le nombre de vue</button>
          </a>

        </div>
      </div>
    </div>



  </div>
  <app-separateur></app-separateur>


  <div class="d-flex justify-content-center">
    <div class="justify-content-center w-75" *ngIf="_twitch_name">
      <div class="row py-2  white-text ">
        <h2>Bannière</h2>
      </div>
      <div class="row py-2  bg-success rounded">

        <div class="col-md-3 py-2" *ngFor="let user_data_banniere of userBanniereList">
          <div *ngFor="let data_banniere of banniereList">



            <div *ngIf="user_data_banniere.banniere == data_banniere.id">
              <a (click)="updateUserBanniere(data_banniere.id, data_banniere.nom_img)">
                <div class="view overlay zoom">
                  <img src="assets/img/{{data_banniere.nom_img}}.png" class="img-fluid " alt="smaple image">
                  <div class="mask flex-center">
                    <p class="white-text">Change</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="cadreExist" class="d-flex justify-content-center">
    <div class="justify-content-center w-75" *ngIf="_twitch_name">
      <div class="row py-2  white-text ">
        <h2>Cadre</h2>
      </div>
      <div class="row py-2  bg-success rounded">

        <div class="col-md-2 py-2" *ngFor="let user_data_cadre of userCadreList">
          <div *ngFor="let data_cadre of cadreList">



            <div *ngIf="user_data_cadre.cadre == data_cadre.id">
              <a (click)="updateUserCadre(data_cadre.id, data_cadre.nom_img)">
                <div class="view overlay zoom">
                  <img src="assets/img/{{data_cadre.nom_img}}.png" class="img-fluid " alt="smaple image">
                  <div class="mask flex-center">
                    <p class="white-text">Change</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <div class="justify-content-center w-75" *ngIf="_twitch_name">
      <div class="row py-2  white-text ">
        <h2>Couleur profil</h2>
      </div>
      <div class="row py-2  bg-success rounded">

        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#ff0000')">
            <div class="view overlay zoom">
              <img src="assets/img/color_red.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#000000')">
            <div class="view overlay zoom">
              <img src="assets/img/color_black.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#ffffff')">
            <div class="view overlay zoom">
              <img src="assets/img/color_white.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#ffff00')">
            <div class="view overlay zoom">
              <img src="assets/img/color_yellow.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#ff00ff')">
            <div class="view overlay zoom">
              <img src="assets/img/color_pink.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#00ff00')">
            <div class="view overlay zoom">
              <img src="assets/img/color_green.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#00ffff')">
            <div class="view overlay zoom">
              <img src="assets/img/color_blue_light.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#0000ff')">
            <div class="view overlay zoom">
              <img src="assets/img/color_blue.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-2 py-2">
          <a (click)="putColorProfil('#777777')">
            <div class="view overlay zoom">
              <img src="assets/img/color_grey.png" class="img-fluid " alt="smaple image">
              <div class="mask flex-center">
                <p class="white-text">Change</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>


  <!--<a class=" justify-content-center"
    href="https://id.twitch.tv/oauth2/authorize?client_id=xn3g12yzv87p0ohpnfsi6o1rh638dm&redirect_uri=http://localhost:4200/profil/&response_type=code&scope=user%3Aread%3Asubscriptions&state=85e19155860186db1bca9f1b9232ee9f">
    <button mdbBtn color="secondary" rounded="true" class="my-4 waves-effect w-25 " mdbWavesEffect type="submit">Lier
      compte twitch</button>
  </a>-->

</div>









<div class="d-flex justify-content-center" *ngIf="navProfil==1">
  <div class="container">
    <div class="row flex white-text">
      <div class="col-3 flex">
        <div class="row py-2 d-flex bg-info rounded">

          <div class="flex col-md-4 text-center">
            <i class="py-2 fas fa-coins"></i>
            <p>{{user_part.data.expedipoint}}</p>
          </div>
          <div class="flex col-md-4 text-center">
            <i class="py-2 far fa-gem"></i>
            <p>{{user_part.data.diamant}}</p>
          </div>
          <div class="flex col-md-4 text-center">
            <i class="py-2 fas fa-ticket-alt"></i>
            <p>{{user_part.data.ticket_sub}}</p>
          </div>
        </div>
      </div>
      <div class="col-9 flex">




        <div *ngFor="let data_success of succesList; let index_Succes = index">
          <div *ngFor="let user_data_success of userSuccesList">
            <div
              *ngIf="infoSucces( data_success.id , user_data_success.succes, index_Succes,user_data_success.currently_Value,user_data_success.Rang )">
              <div class="row py-2 bg-success rounded">
                <div class="col-2">
                  <img src="{{url_Succes}}" class="img-fluid" alt="Responsive image">
                </div>
                <div class="col-10 white-text">
                  <h2>{{data_success.titre}}</h2>
                  <p>{{data_success.description_deb}} {{goal_actual}} {{data_success.description_fin}}</p>
                  <button *ngIf="buttonReward" (click)="getRewardSucces(user_data_success.succes)" mdbBtn
                    color="secondary" rounded="true" class="waves-effect " mdbWavesEffect
                    type="submit">Récompense</button>

                  <div *ngIf="!buttonReward" class="progress">

                    <div class="progress-bar" role="progressbar" style="width: {{pourcentage_succes}}%"
                      aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>



      </div>
    </div>
  </div>


</div>

<div *ngIf="navProfil==2">
  <div class="d-flex justify-content-center">
    <div class="justify-content-center w-75">
      <div class="row py-2  white-text ">
        <h2>Changer mot de passe</h2>
      </div>
      <div class="row py-2  bg-success rounded">
        <div class="col-md-12 ">
          <h4 class="white-text">Ancien mot de passe</h4>
        </div>
        <div class="col-md-6">


          <div class="md-form md-outline">
            <input type="password" id="oldPassword" class="form-control bg-white" mdbInput>
          </div>
        </div>


        <div class="col-md-6 ">


        </div>

        <div class="col-md-6 ">

          <h4 class="white-text">Nouveau mot de passe</h4>
          <div class="md-form md-outline">
            <input type="password" id="newPassword" class="form-control bg-white" mdbInput>
          </div>

        </div>

        <div class="col-md-6 ">

          <h4 class="white-text">Confirmer nouveau mot de passe</h4>
          <div class="md-form md-outline">
            <input type="password" id="confirmPassword" class="form-control bg-white" mdbInput>

          </div>
        </div>
        <div class="col-md-12 ">




          <button mdbBtn color="secondary" (click)="changePassword()" rounded="true" class="my-4 waves-effect "
            mdbWavesEffect type="submit">Modifier mot de passe</button>


        </div>
      </div>
    </div>
  </div>
</div>





<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>


<div class="d-flex justify-content-center">
    <div id="twitch-embed"></div>
</div>

<div class="d-flex justify-content-center align-items-center" *ngIf="afficherAlert(1)" style="height: 100px;">
    <div (click)="deleteAlert()"
        class="d-flex justify-content-center w-50  text-white rounded-pill h-50 border border-danger align-items-center"
        style="background-color:#7f1d1d;">

        <div [innerHTML]="textAlert"></div>
        <!--<a class="text-white-50" (click)="deleteAlert()">
            <mdb-icon fas icon="times"></mdb-icon>
        </a>    -->

    </div>
</div>

<div class="d-flex justify-content-center align-items-center" *ngIf="afficherAlert(2)" style="height: 100px;">
    <div (click)="deleteAlert()"
        class="d-flex justify-content-center w-50  text-white rounded-pill h-50 border border-info align-items-center"
        style="background-color:#4a148c  ;">

        <div [innerHTML]="textAlert"></div>

    </div>
</div>

<div class="d-flex justify-content-center align-items-center" *ngIf="afficherAlert(3)" style="height: 100px;">
    <div (click)="deleteAlert()"
        class="d-flex justify-content-center w-50  text-white rounded-pill h-50 border border-success align-items-center"
        style="background-color:#33691e  ;">

        <div [innerHTML]="textAlert"></div>

    </div>
</div>





<div *ngIf="!navProfil">



    <div *ngIf="commandeAmongUs" class="d-flex justify-content-center">
        <app-separateur></app-separateur>
        <div class=" w-75 ">
            <h2 class="white-text text-left">Among us</h2>
            <div class="rounded bg-success text-center">
                <button mdbBtn type="button" class="commande rounded" (click)="postAmongus(1, 'assets/img/1er.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/ghost_1.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">1 mort</p>
                </button>

                <button mdbBtn type="button" class="commande rounded" (click)="postAmongus(2,'assets/img/2eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/ghost_2.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">2 morts</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postAmongus(3, 'assets/img/3eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/ghost_3.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">3 morts</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postAmongus(4, 'assets/img/4eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/ghost_4.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">4 morts</p>
                </button>

                <button mdbBtn type="button" class="commande rounded" (click)="postAmongus(5, 'assets/img/5eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/ghost_5.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">5 morts</p>
                </button>

                <button mdbBtn type="button" class="commande rounded " (click)="postAmongus(6, 'assets/img/6eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/ghost_6.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">6 morts</p>
                </button>

                <button mdbBtn type="button" class="commande rounded" (click)="postAmongus(7, 'assets/img/7eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/ghost_7.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">7 morts</p>
                </button>
                <button mdbBtn type="button" *ngIf="nbreJoueur > 9" class="commande rounded"
                    (click)="postAmongus(8, 'assets/img/8eme.png')" color="elegant" mdbWavesEffect><img
                        src="assets/img/ghost_8.png" class="img-fluid py-2 among_us" alt="smaple image">
                    <p class="py-2">8 morts</p>
                </button>
                <button mdbBtn type="button" *ngIf="nbreJoueur > 10" class="commande rounded"
                    (click)="postAmongus(9, 'assets/img/9eme.png')" color="elegant" mdbWavesEffect><img
                        src="assets/img/ghost_9.png" class="img-fluid py-2 among_us" alt="smaple image">
                    <p class="py-2">9 morts</p>
                </button>
                <button mdbBtn type="button" *ngIf="nbreJoueur > 11" class="commande rounded"
                    (click)="postAmongus(10, 'assets/img/10eme.png')" color="elegant" mdbWavesEffect><img
                        src="assets/img/ghost_10.png" class="img-fluid py-2 among_us" alt="smaple image">
                    <p class="py-2">10 morts</p>
                </button>
                <button mdbBtn type="button" *ngIf="nbreJoueur > 12" (click)="postAmongus(11, 'assets/img/11eme.png')"
                    class="commande rounded" color="elegant" mdbWavesEffect><img src="assets/img/ghost_11.png"
                        class="img-fluid py-2 among_us" alt="smaple image">
                    <p class="py-2">11 morts</p>
                </button>
                <button mdbBtn type="button" *ngIf="nbreJoueur > 13" class="commande rounded"
                    (click)="postAmongus(12, 'assets/img/12eme.png')" color="elegant" mdbWavesEffect><img
                        src="assets/img/ghost_12.png" class="img-fluid py-2 among_us" alt="smaple image">
                    <p class="py-2">12 morts</p>
                </button>
                <button mdbBtn type="button" *ngIf="nbreJoueur > 14" class="commande rounded"
                    (click)="postAmongus(13, 'assets/img/12eme.png')" color="elegant" mdbWavesEffect><img
                        src="assets/img/ghost_13.png" class="img-fluid py-2 among_us" alt="smaple image">
                    <p class="py-2">13 morts</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postAmongus(14, 'assets/img/12eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/imposteur.png" class="img-fluid py-2 among_us"
                        alt="smaple image">
                    <p class="py-2">Win imposteur</p>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="commandeAgrou" class="d-flex justify-content-center">
        <app-separateur></app-separateur>
        <div class=" w-75 ">
            <h2 class="white-text text-left">Agrou</h2>
            <div class="rounded bg-success text-center">
                <button mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(1, 'assets/img/villageois.png', 'le vote du village')" color="elegant"
                    mdbWavesEffect><img src="assets/img/villageois.png" class="img-fluid py-1 rounded"
                        alt="smaple image">
                    <p class="py-2">Villageois</p>
                </button>

                <button *ngIf="tableauAgrou[0]" mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(2, 'assets/img/sorciere.png', 'la sorciere')" color="elegant" mdbWavesEffect><img
                        src="assets/img/witch.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Sorcière</p>
                </button>
                <button *ngIf="tableauAgrou[1]" mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(3, 'assets/img/amour.png', 'l\'amour qu\'il éprouve')" color="elegant"
                    mdbWavesEffect><img src="assets/img/amour.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Amour</p>
                </button>
                <button *ngIf="tableauAgrou[2]" mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(4, 'assets/img/chasseur.png', 'le chasseur')" color="elegant" mdbWavesEffect><img
                        src="assets/img/chasseur.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Chasseur</p>
                </button>

                <button *ngIf="tableauAgrou[3]" mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(5, 'assets/img/dictateur.png', 'le dictateur')" color="elegant"
                    mdbWavesEffect><img src="assets/img/dictateur.png" class="img-fluid py-1 rounded"
                        alt="smaple image">
                    <p class="py-2">Dictateur</p>
                </button>

                <button *ngIf="tableauAgrou[4]" mdbBtn type="button" class="commande rounded "
                    (click)="postAgrou(6, 'assets/img/assassin.png', 'l\'assassin')" color="elegant" mdbWavesEffect><img
                        src="assets/img/assassin.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Assassin</p>
                </button>

                <button *ngIf="tableauAgrou[5]" mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(7, 'assets/img/pyroman.png', 'le pyroman')" color="elegant" mdbWavesEffect><img
                        src="assets/img/pyroman.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Pyroman</p>
                </button>
                <button *ngIf="tableauAgrou[6]" mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(8, 'assets/img/blaster.png', 'le blaster')" color="elegant" mdbWavesEffect><img
                        src="assets/img/blaster.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Blaster</p>
                </button>
                <button *ngIf="tableauAgrou[7]" mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(9, 'assets/img/ninja.png', 'le ninja')" color="elegant" mdbWavesEffect><img
                        src="assets/img/ninja.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Ninja</p>
                </button>
                <button mdbBtn type="button" class="commande rounded"
                    (click)="postAgrou(10, 'assets/img/maire.png', 'le maire')" color="elegant" mdbWavesEffect><img
                        src="assets/img/maire.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Maire</p>
                </button>
                <button *ngIf="tableauAgrou[8]" mdbBtn type="button"
                    (click)="postAgrou(11, 'assets/img/loup.png', 'loup')" class="commande rounded" color="elegant"
                    mdbWavesEffect><img src="assets/img/loup.png" class="img-fluid py-1 rounded" alt="smaple image">
                    <p class="py-2">Loup win</p>
                </button>

            </div>
        </div>
    </div>
    <div *ngIf="commandeGiveAway" class="d-flex justify-content-center">
        <app-separateur></app-separateur>
        <div class=" w-75 ">
            <h2 class="white-text text-left">Give Away</h2>
            <div class="rounded bg-success text-center">
                <button mdbBtn type="button" class="commande rounded" (click)="giveAway(100)" color="elegant"
                    mdbWavesEffect><img src="assets/img/bronze_away.png" class="img-fluid py-2" alt="smaple image">
                    <p class="py-2">100 &nbsp;<i class="py-2 fas fa-coins"></i></p>
                </button>

                <button mdbBtn type="button" class="commande rounded" (click)="giveAway(200)" color="elegant"
                    mdbWavesEffect><img src="assets/img/silver_away.png" class="img-fluid py-2" alt="smaple image">
                    <p class="py-2">200 &nbsp;<i class=" py-2 fas fa-coins"></i></p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="giveAway(500)" color="elegant"
                    mdbWavesEffect><img src="assets/img/gold_away.png" class="img-fluid py-2" alt="smaple image">
                    <p class="py-2">500 &nbsp; <i class=" py-2 fas fa-coins"></i></p>
                </button>

            </div>
        </div>
    </div>
    <div *ngIf="CommandeMarioKart" class="d-flex justify-content-center">
        <app-separateur></app-separateur>
        <div class=" w-75 ">
            <h2 class="white-text text-left">Mario Kart</h2>
            <div class="rounded bg-success text-center">
                <button mdbBtn type="button" class="commande rounded" (click)="postPari(1, 'assets/img/1er.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/1er.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">1ère place</p>
                </button>

                <button mdbBtn type="button" class="commande rounded" (click)="postPari(2,'assets/img/2eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/2eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">2ème place</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postPari(3, 'assets/img/3eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/3eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">3ème place</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postPari(4, 'assets/img/4eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/4eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">4ème place</p>
                </button>

                <button mdbBtn type="button" class="commande rounded" (click)="postPari(5, 'assets/img/5eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/5eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">5ème place</p>
                </button>

                <button mdbBtn type="button" class="commande rounded " (click)="postPari(6, 'assets/img/6eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/6eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">6ème place</p>
                </button>

                <button mdbBtn type="button" class="commande rounded" (click)="postPari(7, 'assets/img/7eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/7eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">7ème place</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postPari(8, 'assets/img/8eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/8eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">8ème place</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postPari(9, 'assets/img/9eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/9eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">9ème place</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postPari(10, 'assets/img/10eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/10eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">10ème place</p>
                </button>
                <button mdbBtn type="button" (click)="postPari(11, 'assets/img/11eme.png')" class="commande rounded"
                    color="elegant" mdbWavesEffect><img src="assets/img/11eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">11ème place</p>
                </button>
                <button mdbBtn type="button" class="commande rounded" (click)="postPari(12, 'assets/img/12eme.png')"
                    color="elegant" mdbWavesEffect><img src="assets/img/12eme.png" class="img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">12ème place</p>
                </button>

            </div>
        </div>
    </div>
    <div *ngIf="CommandeLEGO" class="d-flex justify-content-center">
        <div class=" w-75 ">
            <h2 class="white-text text-left">LEGO</h2>
            <div class="rounded bg-success text-center">
                <button mdbBtn type="button" class="commande rounded"
                    (click)="postLego_horreur('Zerlys',1,'https://static-cdn.jtvnw.net/jtv_user_pictures/e0e42433-8e43-4605-8fbc-ebf4d34964e2-profile_image-300x300.png')"
                    color="elegant" mdbWavesEffect><img
                        src="https://static-cdn.jtvnw.net/jtv_user_pictures/e0e42433-8e43-4605-8fbc-ebf4d34964e2-profile_image-300x300.png"
                        class="img-fluid py-2" alt="smaple image">
                    <p class="py-2">Zerlys</p>
                </button>

                <button mdbBtn type="button" class="commande rounded"
                    (click)="postLego_horreur(viewerLego,2, viewerImageLego)" color="elegant" mdbWavesEffect><img
                        src="{{viewerImageLego}}" class="rounded-circle img-fluid py-2" alt="smaple image">
                    <p class="py-2">{{viewerLego}}</p>
                </button>

            </div>
        </div>
    </div>
    <div *ngIf="CommandeHorreur" class="d-flex justify-content-center">
        <div class=" w-75 ">
            <h2 class="white-text text-left">Horreur</h2>
            <div class="rounded bg-success text-center">
                <button mdbBtn type="button" class="commande rounded"
                    (click)="postLego_horreur('Zerlys',1,'https://static-cdn.jtvnw.net/jtv_user_pictures/e0e42433-8e43-4605-8fbc-ebf4d34964e2-profile_image-300x300.png')"
                    color="elegant" mdbWavesEffect><img
                        src="https://static-cdn.jtvnw.net/jtv_user_pictures/e0e42433-8e43-4605-8fbc-ebf4d34964e2-profile_image-300x300.png"
                        class="img-fluid py-2" alt="smaple image">
                    <p class="py-2">Zerlys</p>
                </button>

                <button mdbBtn type="button" class="commande rounded"
                    (click)="postLego_horreur(viewer_HorreurOne,2,viewerImage_HorreurOne)" color="elegant"
                    mdbWavesEffect><img src="{{viewerImage_HorreurOne}}" class="rounded-circle img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">{{viewer_HorreurOne}}</p>
                </button>

                <button mdbBtn type="button" class="commande rounded"
                    (click)="postLego_horreur(viewer_HorreurTwo,3,viewerImage_HorreurTwo)" color="elegant"
                    mdbWavesEffect><img src="{{viewerImage_HorreurTwo}}" class="rounded-circle img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">{{viewer_HorreurTwo}}</p>
                </button>

                <button mdbBtn type="button" class="commande rounded"
                    (click)="postLego_horreur(viewer_HorreurThree,4,viewerImage_HorreurThree)" color="elegant"
                    mdbWavesEffect><img src="{{viewerImage_HorreurThree}}" class="rounded-circle img-fluid py-2"
                        alt="smaple image">
                    <p class="py-2">{{viewer_HorreurThree}}</p>
                </button>

                <button mdbBtn type="button" class="commande rounded"
                    (click)="postLego_horreur('aucune mort',5,'assets/img/tombe.png')" color="elegant"
                    mdbWavesEffect><img src="assets/img/tombe.png" class=" img-fluid py-2" alt="smaple image">
                    <p class="py-2">Aucune mort</p>
                </button>

            </div>
        </div>
    </div>
</div>
<app-separateur></app-separateur>
<div class="d-flex justify-content-center">
    <div class=" w-75 ">
        <h2 class="white-text text-left">Information</h2>
        <div class="rounded bg-success text-center">
            <button mdbBtn type="button" class="commande rounded " color="elegant" mdbWavesEffect><img
                    src="assets/img/steam_logo.png" class="img-fluid py-2" alt="smaple image">
                <p class="py-2">Steam</p>
            </button>

            <button mdbBtn type="button" class="commande rounded" color="elegant" mdbWavesEffect><img
                    src="assets/img/Nintendo-Switch-Logo-PNG.png" class="img-fluid py-2" alt="smaple image">
                <p class="py-2">CA Switch</p>
            </button>
            <button mdbBtn type="button" class="commande rounded" color="elegant" mdbWavesEffect><img
                    src="assets/img/youtube.png" class="img-fluid py-2" alt="smaple image">
                <p class="py-2">Youtube</p>
            </button>
            <button mdbBtn type="button" class="commande rounded" color="elegant" mdbWavesEffect><img
                    src="assets/img/Discord-Logo.png" class="img-fluid py-2" alt="smaple image">
                <p class="py-2">Discord</p>
            </button>
            <button mdbBtn type="button" class="commande rounded" color="elegant" mdbWavesEffect><img
                    src="assets/img/twitter.png" class="img-fluid py-2" alt="smaple image">
                <p class="py-2">Twitter</p>
            </button>
        </div>
    </div>
</div>
<app-separateur></app-separateur>
<div *ngIf="groupAuthorization">
    <ul class=" nav special-color-dark py-4 white-text">
        <li class="nav-item ">
            <a class="nav-link active" (click)="navChange(1)">Commande</a>
        </li>
        <li class="nav-item " (click)="navChange(2)">
            <a class="nav-link">Modération</a>
        </li>

    </ul>
    <div *ngIf="navProfil">
        <ul class="nav stylish-color py-6 white-text justify-content-center">
            <li class="nav-item">Mario Kart
            </li>
        </ul>
        <section class="section-preview text-white ">
            <h1 *ngIf="liveLancer">Live en cours</h1>
            <h1 *ngIf="!liveLancer">Live interrompue </h1>
            <button mdbBtn type="button" color="deep-purple" (click)="launchLive(true)" mdbWavesEffect>DEBUT DE
                LIVE</button>

            <button mdbBtn type="button" color="deep-purple" (click)="launchLive(false)" mdbWavesEffect>FIN DE
                LIVE</button>

            <button mdbBtn type="button" color="deep-purple" (click)="lancerParis()" mdbWavesEffect>Lancer paris
                MK</button>

            <button mdbBtn type="button" color="deep-purple" (click)="postLuma()" mdbWavesEffect>get Luma</button>

            <button mdbBtn type="button" color="deep-purple" (click)="try.show()" mdbWavesEffect>Lancer Pari
                lego</button>

            <div mdbModal #try="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-notify modal-success" role="document">
                    <!--Content-->
                    <div class="modal-content">


                        <!--Body-->
                        <div class="modal-body">
                            <div class="text-center">
                                <div class="overflow-hidden">
                                    <select [(ngModel)]="LegoPari" class="browser-default custom-select">

                                        <option *ngFor="let data_user of userList" [value]="data_user.username">
                                            {{data_user.username}}
                                        </option>
                                    </select>

                                    <button (click)="addPariLego()"> valider</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!--/.Content-->
                </div>
            </div>

            <button mdbBtn type="button" color="deep-purple" (click)="amongus.show()" mdbWavesEffect>Lancer Pari Among
                us</button>

            <div mdbModal #amongus="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-notify modal-success" role="document">
                    <!--Content-->
                    <div class="modal-content">


                        <!--Body-->
                        <div class="modal-body">
                            <div class="text-center">
                                <div class="overflow-hidden">
                                    <div class="md-form md-outline">
                                        <input type="number" id="nbrePlayer" class="form-control bg-white" mdbInput>
                                    </div>

                                    <button (click)="addPariAmongUs()"> valider</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!--/.Content-->
                </div>
            </div>
            <button mdbBtn type="button" color="deep-purple" (click)="agrou.show()" mdbWavesEffect>Lancer Pari AGROU
            </button>

            <div mdbModal #agrou="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-notify modal-success" role="document">
                    <!--Content-->
                    <div class="modal-content">


                        <!--Body-->
                        <div class="modal-body">
                            <div class="text-center">
                                <div class="overflow-hidden">


                                    <input type="checkbox" id="sorciere" value="false">
                                    <label for="sorciere"> Sorciere</label>
                                    <br>

                                    <input type="checkbox" id="amour">
                                    <label for="amour"> Amour</label>
                                    <br>

                                    <input type="checkbox" id="chasseur">
                                    <label for="chasseur"> Chasseur</label>
                                    <br>

                                    <input type="checkbox" id="dictateur">
                                    <label for="dictateur"> Dictateur</label>
                                    <br>

                                    <input type="checkbox" id="assassin">
                                    <label for="assassin"> Assassin</label>
                                    <br>

                                    <input type="checkbox" id="pyroman">
                                    <label for="pyroman"> Pyroman</label>
                                    <br>

                                    <input type="checkbox" id="blaster">
                                    <label for="blaster"> Blaster</label>
                                    <br>

                                    <input type="checkbox" id="ninja">
                                    <label for="ninja"> Ninja</label>
                                    <br>


                                    <input type="checkbox" id="loup">
                                    <label for="loup"> Loup</label>
                                    <br>





                                    <button (click)="addPariAgrou()"> valider</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!--/.Content-->
                </div>
            </div>

            <button mdbBtn type="button" color="deep-purple" (click)="Horreur.show()" mdbWavesEffect>Lancer Pari
                horreur</button>

            <div mdbModal #Horreur="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-notify modal-success" role="document">
                    <!--Content-->
                    <div class="modal-content">


                        <!--Body-->
                        <div class="modal-body">
                            <div class="text-center">
                                <div class="overflow-hidden">
                                    <select [(ngModel)]="HorreurOne" class="browser-default custom-select">

                                        <option *ngFor="let data_user of userList" [value]="data_user.username">
                                            {{data_user.username}}
                                        </option>
                                    </select>

                                    <select [(ngModel)]="HorreurTwo" class="browser-default custom-select">

                                        <option *ngFor="let data_user of userList" [value]="data_user.username">
                                            {{data_user.username}}
                                        </option>
                                    </select>

                                    <select [(ngModel)]="HorreurThree" class="browser-default custom-select">

                                        <option *ngFor="let data_user of userList" [value]="data_user.username">
                                            {{data_user.username}}
                                        </option>
                                    </select>

                                    <button (click)="addPariHorreur()"> valider</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!--/.Content-->
                </div>
            </div>

        </section>

        <ul class="nav stylish-color py-6 white-text justify-content-center">
            <li class="nav-item" Résultat>
                resultat lego
            </li>
        </ul>

        <section class="section-preview text-white ">
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(1)" mdbWavesEffect>Zerlys</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(2)"
                mdbWavesEffect>{{viewerLego}}</button>
        </section>

        <ul class="nav stylish-color py-6 white-text justify-content-center">
            <li class="nav-item" Résultat>
                resultat horreur
            </li>
        </ul>

        <section class="section-preview text-white ">
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(1)" mdbWavesEffect>Zerlys</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(2)"
                mdbWavesEffect>{{viewer_HorreurOne}}</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(3)"
                mdbWavesEffect>{{viewer_HorreurTwo}}</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(4)"
                mdbWavesEffect>{{viewer_HorreurThree}}</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(5)" mdbWavesEffect>Aucune
                mort</button>
        </section>
        <ul class="nav stylish-color py-6 white-text justify-content-center">
            <li class="nav-item" Résultat>
                resultat Agrou
            </li>
        </ul>

        <section class="section-preview text-white ">
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(1)" mdbWavesEffect>villageois</button>
            <button *ngIf="tableauAgrou[0]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(2)"
                mdbWavesEffect>sorcière</button>
            <button *ngIf="tableauAgrou[1]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(3)"
                mdbWavesEffect>amour</button>
            <button *ngIf="tableauAgrou[2]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(4)"
                mdbWavesEffect>chasseur</button>
            <button *ngIf="tableauAgrou[3]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(5)"
                mdbWavesEffect>dictateur</button>
            <button *ngIf="tableauAgrou[4]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(6)"
                mdbWavesEffect>assassin</button>
            <button *ngIf="tableauAgrou[5]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(7)"
                mdbWavesEffect>pyroman</button>
            <button *ngIf="tableauAgrou[6]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(8)"
                mdbWavesEffect>blaster</button>
            <button *ngIf="tableauAgrou[7]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(9)"
                mdbWavesEffect>ninja</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(10)" mdbWavesEffect>maire</button>
            <button *ngIf="tableauAgrou[8]" mdbBtn type="button" color="deep-purple" (click)="postGagnant(11)"
                mdbWavesEffect>loup</button>
        </section>

        <ul class="nav stylish-color py-6 white-text justify-content-center">
            <li class="nav-item" Résultat>
                resultat Mario Kart
            </li>
        </ul>

        <section class="section-preview text-white ">
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(1)" mdbWavesEffect>1er</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(2)" mdbWavesEffect>2ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(3)" mdbWavesEffect>3ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(4)" mdbWavesEffect>4ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(5)" mdbWavesEffect>5ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(6)" mdbWavesEffect>6ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(7)" mdbWavesEffect>7ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(8)" mdbWavesEffect>8ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(9)" mdbWavesEffect>9ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(10)" mdbWavesEffect>10ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(11)" mdbWavesEffect>11ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(12)" mdbWavesEffect>12ème</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(13)" mdbWavesEffect>13 morts</button>
            <button mdbBtn type="button" color="deep-purple" (click)="postGagnant(14)" mdbWavesEffect>WIN
                imposteur</button>
        </section>
    </div>
</div>


<app-separateur></app-separateur>
<div id="ResultatPari" class="result_absolute text-right">
    <!-- le contenu défilant -->
    <button *ngIf="resultat" mdbBtn type="button" class="commande rounded border border-white" color="elegant"
        mdbWavesEffect>



        <div class="result_title row justify-content-center">
            Résultat &nbsp;:&nbsp; &nbsp; &nbsp; <img src="assets/img/{{result_pari}}eme.png" class="img-fluid "
                alt="smaple image">
        </div>
        <app-separateur></app-separateur>

        <div *ngFor="let data_winner of list_winner" class="result_player row justify-content-center py-1">
            <img src="{{data_winner[1]}}" class="img-fluid rounded-circle" alt="smaple image"> &nbsp; &nbsp; &nbsp;
            &nbsp; {{data_winner[0]}}
        </div>



    </button>

    <button *ngIf="!resultat" mdbBtn type="button" class="commande rounded border border-white" color="elegant"
        mdbWavesEffect>



        <div class="result_title row justify-content-center py-3">
            Pas de gagnant !
        </div>




    </button>

</div>
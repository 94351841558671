<app-separateur></app-separateur>
<app-separateur></app-separateur>
<app-separateur></app-separateur>

<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#chestModal" data-backdrop="static"
    data-keyboard="false" (click)="chest.show()"></button>

<button id="openError" [hidden]="true" data-toggle="modal" data-target="#chestModal" data-backdrop="static"
    data-keyboard="false" (click)="error.show()"></button>


<div class="d-flex justify-content-center">
    <div class="justify-content-center w-75">
        <div class="row py-2  white-text ">
            <h2>Coffre</h2>
        </div>
        <div class="row py-2  bg-success rounded">


            <div class="col-md-3 py-2">
                <button mdbBtn (click)="test.show()" type="button" class="commande rounded border border-success"
                    color="elegant" mdbWavesEffect>



                    <div> <img src="assets/img/chest.png" class="img-fluid py-2" alt="smaple image"></div>


                    <h5 class="py-2">10 000 &nbsp;<mdb-icon fas icon="coins"></mdb-icon>
                    </h5>


                </button>
            </div>

        </div>
    </div>
</div>

<div class="d-flex justify-content-center">
    <div class="justify-content-center w-75">
        <div class="row py-2  white-text ">
            <h2>Bannière</h2>
        </div>
        <div class="row py-2  bg-success rounded">

            <div class="col-md-3 py-2" *ngFor="let data_banniere of banniereList; let index = index">


                <div *ngIf="userBanniereList[index];else elseBlock">
                    <button mdbBtn type="button" class="commande rounded border border-success" color="elegant"
                        mdbWavesEffect>



                        <div> <img src="assets/img/{{data_banniere.nom_img}}.png" class="img-fluid py-2"
                                alt="smaple image">
                        </div>


                        <h5 class="py-2">
                            <mdb-icon class="green-text" fas icon="check-circle" size="2x"></mdb-icon>
                        </h5>
                    </button>
                </div>
                <ng-template #elseBlock>
                    <button mdbBtn (click)="try.show()" type="button" class="commande rounded border border-success"
                        color="elegant" mdbWavesEffect>
                        <div> <img src="assets/img/{{data_banniere.nom_img}}.png" class="img-fluid py-2"
                                alt="smaple image">
                        </div>

                        <h5 *ngIf="data_banniere.type_get==0" class="py-2">{{data_banniere.prix}} &nbsp; <i
                                class="white-text py-2 far fa-gem">
                            </i>
                        </h5>
                        <h5 *ngIf="data_banniere.type_get==1" class="py-2">{{data_banniere.prix}} &nbsp;<mdb-icon fas
                                icon="coins"></mdb-icon>

                        </h5>
                    </button>


                    <div mdbModal #try="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                        aria-labelledby="myModalLabel" aria-hidden="true" (open)="onOpen($event)">
                        <div class="modal-dialog modal-dialog-centered modal-notify modal-success" role="document">
                            <!--Content-->
                            <div class="modal-content rounded bg border border-info">


                                <!--Body-->
                                <div class="modal-body">
                                    <div class="text-center">
                                        <div class="overflow-hidden">
                                            <img src="assets/img/{{data_banniere.nom_img}}.png" class="img-fluid py-2 "
                                                alt="smaple image">
                                            <a *ngIf="data_banniere.type_get==0" type="button"
                                                (click)="buyBanniere(data_banniere.type_get,data_banniere.prix,data_banniere.id, index)"
                                                (click)="try.hide()" mdbBtn color="primary" class="waves-light"
                                                mdbWavesEffect><i class="white-text py-2 far fa-gem">
                                                </i> {{data_banniere.prix}}
                                            </a>
                                            <a *ngIf="data_banniere.type_get==1" type="button"
                                                (click)="buyBanniere(data_banniere.type_get,data_banniere.prix,data_banniere.id, index)"
                                                (click)="try.hide()" mdbBtn color="primary" class="waves-light"
                                                mdbWavesEffect><i class="py-2 fas fa-coins"></i> {{data_banniere.prix}}
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <!--/.Content-->
                        </div>
                    </div>
                </ng-template>


            </div>



        </div>
    </div>

</div>

<div class="d-flex justify-content-center">
    <div class="justify-content-center w-75">
        <div class="row py-2  white-text ">
            <h2>Cadre</h2>
        </div>
        <div class="row py-2  bg-success rounded">

            <div class="col-md-3 py-2" *ngFor="let data_cadre of cadreList; let index = index">


                <div *ngIf="userCadreList[index];else elseBlock">
                    <button mdbBtn type="button" class="commande rounded border border-success" color="elegant"
                        mdbWavesEffect>



                        <div> <img src="assets/img/{{data_cadre.nom_img}}.png" class="img-fluid py-2"
                                alt="smaple image">
                        </div>


                        <h5 class="py-2">
                            <mdb-icon class="green-text" fas icon="check-circle" size="2x"></mdb-icon>
                        </h5>
                    </button>
                </div>
                <ng-template #elseBlock>
                    <button mdbBtn (click)="try.show()" type="button" class="commande rounded border border-success"
                        color="elegant" mdbWavesEffect>
                        <div> <img src="assets/img/{{data_cadre.nom_img}}.png" class="img-fluid py-2"
                                alt="smaple image">
                        </div>

                        <h5 *ngIf="data_cadre.type_get==0" class="py-2">{{data_cadre.prix}} &nbsp; <i
                                class="white-text py-2 far fa-gem">
                            </i>
                        </h5>
                        <h5 *ngIf="data_cadre.type_get==1" class="py-2">{{data_cadre.prix}} &nbsp;<mdb-icon fas
                                icon="coins"></mdb-icon>

                        </h5>
                    </button>


                    <div mdbModal #try="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                        aria-labelledby="myModalLabel" aria-hidden="true" (open)="onOpen($event)">
                        <div class="modal-dialog modal-dialog-centered modal-notify  " role="document">
                            <!--Content-->
                            <div class="modal-content rounded bg border border-info">


                                <!--Body-->
                                <div class="modal-body">
                                    <div class="text-center">
                                        <div class="overflow-hidden">
                                            <img src="assets/img/{{data_cadre.nom_img}}.png" class="img-fluid py-2"
                                                alt="smaple image">
                                            <a *ngIf="data_cadre.type_get==0" type="button"
                                                (click)="buyCadre(data_cadre.type_get,data_cadre.prix,data_cadre.id)"
                                                (click)="try.hide()" mdbBtn color="primary" class="waves-light"
                                                mdbWavesEffect><i class="white-text py-2 far fa-gem">
                                                </i> {{data_cadre.prix}}
                                            </a>
                                            <a *ngIf="data_cadre.type_get==1" type="button"
                                                (click)="buyCadre(data_cadre.type_get,data_cadre.prix,data_cadre.id)"
                                                (click)="try.hide()" mdbBtn color="primary" class="waves-light"
                                                mdbWavesEffect><i class="py-2 fas fa-coins"></i> {{data_cadre.prix}}
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <!--/.Content-->
                        </div>
                    </div>
                </ng-template>


            </div>



        </div>
    </div>

</div>

<div mdbModal #error="mdbModal" class="modal fade" id="frameModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-notify  " role="document">
        <!--Content-->
        <div class="modal-content rounded bg-error border border-info">


            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <div class="overflow-hidden">
                        <div class="row">
                            <div class="col-md-12 text-center"> <img src="assets/img/error.png" width="100" height="100"
                                    class="img-fluid py-2" alt="smaple image"></div>

                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center white-text">
                                <h4 style="line-height: 65px;">{{error_boutique}} &nbsp; <i *ngIf="typeMoney"
                                        class="py-2 fas fa-coins"></i><i *ngIf="!typeMoney" class="py-2 far fa-gem"></i>
                                </h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <!--/.Content-->
</div>



<div mdbModal #test="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" (open)="onOpen($event)">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-notify modal-success " role="document">
        <!--Content-->
        <div class="modal-content rounded bg-success border border-success">
            <!--Header-->

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <div class="overflow-hidden">

                        <div class="row py-1 justify-content-center">

                            <div class="col-md-4 bg-success">
                                <img src="assets/img/chest.png" class="img-fluid  py-2" alt="smaple image">
                            </div>
                            <div class="col-md-8 bg-success responsive_chest">
                                <div class="row overflow">
                                    <div class="col-md-3  white-text py-2 flex-center text-center">
                                        <div class="w-75 ">
                                            <div class="bg-info border border-success"> <i
                                                    class="white-text py-2 fas fa-coins"></i>
                                                <p class="py-1"> 100
                                                </p>
                                            </div>
                                            <p class="py-1"> 66% </p>
                                        </div>
                                    </div>
                                    <div class="col-md-3  white-text py-2 flex-center text-center">
                                        <div class="w-75  ">
                                            <div class="bg-info border border-success"> <i
                                                    class="white-text py-2 fas fa-coins"></i>
                                                <p class="py-1"> 20 000
                                                </p>
                                            </div>
                                            <p class="py-1"> 14% </p>
                                        </div>
                                    </div>


                                    <div class="col-md-3  white-text py-2 flex-center text-center">
                                        <div class="w-75  ">
                                            <div class="bg-info border border-success"> <i
                                                    class="white-text py-2 far fa-gem">
                                                </i>
                                                <p class="py-1"> 5
                                                </p>
                                            </div>
                                            <p class="py-1"> 19.4 % </p>
                                        </div>
                                    </div>
                                    <div class=" col-md-3 white-text py-2 flex-center text-center">
                                        <div class="w-75  ">
                                            <div class="bg-info border border-success"> <i
                                                    class="white-text py-2 far fa-gem">
                                                </i>
                                                <p class="py-1"> 50
                                                </p>
                                            </div>
                                            <p class="py-1"> 0.1 % </p>
                                        </div>
                                    </div>
                                    <div class="col-md-3  white-text py-2 flex-center text-center">
                                        <div class="w-75  ">
                                            <div class="bg-info border border-success"> <i
                                                    class="white-text py-2 fas fa-ticket-alt">
                                                </i>
                                                <p class="py-1"> sub
                                                </p>
                                            </div>
                                            <p class="py-1"> 0.5 % </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <a type="button" (click)="buyChest()" (click)="test.hide()" mdbBtn color="primary"
                            class="waves-light" mdbWavesEffect><i class="py-2 fas fa-coins"></i> 10 000
                        </a>
                    </div>
                </div>
            </div>

            <!--Footer-->

        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal #chest="mdbModal" class="modal fade" id="chestModal" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" (close)="chestClose()">
    <div class="modal-dialog  modal-dialog-centered modal-notify modal-success" role="document">
        <!--Content-->
        <div class="modal-content modal-chest">
            <!--Header-->


            <!--Body-->
            <div class="modal-body modal-chest">
                <div class="text-center">
                    <div *ngIf="CardAppear" class="card animate fadeUp">
                        <div class="card__inner">
                            <div (click)="chest.hide()" class="card__face card__face--front">
                                <div class="card__content" *ngIf="showItem(1)">
                                    <div class="card__header">

                                        <h2>&nbsp;</h2>>
                                    </div>
                                    <div class="card__body">

                                        <h1>100 <i class="black-text py-2 fas fa-coins"></i></h1>

                                    </div>
                                </div>
                                <div class="card__content" *ngIf="showItem(2)">
                                    <div class="card__header">

                                        <h2>&nbsp;</h2>>
                                    </div>
                                    <div class="card__body">
                                        <h1>20 000 <i class="black-text py-2 fas fa-coins"></i></h1>

                                    </div>
                                </div>
                                <div class="card__content" *ngIf="showItem(3)">
                                    <div class="card__header">

                                        <h2>&nbsp;</h2>>
                                    </div>
                                    <div class="card__body">
                                        <h1>5 <i class="black-text py-2 far fa-gem"></i></h1>

                                    </div>
                                </div>
                                <div class="card__content" *ngIf="showItem(4)">
                                    <div class="card__header">

                                        <h2>&nbsp;</h2>>
                                    </div>
                                    <div class="card__body">
                                        <h1>1 <i class="black-text py-2 fas fa-ticket-alt"></i></h1>

                                    </div>
                                </div>
                                <div class="card__content" *ngIf="showItem(5)">
                                    <div class="card__header">

                                        <h2>&nbsp;</h2>>
                                    </div>
                                    <div class="card__body">
                                        <h1>50 <i class="black-text py-2 far fa-gem"></i></h1>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="flex-center chest">
                        <img (click)="chestOpen()" src="{{url}}" class="img-fluid" alt="Responsive image">
                    </div>
                </div>
            </div>


        </div>
        <!--/.Content-->
    </div>
</div>




<div class="d-flex justify-content-center align-items-center" *ngIf="needMoney" (click)="closeAlert()"
    style="height: 100px;">
    <div class="d-flex justify-content-center w-50  text-white rounded-pill h-50 border border-danger align-items-center"
        style="background-color:#7f1d1d;">

        <div>Pas assez d'expedipoints &nbsp;<i class="py-2 fas fa-coins"></i> </div>
        <!--<a class="text-white-50" (click)="deleteAlert()">
    <mdb-icon fas icon="times"></mdb-icon>
</a>    -->

    </div>
</div>







<app-separateur></app-separateur>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-view-profil',
  templateUrl: './view-profil.component.html',
  styleUrls: ['./view-profil.component.scss']
})
export class ViewProfilComponent implements OnInit {

  private baseUrl_banniere = "url(assets/img/";
  public banniere;
  public cadre = null;
  public image_profil = null;
  public url_Succes;
  public colorProfil = "#FFFFFF";
  public name;
  public succesList;
  public pourcentage;
  public level;
  public navProfil: boolean = false;
  public goal_actual;
  public pourcentage_succes;
  public userSuccesList;
  public clipList;
  private id_player;
  private id_player_twitch;
  private succesArray: string[] = new Array();
  httpOptions: { headers: any; };

  constructor(private route: ActivatedRoute, private _httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.name = params.get('id');
      this._httpClient.get("https://expedigame.com/api/userView/" + this.name).subscribe(data => {
        console.log(data);
        const user_id_decoded = JSON.stringify(data);
        const user_id_part = JSON.parse(user_id_decoded);
        this.image_profil = user_id_part.img_profil;
        this.pourcentage = user_id_part.experience / 10;
        this.level = user_id_part.level;
        this.id_player = user_id_part.id;
        this.id_player_twitch = user_id_part.id_twitch;
        this.getBanniere(this.id_player);
        this.getCadre(this.id_player);
        this.getClip();
        this._httpClient.get("https://expedigame.com/api/succes/").subscribe(data => {
          const succes_decoded = JSON.stringify(data);

          const succes_part = JSON.parse(succes_decoded);
          this.succesList = succes_part.results
          for (var i = 0; i < succes_part.results.length; i++) {

            this.succesArray.push(succes_part.results[i])

          }
          if (succes_part.next != null) {
            this._httpClient.get(succes_part.next).subscribe(data => {
              const succes_decoded = JSON.stringify(data);

              const succes_part = JSON.parse(succes_decoded);
              for (var i = 0; i < succes_part.results.length; i++) {
                this.succesList.push(succes_part.results[i]);
                this.succesArray.push(succes_part.results[i])
              }

            })
          }


        });
        this._httpClient.get("https://expedigame.com/api/userProfil/" + this.id_player).subscribe(data => {
          const user_succes_decoded = JSON.stringify(data);

          const user_succes_part = JSON.parse(user_succes_decoded);
          this.userSuccesList = user_succes_part.results
          if (user_succes_part.next != null) {
            this._httpClient.get(user_succes_part.next).subscribe(data => {
              const user_succes_decoded = JSON.stringify(data);

              const user_succes_part = JSON.parse(user_succes_decoded);
              for (var i = 0; i < user_succes_part.results.length; i++) {
                this.userSuccesList.push(user_succes_part.results[i]);

              }

            })
          }
        });
      });
    })



  }

  getBanniere(user_id) {


    this._httpClient.get("https://expedigame.com/api/banniere/activate/" + user_id, this.httpOptions).subscribe(data => {
      const userBanniere_decoded = JSON.stringify(data);
      const userBanniere_part = JSON.parse(userBanniere_decoded);
      console.log(data);
      this._httpClient.get("https://expedigame.com/api/banniere/" + userBanniere_part.banniere + "/", this.httpOptions).subscribe(data => {
        const banniere_decoded = JSON.stringify(data);
        const banniere_part = JSON.parse(banniere_decoded);
        console.log(data);
        this.banniere = this.baseUrl_banniere + banniere_part.nom_img + ".png)"
      });

    });
  }

  getCadre(user_id) {

    this.getColorProfil(user_id);
    this._httpClient.get("https://expedigame.com/api/cadre/activate/" + user_id, this.httpOptions).subscribe(data => {
      const userCadre_decoded = JSON.stringify(data);
      const userCadre_part = JSON.parse(userCadre_decoded);
      console.log(data);
      this._httpClient.get("https://expedigame.com/api/cadre/" + userCadre_part.cadre + "/", this.httpOptions).subscribe(data => {
        const cadre_decoded = JSON.stringify(data);
        const cadre_part = JSON.parse(cadre_decoded);
        console.log(data);
        this.cadre = "../assets/img/" + cadre_part.nom_img + ".png"
      });

    });
  }

  getColorProfil(user_id) {
    this._httpClient.get("https://expedigame.com/api/couleurProfil/" + user_id + "/").subscribe(data => {
      const color_decoded = JSON.stringify(data);

      const color_part = JSON.parse(color_decoded);
      this.colorProfil = color_part.couleur;
    });
  }

  infoSucces(id_Succes, id_user_Succes, index_Success, value, rang) {

    if (Number(id_Succes) == Number(id_user_Succes)) {

      const array_decoded = JSON.stringify(this.succesArray[index_Success]);
      const array_part = JSON.parse(array_decoded);
      var goal;
      switch (rang) {
        case 0:
          this.url_Succes = "../assets/img/" + array_part.url_img + "_gris.png"

          goal = array_part.goal_bronze
          this.goal_actual = array_part.goal_bronze
          break;
        case 1:
          this.url_Succes = "../assets/img/" + array_part.url_img + "_bronze.png"
          goal = array_part.goal_argent
          this.goal_actual = array_part.goal_argent
          break;
        case 2:
          this.url_Succes = "../assets/img/" + array_part.url_img + "_argent.png"
          goal = array_part.goal_or
          this.goal_actual = array_part.goal_or
          break;
        case 3:
          this.url_Succes = "../assets/img/" + array_part.url_img + "_or.png"
          goal = array_part.goal_or
          this.goal_actual = array_part.goal_or
          break;
      }
      if (array_part.description_fin == null) { this.goal_actual = "" }
      this.calculProgressBar(goal, value);
      return true;
    }
    return false;
  }

  calculProgressBar(goal, value) {

    var progressBar = Math.round(Number(value / goal) * 100);

    this.pourcentage_succes = progressBar;

  }

  navChange(numNav) {
    if (numNav == 2 && this.navProfil == false) {
      this.navProfil = true;
      var profil = document.getElementById("succes");
      profil.classList.remove("active");
      var succes = document.getElementById("clip");
      succes.classList.add("active");
    }
    if (numNav == 1 && this.navProfil == true) {
      this.navProfil = false;
      var profil = document.getElementById("succes");
      profil.classList.add("active");
      var succes = document.getElementById("clip");
      succes.classList.remove("active");

    }
  }

  getClip() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    this._httpClient.get("https://expedigame.com/api/clipList/" + this.id_player_twitch, this.httpOptions).subscribe(async data => {
      const clip_decoded = JSON.stringify(data);
      const clip_part = JSON.parse(clip_decoded);

      this.clipList = clip_part.results;
      var next = clip_part.next;
      while (next != null) {
        const reponse = await this._httpClient.get(next, this.httpOptions).toPromise();
        const clip_decoded = JSON.stringify(reponse);
        const clip_part = JSON.parse(clip_decoded);
        console.log(clip_part)
        for (var i = 0; i < clip_part.results.length; i++) {
          this.clipList.push(clip_part.results[i]);
        }
        next = clip_part.next;
      }
    });
  }

}

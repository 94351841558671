<app-separateur></app-separateur>
<app-separateur></app-separateur>



<div *ngIf="CardAppear" class="card animate fadeUp">
    <div class="card__inner">
        <div class="card__face card__face--front">
            <div class="card__content" *ngIf="showItem(1)">
                <div class="card__header">

                    <h2>&nbsp;</h2>>
                </div>
                <div class="card__body">
                    <h1>100 <i class="py-2 fas fa-coins"></i></h1>

                </div>
            </div>
            <div class="card__content" *ngIf="showItem(2)">
                <div class="card__header">

                    <h2>&nbsp;</h2>>
                </div>
                <div class="card__body">
                    <h1>20 000 <i class="py-2 fas fa-coins"></i></h1>

                </div>
            </div>
            <div class="card__content" *ngIf="showItem(3)">
                <div class="card__header">

                    <h2>&nbsp;</h2>>
                </div>
                <div class="card__body">
                    <h1>5 <i class="py-2 far fa-gem"></i></h1>

                </div>
            </div>
            <div class="card__content" *ngIf="showItem(4)">
                <div class="card__header">

                    <h2>&nbsp;</h2>>
                </div>
                <div class="card__body">
                    <h1>1 <i class="py-2 fas fa-ticket-alt"></i></h1>

                </div>
            </div>
            <div class="card__content" *ngIf="showItem(5)">
                <div class="card__header">

                    <h2>&nbsp;</h2>>
                </div>
                <div class="card__body">
                    <h1>50 <i class="py-2 far fa-gem"></i></h1>

                </div>
            </div>
        </div>

    </div>
</div>



<div class="flex-center" style="width:900px; max-width: 95vw; margin: auto;">
    <img (click)="chestOpen()" src="{{url}}" class="img-fluid" alt="Responsive image">
</div>
import { HttpClient } from '@angular/common/http';
import { GeneratedFile } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-classement',
  templateUrl: './classement.component.html',
  styleUrls: ['./classement.component.scss']
})
export class ClassementComponent implements OnInit {
  public pseudo = "zerlys";
  public classementList;
  public firstName;
  public secondName;
  public thirdName;
  public firstPoint;
  public secondPoint;

  public moisHTML;
  public mois_podiumHTML;
  public anneeHTML;
  public annee_podiumHTML;

  public thirdPoint;
  constructor(private general: AppComponent, private _httpClient: HttpClient) { }

  ngOnInit(): void {
    // this.general.updateClassement(1);
    var today = new Date();
    var mois_precedent;
    var annee_precedent;
    var mois_en_cours = today.getMonth() + 1;
    var annee = today.getFullYear();
    this.getMoisString(mois_en_cours, annee);
    if (mois_en_cours == 1) {
      mois_precedent = 12;
      annee_precedent = annee - 1;
    }
    else {
      mois_precedent = mois_en_cours - 1;
      annee_precedent = annee;
    }
    this._httpClient.get("https://expedigame.com/api/classement/mois/" + mois_precedent + "/annee/" + annee_precedent).subscribe(async data => {
      const classement_decoded = JSON.stringify(data);
      const classement_part = JSON.parse(classement_decoded);
      this.firstName = classement_part.results[0].username;
      this.firstPoint = classement_part.results[0].point;
      this.secondName = classement_part.results[1].username;
      this.secondPoint = classement_part.results[1].point;
      this.thirdName = classement_part.results[2].username;
      this.thirdPoint = classement_part.results[2].point;
    });

    this._httpClient.get("https://expedigame.com/api/classement/mois/" + mois_en_cours + "/annee/" + annee).subscribe(async data => {
      const classement_decoded = JSON.stringify(data);

      const classement_part = JSON.parse(classement_decoded);
      this.classementList = classement_part.results
      var nextPage = classement_part.next
      while (classement_part.next != null) {
        const reponse = await this._httpClient.get(nextPage).toPromise();
        const classement_decoded = JSON.stringify(reponse);
        const classement_part = JSON.parse(classement_decoded);
        nextPage = classement_part.next
        console.log(nextPage)
        for (var i = 0; i < classement_part.results.length; i++) {
          this.classementList.push(classement_part.results[i]);
        }



      }


    });
  }

  getMoisString(mois, annee) {
    this.anneeHTML = annee;
    this.annee_podiumHTML = annee;
    switch (mois) {
      case 1:
        this.annee_podiumHTML = annee - 1;
        this.mois_podiumHTML = "Décembre";
        this.moisHTML = "Janvier";
        break;
      case 2:
        this.mois_podiumHTML = "Janvier";
        this.moisHTML = "Février";
        break;
      case 3:
        this.mois_podiumHTML = "Février";
        this.moisHTML = "Mars";
        break;
      case 4:
        this.mois_podiumHTML = "Mars";
        this.moisHTML = "Avril";
        break;
      case 5:
        this.mois_podiumHTML = "Avril";
        this.moisHTML = "Mai";
        break;
      case 6:
        this.mois_podiumHTML = "Mai";
        this.moisHTML = "Juin";
        break;
      case 7:
        this.mois_podiumHTML = "Juin";
        this.moisHTML = "Juillet";
        break;
      case 8:
        this.mois_podiumHTML = "Juillet";
        this.moisHTML = "Août";
        break;
      case 9:
        this.mois_podiumHTML = "Août";
        this.moisHTML = "Septembre";
        break;
      case 10:
        this.mois_podiumHTML = "Septembre";
        this.moisHTML = "Octobre";
        break;
      case 11:
        this.mois_podiumHTML = "Octobre";
        this.moisHTML = "Novembre";
        break;
      case 12:
        this.mois_podiumHTML = "Novembre";
        this.moisHTML = "Décembre";
        break;
    }
  }

}

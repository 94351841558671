import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { cpuUsage } from 'process';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UserService {

  private baseUrl = 'https://expedigame.com/api/users/';


  // http options used for making API calls
  private httpOptions: any;

  // the actual JWT token
  public token: string; // ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQzOTA3NzEyLCJpYXQiOjE2NDM5MDc0MTIsImp0aSI6ImU4MTkzMzRkYmI2ZDRlNDA4N2NhYzkyMGYwYzU0NDAxIiwidXNlcl9pZCI6MX0.VNL3zwRLRKVks8W6Zoaa9L2XSaEtfEtR2MjYk9dciKs';

  // the token expiration date
  public token_expires: Date;

  // the username of the logged in user
  public username: string;

  // error messages received from the login attempt
  public errors: any = "";

  public subSuccess: boolean = false;
  public error_pseudo: boolean = false;
  public error_date: boolean = false;
  public error_email: boolean = false;

  public serror_pseudo: string;
  public serror_date: string;
  public serror_email: string;


  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
  public login(user, newUser) {
    this.http.post('https://expedigame.com/api/auth/login', JSON.stringify(user), this.httpOptions).subscribe(
      data => {
        this.updateData(data, newUser);
      },
      err => {
        this.errors = err['error'].detail;
      }
    );
  }

  public newUser(user) {

    this.http.post(this.baseUrl, JSON.stringify(user), this.httpOptions).subscribe(
      data => {
        console.log(data);
        this.subSuccess = true;
        this.login(user, "nouveau");
      },
      err => {
        this.errors = err['error'];
        const error_decoded = JSON.stringify(this.errors);
        const error_part = JSON.parse(error_decoded);
        if (error_part.username != '') {
          this.error_pseudo = true;
          this.serror_pseudo = error_part.username;
        }
        else {
          this.error_pseudo = false;
        }
        if (error_part.email != '') {
          this.error_email = true;
          this.serror_email = error_part.email;
        }
        else {
          this.error_email = false;
        }
        //          if(error_part.password != '') {
        //          this.error_pseudo = true;
        //        this.serror_pseudo = error_part.username;
        //          }
        if (error_part.dateAnniv != '') {
          this.error_date = true;
          this.serror_date = error_part.dateAnniv;
        }
        else {
          this.error_date = false;
        }

      }
    );
  }


  // Refreshes the JWT token, to extend the time the user is logged in
  public refreshToken() {
    console.log(JSON.parse(this.cookieService.get('username')));

  }

  public logout() {
    this.cookieService.deleteAll();
    this.token = null;
    this.token_expires = null;
    this.username = null;
    window.location.href = "https://expedigame.com/login";
  }

  private updateData(token, newUser) {

    this.token = token;
    this.errors = [];

    const token_decoded = JSON.stringify(this.token);
    const token_part = JSON.parse(token_decoded);
    this.cookieService.set('token', token_part.access)
    this.cookieService.set('tokenRefresh', token_part.refresh)
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + token_part.access })
    };

    this.http.get(this.baseUrl + 'me', this.httpOptions).subscribe(
      data => {
        const user_decoded = JSON.stringify(data);
        const user_part = JSON.parse(user_decoded);
        console.log(user_part.data.twitch_name);
        this.username = user_part.data.username;

        this.cookieService.set('username', user_decoded);
        if (newUser == "") {
          this.router.navigateByUrl('');
        }
      },
      err => {
        this.errors = err['error'];
      }
    );
    // decode the token to read the username and expiration timestamp
    //const token_parts = this.token.split(/\./);
    // const token_decoded = JSON.parse(this.token);
    //console.warn('token_decoded.refresh');
    //this.token_expires = new Date(Date.now() + 10000); //// A VOIR PLUS TARD 

  }


}
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TwitchEmbed, TwitchEmbedLayout, TwitchPlayer, TwitchPlayerEvent } from 'twitch-player';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  private httpOptions: any;

  declare Twitch: any;

  private baseUrl_banniere = "url(assets/img/";
  public banniere = "";
  public cadre = "";
  public colorProfil = "#ffffff";

  public clipExist = false;
  public cadreExist = false;

  public _twitch_name: boolean = false;
  public userTwitch: string;
  public navProfil: number = 0;
  public pourcentage;
  public pourcentage_succes;
  public timerInterval: any;
  public user_part;

  public succesList;
  public userSuccesList;
  public banniereList;
  public userBanniereList;

  public cadreList;
  public userCadreList;

  public buttonReward = false;

  private banniere_id_active;
  private cadre_id_active;

  public goal_actual;

  public clipList;

  public url_Succes;

  private succesArray: string[] = new Array();
  private userSuccesArray: string[] = new Array();


  constructor(private _httpClient: HttpClient, private route: ActivatedRoute, private cookieService: CookieService, private global: AppComponent) {

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
  }

  client_id = 'xn3g12yzv87p0ohpnfsi6o1rh638dm';
  code = this.route.snapshot.queryParamMap.get('code');
  body = new HttpParams()
    .set('client_id', this.client_id)
    .set('client_secret', 'm3mj8i4vchbik7gc037c53iv8acrgp')
    .set('code', this.code)
    .set('grant_type', 'authorization_code')
    .set('redirect_uri', 'https://expedigame.com/profil/');

  ngOnInit(): void {
    this.getColorProfil();
    this.getBanniere();
    this.getListBanniere();
    this.getCadre();
    this.getListCadre();
    this.getClip();
    this.updateClip();
    // this.showTwitch()
    this.user_part = JSON.parse(this.cookieService.get('username'));
    this.pourcentage = this.user_part.data.experience / 10;
    if (this.user_part.data.twitch_name == "" || this.user_part.data.twitch_name == null) {
      this._twitch_name = false;
      this.lierTwitch();
    }
    else {
      this.userTwitch = this.user_part.data.twitch_name;
      this._twitch_name = true;

    }
    this._httpClient.get("https://expedigame.com/api/succes/").subscribe(data => {
      const succes_decoded = JSON.stringify(data);

      const succes_part = JSON.parse(succes_decoded);
      this.succesList = succes_part.results
      for (var i = 0; i < succes_part.results.length; i++) {

        this.succesArray.push(succes_part.results[i])

      }
      if (succes_part.next != null) {
        this._httpClient.get(succes_part.next).subscribe(data => {
          const succes_decoded = JSON.stringify(data);

          const succes_part = JSON.parse(succes_decoded);
          for (var i = 0; i < succes_part.results.length; i++) {
            this.succesList.push(succes_part.results[i]);
            this.succesArray.push(succes_part.results[i])
          }

        })
      }


    });

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };

    this._httpClient.get("https://expedigame.com/api/userSucces/", httpOptions).subscribe(data => {
      const user_succes_decoded = JSON.stringify(data);

      const user_succes_part = JSON.parse(user_succes_decoded);
      this.userSuccesList = user_succes_part.results
      for (var i = 0; i < user_succes_part.results.length; i++) {

        this.userSuccesArray.push(user_succes_part.results[i])

      }
      if (user_succes_part.next != null) {
        this._httpClient.get(user_succes_part.next, httpOptions).subscribe(data => {
          const user_succes_decoded = JSON.stringify(data);

          const user_succes_part = JSON.parse(user_succes_decoded);
          for (var i = 0; i < user_succes_part.results.length; i++) {
            this.userSuccesList.push(user_succes_part.results[i]);
            this.userSuccesArray.push(user_succes_part.results[i]);

          }

        })
      }
    });


  }

  getColorProfil() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._httpClient.get("https://expedigame.com/api/couleurProfil/get/", httpOptions).subscribe(data => {
      const color_decoded = JSON.stringify(data);

      const color_part = JSON.parse(color_decoded);
      this.colorProfil = color_part.couleur;
    });
  }

  putColorProfil(color) {
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    var body = "couleur=" + color;
    this._httpClient.put("https://expedigame.com/api/couleurProfil/put/", body, httpHeader).subscribe(data => {
      this.colorProfil = color;
    }, err => {
      this._httpClient.post("https://expedigame.com/api/couleurProfil/", body, httpHeader).subscribe(data => { this.colorProfil = color; });
    });
  }

  getBanniere() {
    const user_part = JSON.parse(this.cookieService.get('username'));

    this._httpClient.get("https://expedigame.com/api/banniere/activate/" + user_part.data.id, this.httpOptions).subscribe(data => {
      const userBanniere_decoded = JSON.stringify(data);
      const userBanniere_part = JSON.parse(userBanniere_decoded);
      this.banniere_id_active = userBanniere_part.banniere;

      this._httpClient.get("https://expedigame.com/api/banniere/" + userBanniere_part.banniere + "/", this.httpOptions).subscribe(data => {
        const banniere_decoded = JSON.stringify(data);
        const banniere_part = JSON.parse(banniere_decoded);

        this.banniere = this.baseUrl_banniere + banniere_part.nom_img + ".png)"
      });

    });
  }

  getCadre() {
    const user_part = JSON.parse(this.cookieService.get('username'));

    this._httpClient.get("https://expedigame.com/api/cadre/activate/" + user_part.data.id, this.httpOptions).subscribe(data => {
      const userCadre_decoded = JSON.stringify(data);
      const userCadre_part = JSON.parse(userCadre_decoded);
      this.cadre_id_active = userCadre_part.cadre;

      this._httpClient.get("https://expedigame.com/api/cadre/" + userCadre_part.cadre + "/", this.httpOptions).subscribe(data => {
        const cadre_decoded = JSON.stringify(data);
        const cadre_part = JSON.parse(cadre_decoded);

        this.cadre = "assets/img/" + cadre_part.nom_img + ".png"
      });

    });
  }



  updateUserBanniere(index, newCover) {
    const user_part = JSON.parse(this.cookieService.get('username'));

    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    var body = "activate=" + 0;
    this._httpClient.put("https://expedigame.com/api/user/" + user_part.data.id + "/banniere/" + this.banniere_id_active, body, httpHeader).subscribe(data => {
      body = "activate=" + 1;
      this._httpClient.put("https://expedigame.com/api/user/" + user_part.data.id + "/banniere/" + index, body, httpHeader).subscribe(data => {
        this.banniere_id_active = index;
        this.banniere = this.baseUrl_banniere + newCover + ".png)"
      })
    })

  }

  updateUserCadre(index, newCover) {
    const user_part = JSON.parse(this.cookieService.get('username'));

    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };

    if (this.cadre_id_active == undefined) {
      var body = "activate=" + 1;
      this._httpClient.put("https://expedigame.com/api/user/" + user_part.data.id + "/cadre/" + index, body, httpHeader).subscribe(data => {
        this.cadre_id_active = index;
        this.cadre = "assets/img/" + newCover + ".png"
      })
    } else {
      var body = "activate=" + 0;
      this._httpClient.put("https://expedigame.com/api/user/" + user_part.data.id + "/cadre/" + this.cadre_id_active, body, httpHeader).subscribe(data => {
        body = "activate=" + 1;
        this._httpClient.put("https://expedigame.com/api/user/" + user_part.data.id + "/cadre/" + index, body, httpHeader).subscribe(data => {
          this.cadre_id_active = index;
          this.cadre = "assets/img/" + newCover + ".png"
        })
      })
    }


  }

  getListBanniere() {
    this._httpClient.get("https://expedigame.com/api/banniere/").subscribe(data => {
      const banniere_decoded = JSON.stringify(data);

      const banniere_part = JSON.parse(banniere_decoded);
      this.banniereList = banniere_part.results
      if (banniere_part.next != null) {
        this._httpClient.get(banniere_part.next).subscribe(data => {
          const banniere_decoded = JSON.stringify(data);

          const banniere_part = JSON.parse(banniere_decoded);
          for (var i = 0; i < banniere_part.results.length; i++) {
            this.banniereList.push(banniere_part.results[i]);
          }


        })
      }


    });

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };

    this._httpClient.get("https://expedigame.com/api/userBanniere/", httpOptions).subscribe(data => {
      const user_banniere_decoded = JSON.stringify(data);

      const user_banniere_part = JSON.parse(user_banniere_decoded);
      this.userBanniereList = user_banniere_part.results
      if (user_banniere_part.next != null) {
        this._httpClient.get(user_banniere_part.next, httpOptions).subscribe(data => {
          const user_banniere_decoded = JSON.stringify(data);

          const user_banniere_part = JSON.parse(user_banniere_decoded);
          for (var i = 0; i < user_banniere_part.results.length; i++) {
            this.userBanniereList.push(user_banniere_part.results[i]);

          }

        })
      }
    });

  }

  getListCadre() {
    this._httpClient.get("https://expedigame.com/api/cadre/").subscribe(data => {
      const cadre_decoded = JSON.stringify(data);

      const cadre_part = JSON.parse(cadre_decoded);
      this.cadreList = cadre_part.results

      if (cadre_part.next != null) {
        this._httpClient.get(cadre_part.next).subscribe(data => {
          const cadre_decoded = JSON.stringify(data);

          const cadre_part = JSON.parse(cadre_decoded);
          for (var i = 0; i < cadre_part.results.length; i++) {
            this.cadreList.push(cadre_part.results[i]);
          }


        })
      }


    });

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };

    this._httpClient.get("https://expedigame.com/api/userCadre/", httpOptions).subscribe(data => {
      const user_cadre_decoded = JSON.stringify(data);
      const user_cadre_part = JSON.parse(user_cadre_decoded);
      if (user_cadre_part.count > 0) { this.cadreExist = true; }
      this.userCadreList = user_cadre_part.results
      if (user_cadre_part.next != null) {
        this._httpClient.get(user_cadre_part.next, httpOptions).subscribe(data => {
          const user_cadre_decoded = JSON.stringify(data);

          const user_cadre_part = JSON.parse(user_cadre_decoded);
          for (var i = 0; i < user_cadre_part.results.length; i++) {
            this.userCadreList.push(user_cadre_part.results[i]);

          }

        })
      }
    });

  }



  infoSucces(id_Succes, id_user_Succes, index_Success, value, rang) {

    if (Number(id_Succes) == Number(id_user_Succes)) {
      this.buttonReward = false;
      const array_decoded = JSON.stringify(this.succesArray[index_Success]);
      const array_part = JSON.parse(array_decoded);

      const user_decoded = JSON.stringify(this.userSuccesArray[index_Success]);
      const user_part = JSON.parse(user_decoded);

      var goal;
      switch (rang) {
        case 0:
          this.url_Succes = "assets/img/" + array_part.url_img + "_gris.png"

          goal = array_part.goal_bronze
          this.goal_actual = array_part.goal_bronze
          break;
        case 1:
          if (user_part.reward_bronze == 0) {
            this.buttonReward = true;
          }
          this.url_Succes = "assets/img/" + array_part.url_img + "_bronze.png"
          goal = array_part.goal_argent
          this.goal_actual = array_part.goal_argent
          break;
        case 2:
          if (user_part.reward_bronze == 0 || user_part.reward_argent == 0) {
            this.buttonReward = true;
          }
          this.url_Succes = "assets/img/" + array_part.url_img + "_argent.png"
          goal = array_part.goal_or
          this.goal_actual = array_part.goal_or
          break;
        case 3:
          if (user_part.reward_bronze == 0 || user_part.reward_argent == 0 || user_part.reward_or == 0) {
            this.buttonReward = true;
          }
          this.url_Succes = "assets/img/" + array_part.url_img + "_or.png"
          goal = array_part.goal_or
          this.goal_actual = array_part.goal_or
          break;
      }
      if (array_part.description_fin == null) { this.goal_actual = "" }
      this.calculProgressBar(goal, value);
      return true;
    }
    return false;
  }

  calculProgressBar(goal, value) {

    var progressBar = Math.round(Number(value / goal) * 100);

    this.pourcentage_succes = progressBar;

  }

  getRewardSucces(user_succes_id) {

    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._httpClient.get("https://expedigame.com/api/userSucces/" + user_succes_id + "/", httpHeader).subscribe(data => {
      const reward_decoded = JSON.stringify(data);
      const reward_part = JSON.parse(reward_decoded);
      if (reward_part.reward_bronze == 0) {
        this.postRewardSucces(1, 5000);
        const body = "reward_bronze=" + 1;
        this._httpClient.put("https://expedigame.com/api/userSucces/" + user_succes_id + "/", body, httpHeader).subscribe(data_put => {

        });
      }
      else if (reward_part.reward_argent == 0) {
        this.postRewardSucces(2, user_succes_id);
        const body = "reward_argent=" + 1;
        this._httpClient.put("https://expedigame.com/api/userSucces/" + user_succes_id + "/", body, httpHeader).subscribe();
      }
      else if (reward_part.reward_or == 0) {
        this.postRewardSucces(3, user_succes_id);
        const body = "reward_or=" + 1;
        this._httpClient.put("https://expedigame.com/api/userSucces/" + user_succes_id + "/", body, httpHeader).subscribe();
      }

    });
  }

  postRewardSucces(type_reward, value) {
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    var httpHeaderReward = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    switch (type_reward) {
      case 1:
        const user_part = JSON.parse(this.cookieService.get('username'));

        user_part.data.expedipoint += value;
        this.cookieService.set('expedipoint', user_part.data.expedipoint);

        const bodyTwitch = '{"expedipoint":"' + user_part.data.expedipoint + '" }'; //voir creation body !

        this._httpClient.put('https://expedigame.com/api/users', bodyTwitch, httpHeader).subscribe(
          data => {
            const user_decoded = JSON.stringify(data);
            this.cookieService.set('username', user_decoded);
            window.location.reload();
          }
        );
        break;
      case 2:
        this._httpClient.get("https://expedigame.com/api/succes/" + value + "/", httpHeader).subscribe(data => {
          const array_cadre = JSON.stringify(data);
          const cadre_part = JSON.parse(array_cadre);
          const bodyCadre = "cadre=" + cadre_part.reward_cadre;

          this._httpClient.post("https://expedigame.com/api/userCadre/", bodyCadre, httpHeaderReward).subscribe(data => {
            window.location.reload();
          });
        });

        break;
      case 3:
        const array_banniere = JSON.stringify(this.succesArray[value]);
        const banniere_part = JSON.parse(array_banniere);
        const bodyBanniere = "banniere=" + banniere_part.reward_banniere;
        this._httpClient.post("https://expedigame.com/api/userBanniere/", bodyBanniere, httpHeader).subscribe(data => {
          window.location.reload();
        });
        break;
    }
  }

  navChange(numNav) {
    if (numNav == 2 && this.navProfil == 0) {
      this.navProfil = 1;
      var profil = document.getElementById("profil");
      profil.classList.remove("active");
      var succes = document.getElementById("succes");
      succes.classList.add("active");
      var param = document.getElementById("parametre");
      param.classList.remove("active");
    }
    if (numNav == 1 && this.navProfil > 0) {
      this.navProfil = 0;
      var profil = document.getElementById("profil");
      profil.classList.add("active");
      var succes = document.getElementById("succes");
      succes.classList.remove("active");
      var param = document.getElementById("parametre");
      param.classList.remove("active");

    }

    if (numNav == 3 && this.navProfil < 2) {
      this.navProfil = 2;
      var profil = document.getElementById("profil");
      profil.classList.remove("active");
      var succes = document.getElementById("succes");
      succes.classList.remove("active");
      var param = document.getElementById("parametre");
      param.classList.add("active");

    }
  }


  getClip() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._httpClient.get("https://expedigame.com/api/clip/", this.httpOptions).subscribe(async data => {
      const clip_decoded = JSON.stringify(data);
      const clip_part = JSON.parse(clip_decoded);
      if (clip_part.count > 0) { this.clipExist = true; }
      this.clipList = clip_part.results;
      var next = clip_part.next;
      while (next != null) {
        const reponse = await this._httpClient.get(next, this.httpOptions).toPromise();
        const clip_decoded = JSON.stringify(reponse);
        const clip_part = JSON.parse(clip_decoded);
        for (var i = 0; i < clip_part.results.length; i++) {
          this.clipList.push(clip_part.results[i]);
        }
        next = clip_part.next;
      }
    });
  }

  lierTwitch() {
    this._httpClient.post('https://id.twitch.tv/oauth2/token?' + this.body.toString(), this.body.toString()).subscribe(
      data => {
        const token_decoded = JSON.stringify(data);
        const token_part = JSON.parse(token_decoded);
        this.cookieService.set('token_twitch', token_part.access_token);
        this._httpClient.get('https://api.twitch.tv/helix/users',
          {
            headers: new HttpHeaders()
              .set('Accept', 'application/vnd.twitchtv.v5+json')
              .set('Client-ID', this.client_id)
              .set('Authorization', 'Bearer ' + token_part.access_token)
          }).subscribe(
            data_2 => {

              const twitch_decoded = JSON.stringify(data_2);
              const twitch_part = JSON.parse(twitch_decoded);
              this.updateTwitchName(twitch_part.data[0].display_name, twitch_part.data[0].id, twitch_part.data[0].profile_image_url);
            }
          );

      });

  }

  updateTwitchName(userTwitch, idTwitch, imgProfil) {
    const bodyTwitch = '{"twitch_name" : "' + userTwitch + '", "id_twitch" : "' + idTwitch + '", "img_profil" : "' + imgProfil + '" }'; //voir creation body !
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._httpClient.put('https://expedigame.com/api/users', bodyTwitch, this.httpOptions).subscribe(
      data => {
        const user_decoded = JSON.stringify(data);
        this.cookieService.set('username', user_decoded);
        window.location.reload(); // voir si il y a pas d'autre moyen pour refresh la page / component 
      }
    );
  }



  updateClip() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    this._httpClient.post('https://id.twitch.tv/oauth2/token?' + this.body.toString(), this.body.toString(), this.httpOptions).subscribe(
      data => {
        const token_decoded = JSON.stringify(data);
        const token_part = JSON.parse(token_decoded);
        this.cookieService.set('token_twitch', token_part.access_token);
        this._httpClient.get('https://api.twitch.tv/helix/users',
          {
            headers: new HttpHeaders()
              .set('Accept', 'application/vnd.twitchtv.v5+json')
              .set('Client-ID', this.client_id)
              .set('Authorization', 'Bearer ' + this.cookieService.get('token_twitch'))
          }).subscribe(
            data_2 => {
              const twitch_decoded = JSON.stringify(data_2);
              const twitch_part = JSON.parse(twitch_decoded);
              const httpOptionsss = {
                headers: new HttpHeaders({ 'Content-Type': 'application/vnd.twitchtv.v5+json', 'Client-ID': this.client_id, Authorization: 'Bearer ' + token_part.access_token })
              };
              this.httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + this.cookieService.get('token') })
              };
              this._httpClient.get('https://expedigame.com/api/clip/', this.httpOptions).subscribe(async data => {
                const clip_decoded = JSON.stringify(data);
                const clip_part = JSON.parse(clip_decoded);
                var next = clip_part.next;
                var maxView = 0;
                for (var i = 0; i < clip_part.results.length; i++) {
                  var id = clip_part.results[i].id;
                  const reponse = await this._httpClient.get('https://api.twitch.tv/helix/clips?id=' + clip_part.results[i].id_clip).toPromise();

                  const view_decoded = JSON.stringify(reponse);
                  const view_part = JSON.parse(view_decoded);
                  var body = "count_view=" + view_part.data[0].view_count;
                  if (maxView < view_part.data[0].view_count) {
                    maxView = view_part.data[0].view_count
                  }
                  this._httpClient.put('https://expedigame.com/api/clip/' + id + '/', body, this.httpOptions).subscribe(data => { });
                }


                while (next != null) {
                  const reponse = await this._httpClient.get(next, this.httpOptions).toPromise();
                  const clip_decoded = JSON.stringify(reponse);
                  const clip_part = JSON.parse(clip_decoded);
                  next = clip_part.next;
                  for (var i = 0; i < clip_part.results.length; i++) {
                    var id = clip_part.results[i].id;
                    const reponse = await this._httpClient.get('https://api.twitch.tv/helix/clips?id=' + clip_part.results[i].id_clip).toPromise();
                    const view_decoded = JSON.stringify(reponse);
                    const view_part = JSON.parse(view_decoded);
                    if (maxView < view_part.data[0].view_count) {
                      maxView = view_part.data[0].view_count
                    }
                    var body = "count_view=" + view_part.data[0].view_count;
                    this._httpClient.put('https://expedigame.com/api/clip/' + id + '/', body, this.httpOptions).subscribe();

                  }
                }
                this.global.updateUserSucces(6, maxView);

              });
            });
      });

  }

  changePassword() {
    var old_password = (<HTMLInputElement>document.getElementById("oldPassword")).value;
    var new_password = (<HTMLInputElement>document.getElementById("newPassword")).value;
    var confirm_password = (<HTMLInputElement>document.getElementById("confirmPassword")).value;
    if (new_password == confirm_password) {
      var httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };
      console.log("password identique")
      const user_part = JSON.parse(this.cookieService.get('username'));
      var name = user_part.data.username;
      var user = { 'username': name, 'password': old_password }
      this._httpClient.post('https://expedigame.com/api/auth/login', JSON.stringify(user), httpOptions).subscribe(
        data => {
          console.log("put nouveau mot de passe")
        },
        err => {
          console.log("Ancien mot de passe incorrect")
        }
      );
    }
    else {
      console.log("erreur mot de passe non identique");
    }
  }

}

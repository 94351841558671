import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { formatDate } from '@angular/common';
import { Cipher } from 'crypto';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private _httpClient: HttpClient, private cookieService: CookieService) {

  }

  private goal = 0;
  public img = "";
  public titre = "";
  public description = "";

  public nav = true;
  public footer = true;

  ngOnInit(): void {
    this.getDailyQUest();
    this.addSucces();
    this.addBanniere();
  }


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
  };

  addBanniere() {
    this._httpClient.get("https://expedigame.com/api/userBanniere/", this.httpOptions).subscribe(data => {
      const user_banniere_decoded = JSON.stringify(data);
      const user_banniere_part = JSON.parse(user_banniere_decoded);
      if (user_banniere_part.count == 0 || user_banniere_part.count == undefined) {
        var httpHeader = {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
        };
        const urlParameters = "banniere=1&activate=" + 1;
        this._httpClient.post("https://expedigame.com/api/userBanniere/", urlParameters, httpHeader).subscribe(data => { }, err => {
          console.log(err);
        });
      }
    });
  }

  addSucces() {
    var numberSucces = 0;
    var userSucces = 0;
    var page_succes;

    this._httpClient.get("https://expedigame.com/api/userSucces/", this.httpOptions).subscribe(data => {

      const user_succes_decoded = JSON.stringify(data);
      const user_succes_part = JSON.parse(user_succes_decoded);
      userSucces = user_succes_part.count;
      console.log(user_succes_part.count);
      this._httpClient.get("https://expedigame.com/api/succes/").subscribe(data => {
        var succes_decoded = JSON.stringify(data);
        var succes_part = JSON.parse(succes_decoded);
        console.log(data);
        page_succes = Math.ceil(succes_part.count / 10);
        numberSucces = succes_part.count;
        if (userSucces < numberSucces) {
          console.log(page_succes)
          //création de post 
          var httpHeader = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
          };
          console.log("test 1")
          for (var i = 0; i < succes_part.results.length; i++) {

            var urlParameters = "succes=" + succes_part.results[i].id + "&date_obtenu=2000-01-01";
            this._httpClient.post("https://expedigame.com/api/userSucces/", urlParameters, httpHeader).subscribe(data => { }, err => {
              // console.log(err);
            });
            console.log("test 2");
          }

          for (var j = 2; j <= page_succes; j++) {

            this._httpClient.get(succes_part.next).subscribe(data_3 => {
              console.log(data_3)
              succes_decoded = JSON.stringify(data_3);
              succes_part = JSON.parse(succes_decoded);
              //création de post  // dans django si doublons alors pas de création
              for (var i = 0; i < succes_part.results.length; i++) {

                const urlParameters = "succes=" + succes_part.results[i].id + "&date_obtenu=2000-01-01";
                this._httpClient.post("https://expedigame.com/api/userSucces/", urlParameters, httpHeader).subscribe(data => { }, err => {
                  console.log(err);
                });
              }
            });
          }

          console.log("update les succes de l'utilisateur")

        }
      });
    });




  }



  title = 'mdb-angular-free';

  public user: any;

  public username: string;
  public token: string;


  successAlert = false;

  copyToClipboard(value: string): void {
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    this.successAlert = true;

    setTimeout(() => {
      this.successAlert = false;
    }, 900);

  }


  getDailyQUest() {

    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };

    this._httpClient.get("https://expedigame.com/api/quest/", httpHeader).subscribe(
      data => {

        const data_decoded = JSON.stringify(data);
        const data_part = JSON.parse(data_decoded);

        if (data_part.results.length == 0) {
          this.getRandomDailyQuest();

        }
        else {


          const date1 = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en_US');
          console.log(Date.parse(date1) - Date.parse(data_part.results[0].date_quest));
          if (Date.parse(date1) - Date.parse(data_part.results[0].date_quest) > 115200000) {
            //supprimer + créer nouvelle dailyquest
            for (var i = 0; i < data_part.results.length; i++) {
              this._httpClient.delete("https://expedigame.com/api/quest/" + data_part.results[i].id + "/", httpHeader).subscribe(data => { }, err => {
                console.log(err);
              });
            }
            this.getRandomDailyQuest();
          }
          else {

          }
        }
      },
      err => {
        console.log(err);
      }
    );

  }





  async postDailyQuest(dayQuest: string[]) {
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };

    let dateQuest = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');

    for (var i = 0; i < dayQuest.length; i++) {
      const urlParameters = "quest=" + dayQuest[i] + "&date_quest=" + dateQuest;
      await this._httpClient.post("https://expedigame.com/api/quest/", urlParameters, httpHeader).subscribe(data => { }, err => {
        console.log(err);
      });
    }

  }




  getRandomDailyQuest() {
    var dayQuest: string[] = new Array(3);
    var questSpecial = 0;
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    const date1 = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    this._httpClient.get("https://expedigame.com/api/planning/" + date1 + "/", httpHeader).subscribe(data_planning => {
      const data_planning_decoded = JSON.stringify(data_planning);
      const data_planning_part = JSON.parse(data_planning_decoded);
      console.log(data_planning_part)
      this._httpClient.get("https://expedigame.com/api/objectif/list/" + data_planning_part.type_jeu).subscribe(data => {
        const data_decoded = JSON.stringify(data);
        const data_part = JSON.parse(data_decoded);
        if (data_part.count <= 3) {
          for (var i = 0; i < data_part.results.length; i++)
            dayQuest[i] = data_part.results[i].id
        }
        else {
          dayQuest[0] = Math.floor(Math.random() * data_part.results.length).toString();
          dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
          while (dayQuest[0] == dayQuest[1]) {
            dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
          }
          dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
          while (dayQuest[0] == dayQuest[2] || dayQuest[1] == dayQuest[2]) {
            dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
          }
          dayQuest[0] = data_part.results[dayQuest[0]].id
          dayQuest[1] = data_part.results[dayQuest[1]].id
          dayQuest[2] = data_part.results[dayQuest[2]].id

        }


        questSpecial = data_part.count;

        if (questSpecial < 3) {


          this._httpClient.get("https://expedigame.com/api/objectif/list/1").subscribe(data => {

            const data_decoded = JSON.stringify(data);
            const data_part = JSON.parse(data_decoded);

            switch (questSpecial) {
              case 0:
                dayQuest[0] = Math.floor(Math.random() * data_part.results.length).toString();
                dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
                while (dayQuest[0] == dayQuest[1]) {
                  dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
                }
                dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
                while (dayQuest[0] == dayQuest[2] || dayQuest[1] == dayQuest[2]) {
                  dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
                }
                dayQuest[0] = data_part.results[dayQuest[0]].id
                dayQuest[1] = data_part.results[dayQuest[1]].id
                dayQuest[2] = data_part.results[dayQuest[2]].id
                break;
              case 1:
                dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
                dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
                while (dayQuest[1] == dayQuest[2]) {
                  dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
                }
                dayQuest[1] = data_part.results[dayQuest[1]].id
                dayQuest[2] = data_part.results[dayQuest[2]].id
                break;
              case 2:
                dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
                dayQuest[2] = data_part.results[dayQuest[2]].id
                break;
            }

            console.log(dayQuest)
            this.postDailyQuest(dayQuest);
          });
        }
        else {
          this.postDailyQuest(dayQuest);
        }
      });
    }, err => {
      this._httpClient.get("https://expedigame.com/api/objectif/list/1").subscribe(data => {

        const data_decoded = JSON.stringify(data);
        const data_part = JSON.parse(data_decoded);

        switch (questSpecial) {
          case 0:
            dayQuest[0] = Math.floor(Math.random() * data_part.results.length).toString();
            dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
            while (dayQuest[0] == dayQuest[1]) {
              dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
            }
            dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
            while (dayQuest[0] == dayQuest[2] || dayQuest[1] == dayQuest[2]) {
              dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
            }
            dayQuest[0] = data_part.results[dayQuest[0]].id
            dayQuest[1] = data_part.results[dayQuest[1]].id
            dayQuest[2] = data_part.results[dayQuest[2]].id
            break;
          case 1:
            dayQuest[1] = Math.floor(Math.random() * data_part.results.length).toString();
            dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
            while (dayQuest[1] == dayQuest[2]) {
              dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
            }
            dayQuest[1] = data_part.results[dayQuest[1]].id
            dayQuest[2] = data_part.results[dayQuest[2]].id
            break;
          case 2:
            dayQuest[2] = Math.floor(Math.random() * data_part.results.length).toString();
            dayQuest[2] = data_part.results[dayQuest[2]].id
            break;
        }

        console.log(dayQuest)
        this.postDailyQuest(dayQuest);
      });

    });
  }

  updateUserSucces(num_succes, addValue = 1) {
    var new_Value = 0;
    var new_Rang = 0;
    const user_part = JSON.parse(this.cookieService.get("username"));
    console.log(user_part)
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    this._httpClient.get("https://expedigame.com/api/user/" + user_part.data.id + "/succes/" + num_succes, httpHeader).subscribe(data => {
      const userSucces_decoded = JSON.stringify(data);
      const userSucces_part = JSON.parse(userSucces_decoded);
      console.log(userSucces_part)

      this._httpClient.get("https://expedigame.com/api/succes/" + num_succes + "/", httpHeader).subscribe(data_succes => {
        const succes_decoded = JSON.stringify(data_succes);
        const succes_part = JSON.parse(succes_decoded);
        console.log(succes_part)
        new_Rang = userSucces_part.Rang;
        if (num_succes == 6) {
          new_Value = addValue;
        } else {
          new_Value = userSucces_part.currently_Value + addValue;
        }

        switch (userSucces_part.Rang) {
          case 0:
            this.goal = succes_part.goal_bronze;
            break;
          case 1:
            this.goal = succes_part.goal_argent;
            break;
          case 2:
            this.goal = succes_part.goal_or;
            break;
          default:
            break;
        }

        if (new_Value >= this.goal && userSucces_part.Rang < 3) {
          switch (userSucces_part.Rang) {
            case 0:
              this.img = "assets/img/" + succes_part.url_img + "_bronze.png";
              break;
            case 1:
              this.img = "assets/img/" + succes_part.url_img + "_argent.png";
              break;
            case 2:
              this.img = "assets/img/" + succes_part.url_img + "_or.png";
              break;
            default:
              break;
          }
          new_Rang = userSucces_part.Rang + 1;
          this.titre = succes_part.titre;
          if (succes_part.description_fin == "") {
            this.description = succes_part.description_deb;
          } else {
            this.description = succes_part.description_deb + " " + this.goal + " " + succes_part.description_fin;
          }
          document.getElementById("openSucces").click();
        }
        var httpHeaderPut = {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
        };
        const body = "currently_Value=" + new_Value + "&Rang=" + new_Rang;
        this._httpClient.put("https://expedigame.com/api/user/" + user_part.data.id + "/succes/" + num_succes, body, httpHeaderPut).subscribe(data_put => {

        });



      })

    })
  }

  updateClassement(point) {
    var today = new Date();
    var mois = today.getMonth() + 1;
    var annee = today.getFullYear();
    var httpHeader = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.cookieService.get('token') })
    };
    const user_part = JSON.parse(this.cookieService.get("username"));
    this._httpClient.get("https://expedigame.com/api/classement/mois/" + mois + "/annee/" + annee + "/user/" + user_part.data.id).subscribe(data => {
      const point_decoded = JSON.stringify(data);
      const point_part = JSON.parse(point_decoded);
      point = point_part.point + point;
      var body = "point=" + point;
      this._httpClient.put("https://expedigame.com/api/classement/mois/" + mois + "/annee/" + annee, body, httpHeader).subscribe(data => { })

      console.log(data)
    }, err => {

      var body = "mois=" + mois + "&annee=" + annee + "&username=" + user_part.data.username + "&point=" + point;
      this._httpClient.post("https://expedigame.com/api/classement/", body, httpHeader).subscribe(data => {

      });
    });
  }


}
